import React from 'react';

const QuestionIcon = () => {
	return (
		<svg
			width="46"
			height="44"
			viewBox="0 0 46 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_28669_277048)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.21721 32.3432H4.21891C2.57413 29.2607 1.64083 25.7404 1.64083 21.9998C1.64083 9.83949 11.4904 0 23.6598 0C35.8291 0 45.6787 9.84119 45.6787 22.0002C45.6787 34.1591 35.8291 44.0003 23.6598 44.0003C18.5555 44.0003 13.86 42.269 10.1282 39.362L0.321594 41.9495L4.21721 32.3432ZM25.8099 32.5274C25.8099 31.3408 24.8473 30.379 23.6598 30.379C22.4723 30.379 21.5096 31.3408 21.5096 32.5274C21.5096 33.7139 22.4723 34.6757 23.6598 34.6757C24.8473 34.6757 25.8099 33.7139 25.8099 32.5274ZM23.6598 11.043C19.8655 11.043 16.779 14.1273 16.779 17.918C16.779 18.8672 17.5492 19.6367 18.4992 19.6367C19.4492 19.6367 20.2194 18.8672 20.2194 17.918C20.2194 16.0226 21.7628 14.4805 23.6598 14.4805C25.5567 14.4805 27.1002 16.0226 27.1002 17.918C27.1002 19.8133 25.5567 21.3554 23.6598 21.3554C22.7098 21.3554 21.9396 22.125 21.9396 23.0742V27.3712C21.9396 28.3204 22.7098 29.0899 23.6598 29.0899C24.6098 29.0899 25.38 28.3204 25.38 27.3712V24.5754C28.3443 23.8103 30.5406 21.1154 30.5406 17.918C30.5406 14.1269 27.4537 11.043 23.6598 11.043Z"
					fill="#FFAB2D"
				/>
			</g>
			<defs>
				<clipPath id="clip0_28669_277048">
					<rect
						width="45.3568"
						height="44"
						fill="white"
						transform="translate(0.321594)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default QuestionIcon;
