const translationForms = {
	en: {
		// General fields
		fieldFirstName: 'First name',
		fieldFirstNameSubtitle: '(As it appears on your ID)',
		fieldFirstNamePlaceholder: 'e.g. Enrique Ernesto',
		fieldLastName: 'Last name',
		fieldLastNameSubtitle: '(As it appears on your ID)',
		fieldLastNamePlaceholder: 'e.g. Arriola Hernandez',
		fieldDay: 'Day',
		fieldMonth: 'Month',
		fieldYear: 'Year',
		fieldEmail: 'Email',
		fieldEmailSubtitle: '(To receive exclusive offers)',
		fieldEmailPlaceholder: 'e.g. juan123@gmail.com',
		fieldUserName: 'User name',
		fieldPassword: 'Password',
		fieldForgotPasswordEMail: 'Send e-mail to',
		fieldUsernameEmail: 'Email',
		fieldReEnterPassword: 'Re-enter the password',
		fieldPhoneNumber: 'Phone number',
		fieldPhoneNumberSubtitle: '(An activation code will be sent to)',
		fieldPhoneNumberPlaceholder: 'e.g. 7911 123456',
		fieldBirthday: 'Birthday',
		fieldAllowPromotions: 'I would like to obtain promotions',
		fieldAllowMarketing:
			'I agree to receive newsletters, promotional information and product updates by email or sms.',
		fieldCouponCode: 'e.g. 123456',
		fieldCouponCodeQuestion: 'Do you have a code for ',
		fieldCouponCodeButton: 'Coupon?',
		fieldCouponCodeSubstring: '(optional)',
		fieldCouponCodeInputLabel: 'Coupon',
		hintPassword:
			'Minimum 8 characters (at least 1 uppercase letter, 1 lowercase letter and 1 number)',
		hintPasswordMinMax: '8-30 characters',
		hintPasswordUppercase: 'a capital letter',
		hintPasswordLowercase: 'a lowercase letter',
		hintPasswordNumber: 'a number',
		hintPasswordSpaces: 'no spaces',
		hintNickName: 'One word, for example “luck7“',
		hintCouponCode: 'One word, for example: “FC6533AT“',
		formMessageConditions:
			'Registering or creating your account means that you agree to the <a style="text-decoration: underline; cursor: pointer; font-weight: 600;" href="/info/terms-and-conditions" rel="noreferrer" target="_blank" data-testid="terms-and-conditions">Terms and conditions</a> & <a style="text-decoration: underline; cursor: pointer; font-weight: 600;" href="/info/privacy-policy" rel="noreferrer" target="_blank" data-testid="privacy-policy">Privacy policy</a>.',

		// SingUp
		signUpSupport: 'Support',
		singUpFormTitle: 'Abrir Una Cuenta',
		singUpFormButtonSubmit: 'Register a new account',
		'signUp.form.button.open.account': 'Open Account',
		'signUp.form.complete': 'Please field the form',
		'signUp.form.success.done': 'CONGRATULATIONS!',
		'signUp.form.successfully.registered1':
			'You’ve successfully registered your account.',
		'signUp.form.successfully.registered': 'Your account was created successfully.',
		'signUp.form.button.exit': 'Exit',
		'signUp.form.button.continue': 'Continue',
		'signUp.form.want.cancel.exit.title':
			'You are 2 clicks away from getting your MX$ 500 free bonus,',
		'signUp.form.want.cancel.exit': 'Are you sure you want to cancel and exit?',
		'signUp.form.button.play.now': 'Play Now',
		'signUp.error.seon.restrict.title': 'Ups',
		'signUp.error.seon.restrict.leading':
			'There seems to be a problem with your registration. Please contact our customer support team for further assistance. We apologize for any inconvenience.',
		'error.ageVerification': 'The minimum age to register is 18 years',
		'incorrect.day': 'Invalid day',
		'incorrect.month': 'Invalid month',
		'incorrect.year': 'Invalid year',

		// Login
		// loginFormTitle: 'Open An Account',
		loginFormTitle: 'Access',
		loginFormButtonSignUp: 'Sign Up',
		loginFormButtonFacebook: 'Start',
		loginFormButtonSubmit: 'Log In',
		signUpFormButtonSubmit: 'Open An Account',
		loginFormFieldRememberMe: 'Remember Me',
		loginFormForgotPassword: 'Forgot your password?',
		'login.modal.forgotPassword.buttonByEmail': 'Reset via Email',
		'login.modal.forgotPassword.buttonBySMS': 'Reset via SMS',
		'login.modal.forgotPassword.desc.phone': 'Enter your phone number',
		'login.modal.forgotPassword.hint.phone':
			'Text messages (SMS) can only be used if the mobile phone number has been verified.',
		'verification.method.phone': 'Mobile Number',
		'verification.method.phone.subtitle': '(An activation code will be sent)',
		'verification.description.resetPassword':
			'We have sent an SMS code to your phone. {phoneNumber}',
		'verification.needHelp': 'Do you have problems? Contact the ',
		'verification.button.support': 'assistance service',
		'verification.button.resend': 'Resend',
		'verification.button.resendOTP': 'Resend OTP',
		'verification.error.invalidCode': 'Invalid OTP entered',
		'verification.timeUp': 'Times Up. ',
		confirmPassword: 'Confirm password',
		'required.field': '{fieldName} it is a mandatory field',
		'login.modal.resetPassword.input.newPassword': 'New Password',
		'login.modal.forgotPassword.not.exist.phone.title':
			'The phone number provided is not verified or does not exist',
		'login.modal.forgotPassword.reset.limit.phone.title':
			'Currently the option to recover your password with SMS is unavailable',
		'login.modal.forgotPassword.buttonByEmailAfterPhone': 'Continue with email',
		forgotPasswordFormEnterYourEmail: 'Enter your Email address',
		'forgotPassword.incorrect.credentials':
			'The account with that email does not exist',
		loginFormNoAccount: 'No Account',
		loginFormButtonCreate: 'Create one',
		'login.form.button.openAccount': 'Open an Account',
		'login.form.or': 'or',
		'login.form.or.has.account': 'or you already have an account',
		'login.form.have.account': 'You already have an account?',
		'login.form.logIn': 'Log in',
		'signUp.info.message.alreadyHaveAccount': 'Already have an account?',
		'signUp.error.minimum.char.includes.letters.number':
			'Password must be includes Minimum 8 characters (at least 1 uppercase letter, at least 1 lowercase letter and 1 number)',

		// LogOut
		'sideMenu.modal.logOut.title': 'Are you sure you want to log out?',
		'sideMenu.modal.logOut.message':
			'Do you really want to sign out from your account?',
		cancel: 'Cancel',
		'sideMenu.button.logOut': 'Log out',

		// SuccessRegistrationModal
		successRegistrationModalCongratulations: 'Congratulations!',
		successRegistrationModalMainText: 'You have just created your account on Winpot',
		successRegistrationModalButtonSubmit: 'deposit now',

		// SmallModal
		'smallModal.title.congratulations': 'Congratulations!',
		'smallModal.title.confirmation': 'Are you Sure?',
		'smallModal.description.removeDocument':
			'Do you want to remove this document from verification?',
		'smallModal.description.changePassword': 'Your password has been changed.',

		// Validation
		'password.requirement':
			'Password must contain minimum 8 characters (at least 1 uppercase letter, at least 1 lowercase letter and 1 number)',
		'password.mismatch': 'Password mismatch',
		'password.short': 'Password is too short',
		'password.submit': 'Submit',
		'login.incorrect': 'Login is incorrect',
		'latin.spanish.only': 'Please enter latin or spanish text only',
		'latin.spanish.numbers.only':
			'Please enter Latin or spanish letters and numbers only',
		'minimum.char': 'Minimum {value} characters',
		'maximum.char': 'Maximum {value} characters',
		'incorrect.phone': 'Phone number is incorrect',
		'incorrect.email': 'Email is incorrect',
		'domain.blacklist': 'Domain is in blacklist',
		'whitespace.not.allowed': 'Whitespace characters are not allowed',
		'minimum.char.includes.letters.number':
			'Password must be includes Minimum 8 characters + ' +
			'(at least 1 uppercase letter, at least 1 lowercase letter and 1 number)',
		'includes.letters.number':
			'Password must be includes at least 1 uppercase letter, at least 1 lowercase letter and 1 number)',
		'includes.letters.number.underscore':
			'Password must be includes only letters, numbers and underscore character',
		'field.length.min': '{fieldName} must be at least {length} characters',
		'field.length.max': '{fieldName} must be at most {length} characters',
		'button.back': 'Back',
		'session.modal.expired.title': 'Session expired',
		'session.modal.expired.subtitle':
			'If you want to continue, enter your credentials',
		'session.modal.expired.form.email': 'Username or email',
		'session.modal.expired.form.email.placeholder': 'p. ej. Juan',
		'session.modal.expired.form.password': 'Password',
		'session.modal.expired.form.password.placeholder': 'p. ej. Rodriquez',
		'session.modal.expired.form.login': 'Log In',
		'session.modal.expired.form.logout': 'Log Out',
	},
	es: {
		// General fields
		fieldFirstName: 'Nombre',
		fieldFirstNameSubtitle: '(Como aparece en tu identificacion)',
		fieldFirstNamePlaceholder: 'p. ej. Enrique Ernesto',
		fieldLastName: 'Apellido',
		fieldLastNameSubtitle: '(Como aparece en tu identificacion)',
		fieldLastNamePlaceholder: 'p. ej. Arriola Hernandez',
		fieldDay: 'Día',
		fieldMonth: 'Mes',
		fieldYear: 'Año',
		fieldUserName: 'Nombre de usuario',
		fieldEmail: 'Correo electrónico',
		fieldEmailSubtitle: '(Para recibir ofertas exclusivas)',
		fieldEmailPlaceholder: 'p. ej. juan123@gmail.com',
		loginFormFieldUsernameEmail: 'Usuario o email',
		fieldForgotPasswordEMail: 'Enviar correo electrónico',
		fieldForgotPasswordEMailPlaceholder: 'Correo electrónico',
		fieldPassword: 'Contraseña',
		fieldPasswordPlaceholder: 'Contraseña segura',
		fieldReEnterPassword: 'Volver a introducir la contraseña',
		fieldPhoneNumber: 'Número de teléfono',
		fieldPhoneNumberSubtitle: '(Se enviara un codigo de activacion)',
		fieldPhoneNumberPlaceholder: 'p. ej. 7911 123456',
		fieldBirthday: 'Fecha de nacimiento',
		fieldAllowPromotions: 'Me gustaría obtener promociones',
		fieldAllowMarketing:
			'Acepto recibir boletines, información promocional y actualizaciones de productos por correo electrónico o sms',
		fieldCouponCode: 'p. ej. 123456',
		fieldCouponCodeQuestion: '¿Tienes código de ',
		fieldCouponCodeButton: 'cupón?',
		fieldCouponCodeSubstring: '(opcional)',
		fieldCouponCodeInputLabel: 'Cupón',
		hintPassword:
			'Mínimo 8 caracteres (al menos 1 letra mayúscula, 1 letra minúscula y 1 número)',
		hintPasswordMinMax: '8-30 caracteres',
		hintPasswordUppercase: 'una letra mayúscula',
		hintPasswordLowercase: 'una letra minúscula',
		hintPasswordNumber: 'un número',
		hintPasswordSpaces: 'sin espacios',
		hintPhoneNumber: 'Ingresa tu número de celular real para que sea verificado',
		hintNickName: 'Solo puedes poner una palabra',
		hintCouponCode: 'Una palabra, por ejemplo: “FC6533AT“',
		formMessageConditions:
			'Registrarte o crear tu cuenta significa que estás de acuerdo con <a style="text-decoration: underline; cursor: pointer; " href="/info/terms-and-conditions" rel="noreferrer" target="_blank" data-testid="terms-and-conditions">Los términos y condiciones</a> & <a style="text-decoration: underline; cursor: pointer; " href="/info/privacy-policy" rel="noreferrer" target="_blank" data-testid="privacy-policy">La política de privacidad</a>.',

		// SingUp
		signUpSupport: 'Soporte',
		singUpFormTitle: 'Abrir Una Cuenta',
		singUpFormButtonSubmit: 'Regístra Una Cuenta Nueva',
		'signUp.form.button.open.account': 'Abrir Una Cuenta!',
		'signUp.form.button.submit': 'Registrate',
		'signUp.form.complete': 'Por favor complete el formulario',
		'signUp.form.success.done': 'FELICIDADES!',
		'signUp.form.successfully.registered1': 'Has registrado tu cuenta con éxito.',
		'signUp.form.successfully.registered': 'Tu cuenta fue creada exitosamente.',
		'signUp.form.button.exit': 'Salida',
		'signUp.form.button.continue': 'Continuar',
		'signUp.form.want.cancel.exit.title':
			'Estás a 2 clics de obtener tu bono gratis de $ 500,',
		'signUp.form.want.cancel.exit': '¿Estás seguro de que quieres cancelar y salir?',
		'signUp.form.button.play.now': 'Pagar ahora',
		// 'signUp.error.minimum.char.includes.letters.number':
		// 	'La contraseña debe incluir un mínimo de 8 caracteres (al menos 1 letra mayúscula, al menos 1 letra minúscula y 1 número)',
		'signUp.error.minimum.char.includes.letters.number':
			'mínimo 8 caracteres, /n al menos 1 letra mayúscula, /n al menos 1 letra minúscula, /n al menos 1 número',
		'signUp.error.letters.uppercase': 'al menos 1 letra mayúscula',
		'signUp.error.letters.lowercase': 'al menos 1 letra minúscula',
		'signUp.error.numbers.lowercase': 'al menos 1 número',
		'signUp.error.minimum.char': 'Minimum 8 characters',
		'signUp.error.seon.restrict.title': '¡Ups!',
		'signUp.error.seon.restrict.leading':
			'Parece que hay un problema con tu registro. Comuníquese con nuestro equipo de atención al cliente para obtener más ayuda. Nos disculpamos por cualquier inconveniente.',
		'error.ageVerification': 'La edad mínima para registrarse es de 18 años',
		'incorrect.day': 'Día incorrecto',
		'incorrect.month': 'Mes incorrecto',
		'incorrect.year': 'Año incorrecto',

		// Login
		// loginFormTitle: 'Abrir Una Cuenta',
		loginFormTitle: 'Acceder',
		loginFormButtonSignUp: 'Registra una cuenta nueva',
		loginFormButtonFacebook: 'Conectar',
		loginFormButtonSubmit: 'Acceder',
		signUpFormButtonSubmit: 'Registrate',
		signUpFormButtonSubmitFirstStep: 'Siguiente',
		signUpFormButtonSubmitSecondStep: 'Registrate',
		loginFormFieldRememberMe: 'Recuérdame',
		loginFormForgotPassword: '¿Has olvidado tu contraseña?',
		'login.modal.forgotPassword.buttonBySMS': 'Restablecer a través de SMS',
		'login.modal.forgotPassword.buttonByEmail': 'Restablecer a través de Email',
		'login.modal.forgotPassword.desc.phone': 'Introduzca su número de teléfono',
		'login.modal.forgotPassword.hint.phone':
			'Los mensajes de texto (SMS) solo se pueden usar si el número de teléfono móvil ha sido verificado.',
		'verification.method.phone': 'Número de teléfono',
		'verification.method.phone.subtitle': '(Se enviara un codigo de activacion)',
		'verification.description.resetPassword':
			'Hemos enviado un código SMS a tu teléfono. {phoneNumber}',
		'verification.needHelp': 'Tiene problemas? Póngase en contacto con el ',
		'verification.button.support': 'servicio de asistencia',
		'verification.button.resend': 'Reenviar',
		'verification.button.resendOTP': 'Reenviar OTP',
		'verification.error.invalidCode': 'OTP inválido introducido',
		'verification.timeUp': 'Tiempo de espera. ',
		confirmPassword: 'Confirmar contraseña',
		'required.field': '{fieldName} es un campo obligatorio',
		'login.modal.resetPassword.input.newPassword': 'Nueva contraseña',
		'login.modal.forgotPassword.not.exist.phone.title':
			'El número de teléfono proporcionado \nno está verificado o no existe',
		'login.modal.forgotPassword.reset.limit.phone.title':
			'Actualmente la opción de recuperar tu contraseña con SMS no está disponible',
		'login.modal.forgotPassword.buttonByEmailAfterPhone':
			'Continuar con correo electrónico',
		forgotPasswordFormEnterYourEmail: 'Introduzca su dirección de correo electrónico',
		'forgotPassword.incorrect.credentials':
			'La cuenta con ese correo electrónico no existe',
		loginFormNoAccount: 'Nuevo en Winpot?',
		loginFormButtonCreate: 'Crear cuenta',
		'login.form.button.openAccount': 'Abrir Una Cuenta',
		'login.form.or': 'o',
		'login.form.or.has.account': 'o ya tiene una cuenta',
		'login.form.have.account': 'Ya tienes una cuenta?',
		'login.form.logIn': 'Acceder',
		'signUp.info.message.alreadyHaveAccount': '¿Ya tiene una cuenta?',
		// LogOut
		'sideMenu.modal.logOut.title': '¿Estás seguro de que quieres cerrar la sesión?',
		'sideMenu.modal.logOut.message': '¿Realmente quieres salir de tu cuenta?',
		cancel: 'Cancelar',
		'sideMenu.button.logOut': 'Cerrar la sesión',

		// SmallModal
		'smallModal.title.congratulations': '¡Enhorabuena!',
		'smallModal.title.confirmation': '¿Está seguro?',
		'smallModal.description.removeDocument':
			'¿Desea eliminar este documento de la verificación?',
		'smallModal.description.changePassword': 'Su contraseña ha sido cambiada.',

		// SuccessRegistrationModal
		successRegistrationModalCongratulations: '¡Enhorabuena!',
		successRegistrationModalMainText: 'Acaba de crear su cuenta en Winpot',
		successRegistrationModalButtonSubmit: 'Deposita Ahora',

		// Validation
		'password.requirement':
			'La contraseña debe contener un mínimo de 8 caracteres (al menos 1 letra mayúscula, al menos 1 letra minúscula y 1 número)',
		'password.mismatch': 'Error en la contraseña',
		'password.short': 'La contraseña es demasiado corta',
		'password.submit': 'Enviar',
		'login.incorrect': 'El inicio de sesión es incorrecto',
		'latin.spanish.only': 'Por favor introduzca texto en español o latín',
		'latin.spanish.numbers.only':
			'Por favor, introduzca sólo letras y números latinos o españoles',
		'minimum.char': 'Mínimo {value} caracteres',
		'maximum.char': 'Máximo {value} caracteres',
		'incorrect.phone': 'El número de teléfono es incorrecto',
		'incorrect.email': 'Tu correo electrónico es incorrecto',
		'domain.blacklist': 'El dominio está en la lista negra',
		'whitespace.not.allowed': 'Los espacios en blanco no están permitidos',
		'minimum.char.includes.letters.number':
			'La contraseña debe incluir un mínimo de 8 caracteres' +
			' (al menos 1 letra mayúscula, al menos 1 letra minúscula y 1 número)',
		'includes.letters.number':
			'La contraseña debe incluir al menos 1 letra mayúscula, al menos 1 letra minúscula y 1 número)',
		'includes.letters.number.underscore':
			'La contraseña debe incluir sólo letras, números y guiones bajos',
		'field.length.min': '{fieldName} debe tener al menos {length} caracteres',
		'field.length.max': '{fieldName} debe tener como máximo {length} caracteres',
		'button.back': 'Volver',
		'session.modal.expired.title': 'Sesión expirada',
		'session.modal.expired.subtitle': 'Si desea continuar, ingrese sus credenciales',
		'session.modal.expired.form.email': 'Usuario o email',
		'session.modal.expired.form.email.placeholder': 'p. ej. Juan',
		'session.modal.expired.form.password': 'Contraseña',
		'session.modal.expired.form.password.placeholder': 'p. ej. Rodriquez',
		'session.modal.expired.form.login': 'Inicio de sesión',
		'session.modal.expired.form.logout': 'Cerrar la sesión',
	},
	ru: {
		loginFormFieldUsernameEmail: 'Никнейм/Емаил',
	},
};

export default translationForms;
