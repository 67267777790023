import clsx from 'clsx';
import React from 'react';

import styles from './checkBox.module.scss';
import CheckIcon from './CheckIcon';
import { REACT_APP_WINPOT_SHOW_NEW_SIGN_UP } from '../../utils/consts';

const CheckBox = ({
	label,
	disabled,
	errorText,
	setSelectedValue,
	name,
	checked,
	className,
	checkedClassName = '',
	labelClassName = '',
	modal = '',
	id = '',
	dataTestId = '',
}) => {
	const handleChange = (e) => {
		setSelectedValue((prev) => ({
			...prev,
			[e.target.name]: !checked,
		}));
	};

	return (
		<>
			<div
				className={clsx(styles.wrapper, {
					[styles.newSignUpForm]: REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
					[styles.remember__checkbox]: name === 'remember',
				})}
			>
				<input
					id={id || label + modal}
					type="checkbox"
					name={name ?? label}
					className={clsx(styles.checkBox, className)}
					onChange={handleChange}
					checked={checked}
					disabled={disabled}
					data-testid={dataTestId}
				/>
				<span
					className={clsx(
						styles.checkbox__container,
						{
							[styles.checkbox__container__checked]: checked,
						},
						checkedClassName
					)}
				>
					{checked && <CheckIcon color={'#69657D'} />}
				</span>
				<label
					className={clsx(styles.label, labelClassName)}
					htmlFor={id || label + modal}
				>
					{label}
				</label>
				<p className="errorText">{errorText}</p>
			</div>
		</>
	);
};

export default CheckBox;
