const notification = {
	en: {
		'account.status.is': 'Account status is {{accountStatus}}',
		'already.exists': 'This {field} already exists',
		'user.not.found': 'Email already in use',
		'duplication.error': 'Duplication error',
		'empty.email.nickname': 'Empty email and nickname',
		'empty.password': 'Empty password',
		'equal.passwords': 'The new password cannot be equal to the current password',
		'incorrect.credentials': 'The username/email or password is incorrect',
		'invalid.confirmation.code': 'Invalid confirmation code',
		'expired.confirmation.code':
			"Link is expired. For password change go to 'Forgot your password?' form",
		'missing.fields.error': 'Missing fields error',
		'must.be.set': 'This {{field}} must be set',
		'must.be.valid': 'This {{field}} must be valid',
		'nothing.to.update': 'Nothing to update',
		oldPassword: 'Old password is required',
		oldPasswordShort: 'Old password is short. Check it, please',
		'password.incorrect': 'Password is incorrect',
		'notification.empty.password': 'Empty password',
		'notification.empty.email': 'Empty email/username',
		'notification.empty.emailAndPassword': 'Empty email/username and password',
		'document.not.found': 'Cannot find document with this id',
		'document.already.canceled': 'The document is already canceled',
		'document.already.deleted': 'The document is already deleted',
		'document.already.rejected': 'The document is already rejected',
		'document.already.approved': 'The document is already approved',
		'document.already.expired': 'The document is already expired',
		'failed.upload.document': 'Failed to upload document!',
		'file.too.large': 'File is too large!',
		'players.field.email': 'email',
		'players.field.phone': 'phone',
		'players.field.nickName': 'username',

		'error.invalid.date.format': 'Invalid Date Format',
		'error.ageVerification': 'The minimum age to register is 18 years',
		'error.minRegistrationDate': 'Date of birth should not be before 01.01.1920',
		'duplicate.phoneNumber': 'User with this phone already exist',
		'duplicate.email': 'User with this email already exist',
		'duplicate.nickName': 'User with this nick name already exist',
	},

	es: {
		'account.status.is': 'El estado de la cuenta es {{accountStatus}}',
		'already.exists': 'Este {field} ya existe',
		'user.not.found': 'Correo electrónico ya en uso',
		'duplication.error': 'Error de duplicación',
		'empty.email.nickname': 'Correo electrónico y apodo vacíos',
		'empty.password': 'Contraseña vacía',
		'equal.passwords': 'La nueva contraseña no puede ser igual a la actual',
		'incorrect.credentials':
			'El nombre de usuario/correo electrónico o la contraseña son incorrectos',
		'invalid.confirmation.code': 'Código de confirmación inválido',
		'expired.confirmation.code':
			"El enlace ha caducado. Para cambiar la contraseña, vaya al formulario '¿Ha olvidado su contraseña?'",
		'missing.fields.error': 'Error de campos faltantes',
		'must.be.set': 'Este {{field}} debe ser ajustado',
		'must.be.valid': 'Este {{field}} debe ser válido',
		'nothing.to.update': 'Nada que actualizar',
		oldPassword: 'Se requiere una contraseña antigua',
		oldPasswordShort: 'La contraseña antigua es corta. Compruébela, por favor',
		'password.incorrect': 'La contraseña es incorrecta',
		'notification.empty.password': 'Contraseña vacía',
		'notification.empty.email': 'Correo electrónico/nombre de usuario vacíos',
		'notification.empty.emailAndPassword':
			'Correo electrónico/nombre de usuario y contraseña vacíos',
		'document.not.found': 'No se puede encontrar el documento con este id',
		'document.already.canceled': 'El documento ya está cancelado',
		'document.already.deleted': 'El documento ya está borrado',
		'document.already.rejected': 'El documento ya está rechazado',
		'document.already.approved': 'El documento ya está aprobado',
		'document.already.expired': 'El documento ya está caducado',
		'failed.upload.document': 'No se ha podido cargar el documento!',
		'file.too.large': 'El archivo es demasiado grande!',
		'players.field.email': 'Correo electrónico',
		'players.field.phone': 'Teléfono',
		'players.field.nickName': 'Nombre de usuario',

		'error.invalid.date.format': 'Formato de fecha no válido',
		'error.ageVerification': 'La edad mínima para registrarse es de 18 años',
		'error.minRegistrationDate':
			'Tu fecha de nacimiento no debe ser menor a 01/01/1920',
		'duplicate.phoneNumber': 'Ya existe un usuario con este teléfono',
		'duplicate.email': 'Ya existe un usuario con este correo electrónico',
		'duplicate.nickName': 'Ya existe un usuario con este nombre de usuario',
	},
};

export default notification;
