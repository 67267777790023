import { resetErrorServerMessage } from '../../../redux/actions/userSession';
import React, { useEffect, useState } from 'react';
import { isLoggedIn } from '../../../utils/session';
import screenSizes, {
	AccountStatus,
	getRedirectAfterRegistration,
	REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
	removeCookieFromDomains,
	setCookieToDomains,
	topNav,
	WINPOT_CASINO_URL,
} from '../../../utils/consts';

import Button from '../../../UI/Button/Button';
import Cookies from 'js-cookie';
import ForgotPassword from '../../Modals/ForgotPassword/ForgotPassword';
import LoginModal from '../../Modals/Login/Login';
import Menu from '../../SideMenu/Menu';
import { PopupType } from '../../Modals/SmallModal/SmallModal';
import ProfileDropdown from './ProfileDropdown';
import WinpotLogo from '../../../UI/icons/RedLogo';
import { actions } from '../../../redux/actions/commonActions';
import clsx from 'clsx';
import getMessage from '../../../utils/locale';
import navbarStyles from './navbar.module.scss';
import { useDispatch } from 'react-redux';
import { useModal, useUserData } from '../../../redux/selectors';
import ConfirmationPopup from '../../Modals/ConfirmationPopup/ConfirmationPopup';
import SignUpModal from '../../Modals/SignUp/signUp';
import ShowSeonRestrict from '../../Modals/ShowSeonRestrict/ShowSeonRestrict';
import SignUpModalOldVersion from '../../Modals/SignUp/signUpOldVersion';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import SupportIcon from '../../../images/navbar/support.svg';
import { clickedOnLink, handleClickOnGamification } from '../../../utils/common';

const Navbar = ({ setShowSpinner }) => {
	const dispatch = useDispatch();
	const userData = useUserData();

	const [openSideMenu, setOpenSideMenu] = useState(false);
	const [userFrozenOrBlocked, setUserFrozenOrBlocked] = useState(false);
	const { category, historyPopupType = [] } = useModal();
	const size = useWindowSize();
	const afterResetPassInitState = {
		open: false,
		title: '',
		description: '',
		icon: null,
		openLogin: false,
	};
	const [modalAfterResetPassword, setModalAfterResetPassword] = useState(
		afterResetPassInitState
	);

	const resetModals = () => {
		dispatch({ type: actions.ClosePopUp });
	};

	const onBlockPopupClose = () => {
		dispatch(resetErrorServerMessage());
		setUserFrozenOrBlocked(false);
	};

	useEffect(() => {
		if (
			userData?.dataError?.data?.accountStatus === AccountStatus.Blocked ||
			userData?.dataError?.data?.accountStatus === AccountStatus.Frozen ||
			userData?.dataError?.data?.accountStatus === AccountStatus.CoolOff ||
			userData?.dataError?.data?.accountStatus === AccountStatus.Closed ||
			userData?.dataError?.data?.accountStatus === AccountStatus.SelfClosed
		) {
			dispatch({
				type: actions.OpenPopUp,
				payload: {
					category: PopupType.Confirm,
					historyPopupType: [...historyPopupType, PopupType.Confirm],
				},
			});
			setUserFrozenOrBlocked(true);
		} else {
			setUserFrozenOrBlocked(false);
		}
	}, [userData?.dataError?.message]);

	useEffect(() => {
		if (
			category === PopupType.None &&
			historyPopupType?.length &&
			size.width <= screenSizes.mobileMax
		) {
			if (
				historyPopupType[historyPopupType.length - 1] === PopupType.SignUp &&
				historyPopupType[historyPopupType.length - 2] === PopupType.Login
			) {
				dispatch({
					type: actions.OpenPopUp,
					payload: {
						category: PopupType.Login,
						historyPopupType: [...historyPopupType, PopupType.Login],
					},
				});
			}
			if (
				historyPopupType[historyPopupType.length - 1] === PopupType.SignUp &&
				historyPopupType[historyPopupType.length - 2] === PopupType.LoginPromo
			) {
				dispatch({
					type: actions.OpenPopUp,
					payload: {
						category: PopupType.LoginPromo,
						historyPopupType: [...historyPopupType, PopupType.LoginPromo],
					},
				});
			}
		}
	}, [historyPopupType, category, size.width]);

	const onHamburgerClick = () => {
		setOpenSideMenu((prev) => !prev);
	};

	const openSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	const checkIsAccount = (e, link) => {
		clickedOnLink(link, dispatch, () => setShowSpinner(true));
	};

	useEffect(() => {
		localStorage.removeItem('loginRequest');
	}, []);
	document.body.style.overflow = 'hidden';

	const loginButtons = (
		<div className={navbarStyles.navbar__right}>
			{size.width > screenSizes.tabletMiddle && (
				<div className={navbarStyles.navbar__right__buttons}>
					<img
						src={SupportIcon}
						alt={getMessage('es', 'headerNavbarSupportIcon')}
						onClick={openSupport}
						data-testid="support-button"
						className={navbarStyles.navbar__right__support__button}
					/>
				</div>
			)}
			<div className={navbarStyles.loginButtons}>
				<Button
					text={getMessage('es', 'headerNavbarButtonSignUp')}
					onClick={() => {
						setCookieToDomains('product_2', '1');
						dispatch({
							type: actions.OpenPopUp,
							payload: {
								category: PopupType.SignUp,
								historyPopupType: [...historyPopupType, PopupType.SignUp],
							},
						});
						setOpenSideMenu(false);
					}}
					classes={'btnPrimaryGreen'}
					dataTestId={'signUp-button'}
				/>
				<Button
					text={getMessage('es', 'headerNavbarButtonLogIn')}
					classes={'btnPrimaryGreenOutlined'}
					type={'button'}
					onClick={() => {
						dispatch({
							type: actions.OpenPopUp,
							payload: {
								category: PopupType.Login,
								historyPopupType: [...historyPopupType, PopupType.Login],
							},
						});
						setOpenSideMenu(false);
					}}
					dataTestId={'signIn-button'}
				/>
			</div>
		</div>
	);

	useEffect(() => {
		const afterDepositURL = Cookies.get('afterDepositURL');
		if (isLoggedIn() || afterDepositURL) {
			sessionStorage.setItem('prevPath', WINPOT_CASINO_URL);
			let url = WINPOT_CASINO_URL + getRedirectAfterRegistration();
			setShowSpinner(true);
			if (afterDepositURL) {
				removeCookieFromDomains('afterDepositURL');
				url += '?modal=PaymentCompleted';
			}
			window.location.href = url;
		}
	}, [isLoggedIn()]);

	const openModalWithURl = (modalName) => {
		switch (modalName) {
			case PopupType.SignUp:
				if (category !== PopupType.SignUp && !isLoggedIn()) {
					dispatch({
						type: actions.OpenPopUp,
						payload: {
							category: PopupType.SignUp,
						},
					});
				}
				break;
			case PopupType.Login:
				if (category !== PopupType.Login && !isLoggedIn()) {
					dispatch({
						type: actions.OpenPopUp,
						payload: {
							category: PopupType.Login,
						},
					});
				}
				break;
			case PopupType.LoginPromo:
				if (category !== PopupType.LoginPromo && !isLoggedIn()) {
					dispatch({
						type: actions.OpenPopUp,
						payload: {
							category: PopupType.LoginPromo,
						},
					});
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const activeModalName = new URL(window.location.href).searchParams.get('modal');
		if (activeModalName) {
			openModalWithURl(activeModalName);
		}
		const popStateEvent = (e) => {
			const activeModalName = new URL(window.location.href).searchParams.get(
				'modal'
			);
			if (activeModalName) {
				openModalWithURl(activeModalName);
			} else {
				console.log('category', category);
				resetModals();
			}
		};
		window.addEventListener('popstate', popStateEvent);

		return () => window.removeEventListener('popstate', popStateEvent);
	}, []);

	return (
		<>
			<div>
				<div className={navbarStyles.topNavContainer}>
					<nav id="topNav" className={navbarStyles.navContainer}>
						<div
							className={clsx(navbarStyles.hamburger, {
								[navbarStyles.toggle]: openSideMenu,
							})}
							onClick={onHamburgerClick}
							data-testid="menu-mobile-icon"
						>
							<div className={navbarStyles.line1} />
							<div className={navbarStyles.line2} />
							<div className={navbarStyles.line3} />
						</div>
						<div className={navbarStyles.logo}>
							<a href="/" data-testid="winpot-logo">
								<div className={navbarStyles.companyLogo}>
									<WinpotLogo />
									{/* <img src={XmasLogoDesktop} alt="Xmas Winpot Logo" />*/}
								</div>
							</a>
						</div>
						<ul className={navbarStyles.navLinks}>
							{topNav.map(({ label, link = '' }, index) => {
								const winClubItem = label.toLowerCase() === 'winclub';

								return (
									<li key={index}>
										<a
											onClick={(e) => checkIsAccount(e, link)}
											className={clsx({
												[navbarStyles.isWinclub]: winClubItem,
											})}
											data-testid={`${label
												.replace(/[ ]/g, '-')
												.toLowerCase()}-menu-link`}
										>
											{getMessage(
												'es',
												`Navigation${
													winClubItem ? 'WinpotClub' : label
												}`
											)}
										</a>
									</li>
								);
							})}
						</ul>
						{size.width > screenSizes.MENU_VISIBILITY && (
							<div className={navbarStyles.gamification}>
								<div
									className={navbarStyles.gamification__title}
									onClick={() =>
										handleClickOnGamification(
											dispatch,
											historyPopupType
										)
									}
								>
									{getMessage('es', 'NavigationGamificationTitle')}
								</div>
							</div>
						)}
						<div className={navbarStyles.right_site_button_wrapper}>
							{!isLoggedIn() ? <>{loginButtons}</> : <ProfileDropdown />}
						</div>
					</nav>
				</div>
			</div>
			{openSideMenu && (
				<Menu setOpenSideMenu={setOpenSideMenu} setShowSpinner={setShowSpinner} />
			)}
			{category === PopupType.SignUp && REACT_APP_WINPOT_SHOW_NEW_SIGN_UP && (
				<SignUpModal />
			)}
			{category === PopupType.SignUp && !REACT_APP_WINPOT_SHOW_NEW_SIGN_UP && (
				<SignUpModalOldVersion />
			)}
			{(category === PopupType.Login || category === PopupType.LoginPromo) && (
				<LoginModal
					inModal
					isLoginPromoPopUp={category === PopupType.LoginPromo}
				/>
			)}
			{category === PopupType.ForgotPassword && !modalAfterResetPassword.open && (
				<ForgotPassword setModalAfterResetPassword={setModalAfterResetPassword} />
			)}
			{category === PopupType.showSeonRestrict && <ShowSeonRestrict />}
			{userFrozenOrBlocked && (
				<ConfirmationPopup
					title={getMessage(
						'es',
						`navbar.user.blocked.${userData?.dataError?.data?.accountStatus}`
					)}
					description={getMessage('es', 'navbar.popup.blocked.description')}
					onClose={onBlockPopupClose}
					isFrozenAcc
					isWhite
				/>
			)}
			{modalAfterResetPassword?.open && (
				<ConfirmationPopup
					title={modalAfterResetPassword.title}
					description={modalAfterResetPassword.description}
					icon={modalAfterResetPassword.icon}
					isWhite={true}
					onClose={() => {
						setModalAfterResetPassword(afterResetPassInitState);
						if (modalAfterResetPassword?.openLogin) {
							dispatch({
								type: actions.OpenPopUp,
								payload: {
									category: PopupType.Login,
									historyPopupType: [
										...historyPopupType,
										PopupType.Login,
									],
								},
							});
						}
					}}
				/>
			)}
		</>
	);
};

export default Navbar;
