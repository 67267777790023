import React, { useEffect, useState } from 'react';
import getMessage from '../../../utils/locale';

import styles from './PromoPopup.module.scss';
import { PopupContainer } from '../../Container/Container';
import { PopupType } from '../../Modals/SmallModal/SmallModal';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import screenSizes from '../../../utils/consts';
import clsx from 'clsx';
import { tabs, TABS_TYPE } from './PromoTypes';

const PromoPopup = (props) => {
	const size = useWindowSize();
	const { showPromoPopup, popupData } = props;

	const [activeTab, setActiveTab] = useState(TABS_TYPE.DETAILS);

	const [fullDays, setFullDays] = useState(null);
	const [fullHours, setFullHours] = useState(null);
	const [fullMinutes, setFullMinutes] = useState(null);
	const [fullSeconds, setFullSeconds] = useState(null);
	const [expired, setExpired] = useState(false);

	const sec = 1000;
	const min = sec * 60;
	const hour = min * 60;
	const day = hour * 24;
	const options = {
		timeZone: 'America/Mexico_City', // Set the time zone to Mexico City
	};
	const now = new Date(new Date().toLocaleString('en-US', options));
	// Get campaign date on Mexico
	const endCampaignDate = new Date(
		new Date(popupData.endDate).toLocaleString('en-US', options)
	);
	if (endCampaignDate <= now) {
		!expired && setExpired(true);
	}
	const int = setInterval(() => {
		if (endCampaignDate > now) {
			const current = new Date(
				new Date().toLocaleString('en-US', options)
			).getTime();
			const remaining = endCampaignDate - current;
			setFullDays(Math.floor(remaining / day));
			setFullHours(Math.floor((remaining % day) / hour));
			setFullMinutes(Math.floor((remaining % hour) / min));
			setFullSeconds(Math.floor((remaining % min) / sec));
		}
	}, 1000);

	useEffect(() => {
		if (fullSeconds <= 0 || expired) {
			clearInterval(int);
		}
		return () => clearInterval(int);
	}, [fullSeconds, expired]);

	const details = () => {
		return (
			<div className={styles.promo__text}>
				{!expired && !!popupData.endDate && (
					<div className={styles.promotions__popup__countdown__wrapper}>
						{!!fullDays && (
							<div>
								<div
									className={
										styles.promotions__popup__countdown__num +
										' ' +
										styles.days
									}
								>
									{fullDays}
								</div>
								<div
									className={styles.promotions__popup__countdown__text}
								>
									{getMessage('es', 'promoPopUpTimerDays')}
								</div>
							</div>
						)}
						{!!fullHours && (
							<div>
								<div
									className={
										styles.promotions__popup__countdown__num +
										' ' +
										styles.hours
									}
								>
									{fullHours}
								</div>
								<div
									className={styles.promotions__popup__countdown__text}
								>
									{getMessage('es', 'promoPopUpTimerHours')}
								</div>
							</div>
						)}
						{!!fullMinutes && (
							<div>
								<div
									className={
										styles.promotions__popup__countdown__num +
										' ' +
										styles.minutes
									}
								>
									{fullMinutes}
								</div>
								<div
									className={styles.promotions__popup__countdown__text}
								>
									{getMessage('es', 'promoPopUpTimerMinutes')}
								</div>
							</div>
						)}
						<div>
							<div
								className={
									styles.promotions__popup__countdown__num +
									' ' +
									styles.seconds
								}
							>
								{fullSeconds}
							</div>
							<div className={styles.promotions__popup__countdown__text}>
								{getMessage('es', 'promoPopUpTimerSeconds')}
							</div>
						</div>
					</div>
				)}
				{popupData.detailsText}
			</div>
		);
	};

	const terms = () => {
		return <div className={styles.promo__text}>{popupData.termsText}</div>;
	};

	const showContent = () => {
		switch (activeTab) {
			case TABS_TYPE.DETAILS:
				return details();
			case TABS_TYPE.TERMS:
				return terms();
			default:
				return <div />;
		}
	};

	return (
		<PopupContainer
			classes={styles.promo__container}
			onClose={showPromoPopup}
			closeByClick={true}
			displayCloseButton={true}
			displayBackButton={false}
			imageSize="large"
			isSmallModal={true}
			typeModal={PopupType.PromoPopup}
			open
		>
			<div className={styles.promo__wrapper}>
				{size.width > screenSizes.mobileMiddle && (
					<h2 className={styles.promo__title}>{popupData.popupTitle}</h2>
				)}
				<div className={styles.promo__media}>
					<img
						className={styles.promo__img}
						src={popupData.popupImage}
						alt={popupData.popupTitle}
					/>
				</div>
				{size.width <= screenSizes.mobileMiddle && (
					<h2 className={styles.promo__title__mobile}>
						{popupData.popupTitle}
					</h2>
				)}
				<div className={styles.promo__content}>
					<div className={styles.promo__btns}>
						{tabs?.map((item) => {
							return (
								<button
									className={clsx(styles.promo__btn, {
										[styles.promo__btn__active]:
											activeTab === TABS_TYPE[item.tab],
									})}
									onClick={() => setActiveTab(TABS_TYPE[item.tab])}
									key={item.title}
								>
									{getMessage('es', item.title)}
								</button>
							);
						})}
					</div>
					<div className={styles.promo__content__inner}>{showContent()}</div>
				</div>
			</div>
		</PopupContainer>
	);
};

export default PromoPopup;
