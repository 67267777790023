import React from 'react';

const Error = ({ color = '#E73843' }) => (
	<svg fill={color}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.7727 12.3629C21.7727 17.9611 17.2345 22.4993 11.6364 22.4993C6.03821 22.4993 1.5 17.9611 1.5 12.3629C1.5 6.76477 6.03821 2.22656 11.6364 2.22656C17.2345 2.22656 21.7727 6.76477 21.7727 12.3629ZM23.2727 12.3629C23.2727 18.7895 18.063 23.9993 11.6364 23.9993C5.20978 23.9993 0 18.7895 0 12.3629C0 5.93634 5.20978 0.726562 11.6364 0.726562C18.063 0.726562 23.2727 5.93634 23.2727 12.3629ZM15.5938 15.2109C15.8884 15.5055 15.8884 15.9833 15.5938 16.2779C15.2991 16.5725 14.8214 16.5725 14.5268 16.2779L7.78759 9.53871C7.49295 9.24407 7.49295 8.76637 7.78759 8.47173C8.08223 8.17709 8.55994 8.17709 8.85458 8.47173L15.5938 15.2109Z"
			fill={color}
		/>
		<line
			x1="8.21664"
			y1="15.7173"
			x2="14.9407"
			y2="8.99122"
			stroke={color}
			strokeWidth="1.50748"
			strokeLinecap="round"
		/>
	</svg>
);

export default Error;
