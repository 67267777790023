import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { phoneCodes } from './phoneCodes';
import styles from './MultiSelect.module.scss';
import stylesSimpleSelect from './SimpleSelect.module.scss';
import Input from './Input';
import { REACT_APP_WINPOT_SHOW_NEW_SIGN_UP } from '../../utils/consts';

const MultiSelect = ({
	size,
	items,
	label,
	errorText,
	placeholder,
	search,
	showOnlySelected,
	iconFlag,
	selectedItem,
	setPhoneCodeIndex,
	widthContent,
	disabled = false,
}) => {
	const ref = useRef(null);
	const [searchValue, setSearchValue] = useState({ search: '' });
	const [showFilter, setShowFilter] = useState(false);
	const [filteredItems, setFilteredItems] = useState(null);
	// for scroll to current country
	const [openBlock, setOpenBlock] = useState(false);
	const [currentCountry, setCurrentCountry] = useState('');

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowFilter(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref.current]);

	const handleButtonClick = () => {
		if (!disabled) {
			setShowFilter(!showFilter);
			setOpenBlock((prev) => !prev);
		}
	};

	const isPhoneList = (listItem) => {
		return listItem.text.startsWith('+');
	};

	const isPhoneListMemoized = useMemo(
		() => items.length && isPhoneList(items[0]),
		[items]
	);

	const handleClickPhone = (ind) => {
		if (setPhoneCodeIndex) {
			setPhoneCodeIndex(ind);
		}
		setShowFilter((prev) => !prev);
		setOpenBlock((prev) => !prev);
	};

	// for scroll to current country
	useEffect(() => {
		if (openBlock) {
			if (currentCountry) {
				document
					.getElementById(currentCountry)
					.scrollIntoView({ block: 'nearest' });
			}
		}
	}, [openBlock]);

	useEffect(() => {
		setFilteredItems(items);
	}, [items]);

	useEffect(() => {
		setFilteredItems(() =>
			items?.filter(
				(item) =>
					item?.text?.includes(searchValue.search?.toLowerCase()) ||
					item?.name?.toLowerCase().includes(searchValue.search?.toLowerCase())
			)
		);
	}, [searchValue]);

	const flag = iconFlag ? (
		<>
			<CircleFlag countryCode={phoneCodes[selectedItem].codeFlag} height={'20'} />
			<span className={clsx(styles.codePhone, styles.codePhoneLine)}>
				{phoneCodes[selectedItem].text}
			</span>
		</>
	) : null;

	return (
		<>
			<div
				className={clsx(styles.wrapper, {
					[styles.tiny]: size === 'tiny',
					[styles.small]: size === 'small',
					[styles.medium]: size === 'medium',
				})}
			>
				{label && (
					<label className={styles.label} htmlFor={label}>
						{/* {t(label, { ns: 'promotions' })}:*/}
					</label>
				)}

				<div ref={ref}>
					<button
						defaultValue={placeholder}
						className={clsx(
							stylesSimpleSelect.select,
							styles.selectLine,
							styles.btn,
							{
								[styles.btnLine]: iconFlag,
								[styles.btnDisabled]: disabled,
								[styles.newSignUpForm]: REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
								[stylesSimpleSelect.newSignUpForm]:
									REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
							}
						)}
						onClick={handleButtonClick}
						type={'button'}
					>
						{placeholder || (iconFlag && flag)}
					</button>

					<div
						className={clsx(styles.wrapperContent, {
							[styles.show]: showFilter,
							[styles.iconFlag]: iconFlag,
						})}
						style={{ width: widthContent }}
					>
						<div style={{ position: 'relative' }}>
							{search && (
								<div
									className={clsx(
										styles.wrapperSearch,
										styles.wrapperSearchLine
									)}
								>
									<Input
										search
										setSelectedValue={setSearchValue}
										name="search"
										// placeholder={t('Search', { ns: 'common' })}
										placeholder={'search'}
										type="text"
									/>
								</div>
							)}
							{showOnlySelected && (
								<>
									<div className={styles.divider} />
								</>
							)}
							<ul className={styles.ul}>
								{filteredItems?.map((item, index) => {
									return (
										<li
											key={`filtered-item-${index}`}
											className={clsx(styles.li, {
												[styles.iconFlag]: iconFlag,
												[styles.active]:
													phoneCodes[selectedItem].name ===
													item.name,
											})}
											onClick={() => {
												isPhoneListMemoized &&
													handleClickPhone(item.ind);
												// for scroll to current country
												setCurrentCountry(item.name);
											}}
											id={item.name}
											style={{ scrollBehavior: 'smooth' }}
										>
											{iconFlag && (
												<>
													<CircleFlag
														countryCode={item.codeFlag}
													/>
													<span className={styles.text}>
														{item.text}
													</span>
													<span className={styles.name}>
														{item.name}
													</span>
												</>
											)}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{errorText && <p className={styles.errorText}>{errorText}</p>}
		</>
	);
};
export default MultiSelect;
