import React from 'react';
import Button from '../../UI/Button/Button';

import styles from './NotFoundPage.module.scss';
import getMessage from '../../utils/locale';
import notFoundGif from '../../images/notFoundPage/Error404.gif';

const NotFoundPage = () => {
	return (
		<div className={styles.container}>
			<img src={notFoundGif} alt="Error" className={styles.image} />
			<h2 className={styles.title}>{getMessage('es', 'notFound.title')}</h2>
			<p className={styles.description}>
				{getMessage('es', 'notFound.description')}
			</p>
			<Button
				type="submit"
				variant="contained"
				classes={'btnPrimaryRed'}
				text={getMessage('es', 'notFound.button')}
				onClick={() => (window.location.href = '/')}
			/>
		</div>
	);
};

export default NotFoundPage;
