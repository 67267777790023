import axios from 'axios';
import Cookies from 'js-cookie';
import { endUserSession } from '../../redux/actions/userSession';

import {
	checkRememberUser,
	destroySession,
	getSiteApi,
	initializeSession,
	removeLocalAuthData,
} from '../session';
import { AccountStatus, setValueToBrowser } from '../consts';

let store;

export const initStore = (initialStore) => {
	store = initialStore;
};

const removeUserFromLs = (isUserWithoutActiveAccountStatus = false) => {
	if (store) {
		store.dispatch(endUserSession());
	}
	localStorage.removeItem('email');
	localStorage.removeItem('userId');
	if (!isUserWithoutActiveAccountStatus) {
		location.replace('/');
	}
};

axios.interceptors.request.use((config) => {
	config.withCredentials = true;
	const authorizationHeader = Cookies.get('AuthorizationToken');
	if (authorizationHeader) {
		config.headers.Authorization = authorizationHeader;
	}
	return config;
});

axios.interceptors.response.use(
	(response) => {
		// Save current time for checking of the user activity
		// Do not consider token refresh or balance loading as an activity
		if (
			!response.config.url.endsWith('player/crm/refreshToken') &&
			!response.config.url.endsWith('player/financial/balance')
		) {
			localStorage.setItem('lastRequestTime', `${new Date().getTime()}`);
		}

		if (
			response.config.url.endsWith('player/crm/login') ||
			response.config.url.endsWith('player/crm/register')
		) {
			let product = response.data?.product;
			if (!product) {
				product = 1;
			}
			setValueToBrowser('product', product.toString());
		}

		return response;
	},
	async (error) => {
		if (
			((error.status === 500 && error.data.message === 'TokenExpiredError') ||
				error.status === 401 ||
				error.response?.status === 401) &&
			!error.response.config.url.endsWith('player/crm/refreshToken') &&
			!error.response.config.url.endsWith('player/crm/login') &&
			!error.response.config.url.endsWith('player/crm/logout') &&
			!error.response.config.url.endsWith('player/crm/register')
		) {
			const lsUserData = localStorage.getItem('persist:nextjs');
			if (lsUserData) {
				try {
					const userSession = JSON.parse(JSON.parse(lsUserData).userSession);
					const tokenExpirationDate = userSession.expiresIn.tokenExpirationDate;

					if (tokenExpirationDate < Date.now()) {
						return;
					}

					try {
						const refreshingResponse = await axios.post(
							`${getSiteApi()}player/crm/refreshToken`,
							{ remember: checkRememberUser() },
							{
								headers: {
									Authorization: `Bearer ${userSession.refreshToken}`,
								},
								withCredentials: true,
							}
						);
						initializeSession(refreshingResponse.data);

						// Resend previous request
						const requestConfig = {
							headers: {
								Authorization: `Bearer ${refreshingResponse.data.accessToken}`,
							},
							method: error.response.config.method,
							withCredentials: true,
							crossDomain: true,
							url: error.response.config.url,
						};
						if (error.response.config.data) {
							requestConfig['data'] = error.response.config.data;
						}
						return Promise.resolve(axios(requestConfig));
					} catch (e) {
						removeUserFromLs();
						await destroySession();
						window.history.push('/');
					}
				} catch (e) {
					removeUserFromLs();
					await destroySession();
				}
			} else {
				removeUserFromLs();
				await destroySession();
			}
		}
		const isUserWithoutActiveAccountStatus =
			error.response?.data?.data?.accountStatus === AccountStatus.Frozen ||
			error.response?.data?.data?.accountStatus === AccountStatus.Blocked ||
			error.response?.data?.data?.accountStatus === AccountStatus.CoolOff ||
			error.response?.data?.data?.accountStatus === AccountStatus.Closed ||
			error.response?.data?.data?.accountStatus === AccountStatus.SelfClosed;
		if (
			error?.response &&
			error?.response?.status === 401 &&
			isUserWithoutActiveAccountStatus
		) {
			removeUserFromLs(isUserWithoutActiveAccountStatus);
			removeLocalAuthData();
		}
		return Promise.reject(error);
	}
);

export default axios;
