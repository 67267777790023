import React from 'react';

const AppleIcon = () => {
	return (
		<svg
			width="20"
			height="24"
			viewBox="0 0 20 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.3086 12.763C16.3204 11.8431 16.5648 10.9411 17.0189 10.141C17.473 9.34093 18.1221 8.6687 18.9058 8.18688C18.408 7.47583 17.7512 6.89066 16.9876 6.47785C16.224 6.06504 15.3747 5.83597 14.5071 5.80883C12.6563 5.61457 10.8621 6.91628 9.91898 6.91628C8.95763 6.91628 7.50557 5.82811 5.94194 5.86029C4.93054 5.89296 3.94485 6.18707 3.08092 6.71395C2.21698 7.24084 1.50426 7.98253 1.01219 8.86676C-1.11933 12.5572 0.470591 17.9808 2.51241 20.9639C3.53398 22.4246 4.7279 24.0562 6.29011 23.9984C7.81884 23.9349 8.38979 23.0235 10.2351 23.0235C12.0633 23.0235 12.5989 23.9984 14.1928 23.9616C15.8332 23.9349 16.8668 22.4944 17.8525 21.0198C18.5865 19.979 19.1513 18.8287 19.526 17.6115C18.573 17.2084 17.7597 16.5337 17.1875 15.6715C16.6154 14.8093 16.3097 13.7978 16.3086 12.763Z"
				fill="white"
			/>
			<path
				d="M13.2978 3.84712C14.1922 2.77342 14.6329 1.39335 14.5262 0C13.1597 0.14352 11.8975 0.796599 10.991 1.82911C10.5478 2.33352 10.2083 2.92033 9.99205 3.55601C9.77576 4.19169 9.68688 4.86376 9.73048 5.53381C10.414 5.54084 11.0901 5.3927 11.708 5.10054C12.326 4.80838 12.8695 4.37982 13.2978 3.84712Z"
				fill="white"
			/>
		</svg>
	);
};

export default AppleIcon;
