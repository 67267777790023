import { actions } from '../actions/commonActions';

const initialState = {
	loading: false,
	isOpenSupport: false,
	loadingValidateFields: false,
};

export const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.StartRequest: {
			return {
				...state,
				loading: true,
			};
		}

		case actions.StartRequestValidateFields: {
			return {
				...state,
				loadingValidateFields: true,
			};
		}

		case actions.FinishRequest: {
			return {
				...state,
				loading: false,
			};
		}

		case actions.FinishRequestValidateFields: {
			return {
				...state,
				loadingValidateFields: false,
			};
		}

		case actions.ClickedOnSupport: {
			return {
				...state,
				isOpenSupport: action.payload,
			};
		}

		default:
			return state;
	}
};
