import React from 'react';

const InvisibleIcon = ({ color }) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		color={color}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.41317 9.41343C9.23007 9.60993 9.00927 9.76753 8.76394 9.87685C8.51861 9.98616 8.25377 10.0449 7.98523 10.0497C7.71669 10.0544 7.44995 10.005 7.20091 9.90442C6.95188 9.80384 6.72565 9.65412 6.53573 9.4642C6.34582 9.27428 6.1961 9.04806 6.09551 8.79902C5.99492 8.54999 5.94552 8.28325 5.95026 8.0147C5.955 7.74616 6.01378 7.48133 6.12309 7.236C6.2324 6.99066 6.39001 6.76986 6.5865 6.58676M11.9598 11.9601C10.8202 12.8288 9.43258 13.31 7.99984 13.3334C3.33317 13.3334 0.666504 8.0001 0.666504 8.0001C1.49576 6.45469 2.64593 5.1045 4.03984 4.0401L11.9598 11.9601ZM6.59984 2.82676C7.05872 2.71935 7.52855 2.66566 7.99984 2.66676C12.6665 2.66676 15.3332 8.0001 15.3332 8.0001C14.9285 8.75717 14.4459 9.46992 13.8932 10.1268L6.59984 2.82676Z"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M0.666504 0.666748L15.3332 15.3334"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default InvisibleIcon;
