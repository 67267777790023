import React from 'react';

import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, EffectFlip, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import slides from './promoData';

import styles from './Promotions.module.scss';
import PromoSlide from './PromoSlide';

const Promotions = () => {
	SwiperCore.use([Autoplay, EffectFlip, Navigation, Pagination]);
	const params = {
		autoplay: {
			delay: 6000,
			disableOnInteraction: false,
		},
		slidesPerView: 3,
		slidesPerGroup: 3,
		spaceBetween: 16,
		loopedSlides: 3,
		speed: 1500,
		pagination: { clickable: true },
		loop: true,
		setWrapperSize: true,
		observer: true,
		observeParents: true,
		breakpoints: {
			320: {
				slidesPerGroup: 1,
				slidesPerView: 1,
				spaceBetween: 4,
			},
			480: {
				slidesPerGroup: 1,
				slidesPerView: 1,
				spaceBetween: 16,
			},
			900: {
				slidesPerGroup: 1,
				slidesPerView: 1,
			},
			1025: {
				slidesPerGroup: 2,
				slidesPerView: 2,
			},
			1301: {
				slidesPerGroup: 3,
				slidesPerView: 3,
			},
		},
	};

	const setSlider = () => {
		return (
			// @ts-ignore
			<Swiper {...params}>
				{slides.map((slide, idx) => {
					return (
						<SwiperSlide key={idx}>
							<PromoSlide
								slide={slide}
								key={slide.src}
								popupData={slide.popupData}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>
		);
	};

	return (
		<>
			<div className={styles.promotions__wrapper}>
				<div className={styles.promotions}>{setSlider()}</div>
			</div>
		</>
	);
};
export default Promotions;
