import React from 'react';

const Close = ({ color = 'inherit', className = '', size = 'medium' }) => {
	const imageSize = size === 'medium' ? 24 : 32;

	return (
		<div className={className}>
			<svg
				width={imageSize}
				height={imageSize}
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.76499 24.13C9.09417 30.4041 17.6335 31.9394 23.8362 27.5695C30.039 23.1996 31.5661 14.5724 27.2369 8.29834C22.9077 2.02425 14.3684 0.488965 8.1657 4.85885C1.96295 9.22874 0.43582 17.8559 4.76499 24.13ZM3.9475 24.7059C8.58845 31.4319 17.7472 33.0824 24.4041 28.3925C31.0611 23.7027 32.6954 14.4483 28.0544 7.7224C23.4135 0.996466 14.2547 -0.654094 7.59777 4.03577C0.940826 8.72564 -0.693456 17.98 3.9475 24.7059ZM11.3265 21.1758C11.0336 20.8829 11.0336 20.408 11.3265 20.1151L14.8382 16.6034L10.8833 12.8228C10.5838 12.5365 10.5732 12.0618 10.8594 11.7624C11.1456 11.463 11.6203 11.4523 11.9198 11.7385L15.8991 15.5425L19.4902 11.9514C19.7831 11.6585 20.258 11.6585 20.5508 11.9514C20.8437 12.2443 20.8437 12.7192 20.5508 13.0121L16.9837 16.5792L20.5181 19.9579C20.8175 20.2442 20.8282 20.7189 20.542 21.0183C20.2558 21.3177 19.781 21.3284 19.4816 21.0422L15.9227 17.6402L12.3871 21.1758C12.0942 21.4687 11.6194 21.4687 11.3265 21.1758Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};

export default Close;
