export const BEGIN_USER_SESSION = 'BEGIN_USER_SESSION';
export const beginUserSession = (data) => {
	localStorage.setItem('email', data.email);
	localStorage.setItem('userId', data.id);
	return {
		type: 'BEGIN_USER_SESSION',
		payload: data,
	};
};

export const RESET_ERROR_USER_SESSION = 'RESET_ERROR_USER_SESSION';
export const resetErrorUserSession = () => {
	localStorage.removeItem('email');
	localStorage.removeItem('userId');
	return {
		type: 'END_USER_SESSION',
	};
};

export const END_USER_SESSION = 'END_USER_SESSION';
export const endUserSession = () => {
	localStorage.removeItem('email');
	localStorage.removeItem('userId');
	localStorage.removeItem('persist:nextjs');
	localStorage.removeItem('isSkipAgreement');
	sessionStorage.removeItem('showVerificationModalDate');
	sessionStorage.removeItem('showBonusModalDate');
	delete window._smartico_user_id;
	delete window._smartico_language;
	return {
		type: 'END_USER_SESSION',
	};
};

export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const changePassword = () => {
	return {
		type: 'SUCCESS_CHANGE_PASSWORD',
	};
};

export const RESET_USER_CHANGE_PASSWORD = 'RESET_USER_CHANGE_PASSWORD';

export const PUT_DOCUMENTS_KYC = 'PUT_DOCUMENTS_KYC';
export const putDocumentsKYC = (data) => {
	return {
		type: PUT_DOCUMENTS_KYC,
		payload: data,
	};
};

export const PUT_HISTORY_KYC = 'PUT_HISTORY_KYC';
export const putHistoryKYC = (data) => {
	return {
		type: PUT_HISTORY_KYC,
		payload: data,
	};
};

export const PUT_ERROR_SERVER_MESSAGE_OBJECT = 'PUT_ERROR_SERVER_MESSAGE_OBJECT';
export const putErrorServerMessageObject = (data) => {
	return {
		type: PUT_ERROR_SERVER_MESSAGE_OBJECT,
		payload: { ...data },
	};
};

export const PUT_ERROR_SERVER_MESSAGE = 'PUT_ERROR_SERVER_MESSAGE';
export const putErrorServerMessage = (data) => {
	return {
		type: PUT_ERROR_SERVER_MESSAGE,
		payload: data,
	};
};

export const RESET_ERROR_SERVER_MESSAGE = 'RESET_ERROR_SERVER_MESSAGE';
export const resetErrorServerMessage = () => {
	return {
		type: RESET_ERROR_SERVER_MESSAGE,
	};
};
