import React from 'react';
import clsx from 'clsx';
import styles from './sidemenu.module.scss';
import getMessage from '../../utils/locale';
import Title from '../../images/gamificationMenu/title.svg';
import { useDispatch } from 'react-redux';
import { useModal } from '../../redux/selectors';
import { handleClickOnGamification } from '../../utils/common';

const Gamification = () => {
	const dispatch = useDispatch();
	const { historyPopupType = [] } = useModal();

	return (
		<div className={clsx(styles.navLinksInfo, styles.gamification)}>
			<h2
				className={styles.navInfoTitle}
				onClick={() => handleClickOnGamification(dispatch, historyPopupType)}
			>
				<img src={Title} alt={Title} />
				{getMessage('es', 'NavigationGamificationTitle')}
			</h2>
		</div>
	);
};

export default Gamification;
