import React, { useEffect, useMemo, useState } from 'react';
import { PopupContainer } from '../../Container/Container';
import { envMode, productionDomains, stageDomains } from '../../../utils/consts';
import styles from './forgotPassword.module.scss';
import Button from '../../../UI/Button/Button';
import LockIcon from '../../Icons/LockIcon';
import { getEmailSchema } from '../../../utils/validation';
import getMessage from '../../../utils/locale';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSiteApi } from '../../../utils/session';
import axios from '../../../utils/api/config';
import { PopupType } from '../SmallModal/SmallModal';
import { actions } from '../../../redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import {
	putErrorServerMessage,
	resetErrorServerMessage,
} from '../../../redux/actions/userSession';
import { errorNotification } from '../../../utils/notifications/notifications';
import EditPhone from '../EditPhone/EditPhone';
import CodeVerification from '../CodeVerification/CodeVerification';
import QuestionIcon from '../../Icons/QuestionIcon';
import ResetPassword from '../ResetPassword/ResetPassword';
import clsx from 'clsx';
import InputLineWithLabel from '../../../UI/InputLineWithLabel/InputLineWithLabel';
import { useUserData } from '../../../redux/selectors';
import MailIcon from '../../../UI/icons/MailIcon';
import { isValidEmail } from '../../../utils/common';

export const ConfirmationCodeState = {
	Initial: 'Initial',
	EnterPhoneNumber: 'EnterPhoneNumber',
	ResetPasswordModal: 'ResetPasswordModal',
	MobileNotExist: 'MobileNotExist',
	MobileMaxAttemptsAmount: 'MobileMaxAttemptsAmount',
	EnterCode: 'EnterCode',
	Success: 'Success',
};

export const VerificationTab = {
	Email: 'Email',
	Phone: 'Phone',
};
export const SECONDS_IN_MINUTE = 60;

const ForgotPassword = ({ setModalAfterResetPassword }) => {
	const dispatch = useDispatch();
	const { errorServerMessage: errorServer } = useUserData();
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorServerMessage, setErrorServerMessage] = useState('');

	const [activeScreen, setActiveScreen] = useState(null);
	const [activeMethod, setActiveMethod] = useState(null);

	const [mobileCode, setMobileCode] = useState(null);
	const [secondsLeft, setTimeLeft] = useState(SECONDS_IN_MINUTE);
	const [sendCodeCount, setSendCodeCount] = useState(0);
	const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const failedLoginAttempts = sessionStorage.getItem('failedLoginAttempts');
		if (failedLoginAttempts === '2') {
			sessionStorage.removeItem('failedLoginAttempts');
		}
	}, []);

	const onClose = () => dispatch({ type: actions.ClosePopUp });

	const schema = useMemo(() => getEmailSchema(getMessage), []);

	const { register, handleSubmit, formState, watch } = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
		},
		shouldFocusError: true,
	});

	const formFields = watch();
	const { errors } = formState;

	useEffect(() => {
		errorServerMessage && setErrorServerMessage('');
	}, [activeMethod]);

	useEffect(() => {
		setErrorServerMessage(errorServer);
	}, [errorServer]);

	const onSubmit = async (data = {}, e = null, phoneNumber = '') => {
		e && e.preventDefault;
		const requestData = {};

		if (envMode === 'production') {
			requestData.origin = productionDomains[0];
		} else {
			requestData.origin = stageDomains[0];
		}

		if (activeMethod === VerificationTab.Phone) {
			requestData.phone = phoneNumber.trim();
		} else {
			if (isValidEmail(formFields.email)) {
				requestData.email = formFields.email.toLowerCase();
			} else {
				requestData.nickName = formFields.email.toLowerCase();
			}
		}

		if (!errors.email?.message) {
			try {
				setIsDisabled(true);
				setIsLoading(true);
				dispatch(resetErrorServerMessage());
				setErrorServerMessage('');
				const response = await axios.post(
					activeMethod === VerificationTab.Phone
						? `${getSiteApi()}player/crm/password/reset/phone`
						: `${getSiteApi()}player/crm/password/reset`,
					requestData
				);
				if (response.status >= 200 && response.status < 300) {
					if (activeMethod === VerificationTab.Phone) {
						setActiveScreen(ConfirmationCodeState.EnterCode);
						setSendCodeCount((prev) => prev + 1);
						setTimeLeft(SECONDS_IN_MINUTE * 2);
						setCurrentPhoneNumber(phoneNumber);
					} else {
						setModalAfterResetPassword({
							open: true,
							title: getMessage(
								'es',
								'navbar.user.titleCongratulation.thankYou'
							),
							description: getMessage(
								'es',
								'navbar.popup.descCongratulation.checkEmail'
							),
							icon: <MailIcon />,
							openLogin: false,
						});
						onClose();
					}
				}
			} catch (e) {
				if (e.response?.data?.message) {
					const errorMessage = getMessage(
						'es',
						`forgotPassword.${e.response?.data?.message}`
					);
					dispatch(putErrorServerMessage(errorMessage));
					if (activeMethod === VerificationTab.Phone) {
						switch (e.response?.data?.message) {
							case 'limit.password.reset':
								setActiveScreen(
									ConfirmationCodeState.MobileMaxAttemptsAmount
								);
								break;
							case 'phone.not.verified':
							default:
								setActiveScreen(ConfirmationCodeState.MobileNotExist);
						}
					}
				} else {
					errorNotification('', getMessage('es', e.response));
				}
			} finally {
				setIsDisabled(false);
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		if (sendCodeCount >= 2) {
			dispatch({
				type: actions.ClickedOnSupport,
				payload: true,
			});
		}
	}, [sendCodeCount]);

	useEffect(() => {
		let timer;
		if (secondsLeft > 0) {
			timer = setTimeout(() => {
				setTimeLeft(secondsLeft - 1);
			}, 1000);
		} else if (timer && secondsLeft <= 0) {
			clearTimeout(timer);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [secondsLeft]);

	const showBody = () => {
		document.body.style.overflowY = 'hidden';

		switch (activeScreen) {
			case ConfirmationCodeState.EnterCode:
				return (
					<CodeVerification
						activeTab={VerificationTab.Phone}
						setActiveScreen={setActiveScreen}
						setMobileCode={setMobileCode}
						secondsLeft={secondsLeft}
						verifyMethod={() => onSubmit()}
						currentPhoneNumber={currentPhoneNumber}
					/>
				);
		}
	};

	const resetPassMethod = () => {
		setActiveScreen(null);
		setActiveMethod(null);
	};

	return (
		<>
			{activeMethod === VerificationTab.Phone &&
			activeScreen === ConfirmationCodeState.EnterPhoneNumber ? (
				<EditPhone
					activeMethod={VerificationTab.Phone}
					onResetPassword={(phoneNumber) => onSubmit({}, null, phoneNumber)}
					isDisabled={isDisabled}
					isResetPassword
					resetPassMethod={resetPassMethod}
				/>
			) : activeScreen === ConfirmationCodeState.ResetPasswordModal ? (
				<ResetPassword
					onClose={onClose}
					mobileCode={mobileCode}
					setModalAfterResetPassword={setModalAfterResetPassword}
				/>
			) : (
				<PopupContainer
					classes={styles.forgotPassword}
					onClose={onClose}
					displayCloseButton
					typeModal={PopupType.ForgotPassword}
					displayBackButton={
						activeScreen === ConfirmationCodeState.MobileNotExist ||
						activeScreen === ConfirmationCodeState.MobileMaxAttemptsAmount ||
						activeScreen === ConfirmationCodeState.EnterCode
					}
					onBack={() => setActiveScreen(ConfirmationCodeState.EnterPhoneNumber)}
				>
					{activeMethod !== VerificationTab.Phone &&
						activeScreen !== ConfirmationCodeState.MobileNotExist &&
						activeScreen !==
							ConfirmationCodeState.MobileMaxAttemptsAmount && (
							<>
								<div className={styles.icon}>
									<LockIcon />
								</div>
								<h2 className={styles.title}>
									{getMessage(
										'es',
										`${
											!activeMethod ||
											activeMethod === VerificationTab.Email
												? 'loginFormForgotPassword'
												: 'successRegistrationModalCongratulations'
										}`
									)}
								</h2>
							</>
						)}
					{activeMethod === VerificationTab.Phone &&
						(activeScreen === ConfirmationCodeState.MobileNotExist ||
							activeScreen ===
								ConfirmationCodeState.MobileMaxAttemptsAmount) && (
							<>
								<div className={styles.icon}>
									<QuestionIcon />
								</div>
								<h2 className={styles.title}>
									{activeScreen === ConfirmationCodeState.MobileNotExist
										? getMessage(
												'es',
												'login.modal.forgotPassword.not.exist.phone.title'
										  )
										: getMessage(
												'es',
												'login.modal.forgotPassword.reset.limit.phone.title'
										  )}
								</h2>
							</>
						)}
					{!activeMethod ||
					activeScreen === ConfirmationCodeState.MobileNotExist ||
					activeScreen === ConfirmationCodeState.MobileMaxAttemptsAmount ? (
						<div className={clsx(styles.buttons, styles.reset)}>
							<Button
								variant="contained"
								color="primary"
								text={getMessage(
									'es',
									activeScreen ===
										ConfirmationCodeState.MobileNotExist ||
										activeScreen ===
											ConfirmationCodeState.MobileMaxAttemptsAmount
										? 'login.modal.forgotPassword.buttonByEmailAfterPhone'
										: 'login.modal.forgotPassword.buttonByEmail'
								)}
								type="button"
								onClick={() => {
									setActiveMethod(VerificationTab.Email);
									setActiveScreen(null);
								}}
							/>
							{activeScreen === ConfirmationCodeState.MobileNotExist && (
								<button
									className={styles.retry}
									onClick={() =>
										setActiveScreen(
											ConfirmationCodeState.EnterPhoneNumber
										)
									}
									type="button"
									data-testid="cancel-button"
								>
									{getMessage('es', 'common.try.again', {
										ns: 'common',
									})}
								</button>
							)}
							{!activeMethod && (
								<Button
									variant="contained"
									color="primary"
									text={getMessage(
										'es',
										'login.modal.forgotPassword.buttonBySMS'
									)}
									type="button"
									onClick={() => {
										setActiveMethod(VerificationTab.Phone);
										setActiveScreen(
											ConfirmationCodeState.EnterPhoneNumber
										);
									}}
								/>
							)}
						</div>
					) : activeMethod !== VerificationTab.Phone ? (
						<form
							className={styles.form}
							onSubmit={handleSubmit(onSubmit)}
							autoComplete="off"
						>
							<h2 className={styles.message}>
								{getMessage('es', 'forgotPasswordFormEnterYourEmail')}
							</h2>

							<InputLineWithLabel
								reg={register('email')}
								name="email"
								label={getMessage('es', 'fieldForgotPasswordEMail')}
								placeholder={getMessage(
									'es',
									'fieldForgotPasswordEMailPlaceholder'
								)}
								forgotPassword
								errorText={errors.email?.message || errorServerMessage}
								value={formFields.email}
							/>

							<div className={styles.buttons}>
								<Button
									type="submit"
									text={getMessage('es', 'password.submit')}
									disabled={isLoading || !(formFields.email.length > 1)}
									forgotPassword
								/>
							</div>
						</form>
					) : (
						showBody()
					)}
				</PopupContainer>
			)}
		</>
	);
};

export default ForgotPassword;
