import translationLoginInHeader from './translationLoginInHeader';
import translationGeneralScreen from './translationGeneralScreen';
import translationForms from './translationForms';
import translationFooter from './translationFooter';
import translationsRegInfo from './translationRegInfo';
import translationsNavigation from './translationNavigation';
import notification from './notification';

const translations = {
	en: {
		...translationLoginInHeader.en,
		...translationGeneralScreen.en,
		...translationForms.en,
		...translationFooter.en,
		...translationsRegInfo.en,
		...translationsNavigation.en,
		...notification.en,
		...notification.es,
		titleGamesSelection: 'Winpot Games Selection',
		buttonFacebookInHeader: 'Inizio',
		// buttonGames: 'Explore more',
		buttonGames: 'Play Now',
		registrationFacebook: 'Start',
		buttonLogin: 'Log in',
		buttonLogOut: 'log Out',
		buttonSignUP: 'Sign up',
		uploading: 'Uploading',
		buttonBack: 'back',
		buttonOK: 'OK',
		buttonNo: 'NO',
		buttonYes: 'Yes',
		support: 'Support',
		submitResetPassword: 'Reset',
		'resetPasswordByMethods.title': 'Reset password',
		'resetPassword.enter.placeholder': 'Strong password',
		'resetPassword.reEnter.placeholder': 'Re-enter password',
		'changePassword.repeat': 'Repeat password',
		'changePassword.reEnter': 'Re-enter password',
		'common.try.again': 'Please try again',
		'profileDropdown.alt.arrowButton': 'Arrow button',
		'login.form.button.login': 'Log in',
		'profileDropdown.navbar.balance.title': 'Balance',
		'profileDropdown.navbar.deposit.button': 'Deposit',
		FooterDescText:
			'At Casino, we strive to keep player satisfaction at the forefront of our vision, and continually provide it with our winning combination of online entertainment services and customer rewards. The Casino brand develops and provides superior classic casino offerings, social games, scratch cards, and live and mobile casinos. Our loyalty tournaments, promotions, and incentives seek to outshine the competition time and time again. Thanks to software from industry leader Wiztechgroup, a spectacular experience awaits all customers in every corner. Its intuitive design, graphics, captivating sounds, and above all its great accessibility give it a truly attractive experience. Register your free account and become a Casino winner today. We use certain browser cookies in an effort to improve your experience with us. By using this site, you acknowledge and acknowledge such practice.',
		'navbar.user.blocked.title': 'Forbidden',
		'navbar.user.blocked.Closed': 'Closed',
		'navbar.user.blocked.SelfClosed': 'Self closed',
		'navbar.user.blocked.Frozen': 'Frozen',
		'navbar.user.blocked.Lead': 'Lead',
		'navbar.popup.blocked.description':
			'Access to your account is currently restricted. Contact <a href="mailto:soporte@winpot.mx">soporte@winpot.mx</a> to find out the status of your account.',
		'notFound.title': 'A problem has occurred',
		'notFound.description': 'Please try again',
		'notFound.button': 'Back',
		'navbar.user.titleCongratulation.thankYou': 'Thank you',
		'navbar.popup.descCongratulation.checkEmail':
			'Please check your email to reset your password',
	},
	es: {
		...translationLoginInHeader.es,
		...translationGeneralScreen.es,
		...translationForms.es,
		...translationFooter.es,
		...translationsRegInfo.es,
		...translationsNavigation.es,
		titleGamesSelection: 'Selección de Juegos Winpot',
		buttonFacebookInHeader: 'Conectar',
		// buttonGames: 'Explorar más',
		buttonGames: 'Comenzar',
		registrationFacebook: 'Conectar',
		registrationGoogle: 'REGISTRAR CON Google',
		registrationEmail: 'REGISTRAR una\ncuenta nueva',
		navigationTitle: 'Navegación',
		buttonLogin: 'Login',
		buttonLogOut: 'Cerrar la session',
		buttonSignUP: 'Signup',
		buttonBack: 'volver',
		buttonOK: 'OK',
		buttonNo: 'NO',
		buttonYes: 'Yes',
		'login.form.button.login': 'Inicio de sesión',
		DescLine1: 'De regalo',
		DescLine2: 'sin depòsito',
		DescLine2Money: '$400',
		DescLine3: '¡Duplicamos',
		DescLine4FirstWord: 'tu ',
		DescLine4Attention: '1er ',
		DescLine4SecondWord: 'depòsito!',
		DescLine5: '- HASTA $3,000 - ',
		DescCardLine1: 'despues de hacer to 1er deposito',
		DescCardLine2: 'INGRESA',
		DescCardLine3: 'Código de promocion',
		DescCardLine4: 'en la seccion de bonos',
		DescCardAttention: 'Bpd3000',
		buttonGeneral: 'APUESTA AQUI',
		registrationFormHeaderLine1: 'ABRIR UNA CUENTA',
		registrationFormHeaderLine2: 'ES GRATIS Y SOLO TOMA 15 SEGUNDOS',
		registrationFormBodyLine1: 'CREA TU CUENTA CON UN CLIC',
		registrationFormBodyDesc1:
			'No se preocupe, no publicaremos nada en su nombre, es solo para facilitar el proceso de registro.',
		registrationFormBodyDesc2:
			'Registrarte o crear tu cuenta significa que estás de acuerdo con los términos y condiciones & la política de privacidad..',
		support: 'Soporte',
		MockupAppLine1: 'Apuesta',
		MockupAppLine2: 'EN VIVO',
		MockupAppLine3: 'desde tu celular o tablet',
		MockupAppLine4: 'EN TUS DEPORTES FAVORITOS',
		buttonMockupApp: 'Deposita AQUÍ',
		'profileDropdown.navbar.balance.title': 'Balance',
		'profileDropdown.navbar.deposit.button': 'Deposita',
		'profileDropdown.alt.arrowButton': 'Botón de flecha',
		FooterDescText:
			'En Casino, nos esforzamos por mantener la satisfacción del jugador en la vanguardia de nuestra visión, y la proporcionamos continuamente con nuestra combinación ganadora de servicios de entretenimiento en línea y recompensas para el cliente. La marca Casino desarrolla y proporciona ofertas superiores de casino clásico, juegos sociales, tarjetas de rascar y casinos en vivo y móviles. Nuestros torneos de fidelidad, promociones e incentivos buscan superar a la competencia una y otra vez. Gracias al software del líder del sector, Wiztechgroup, una experiencia espectacular espera a todos los clientes en cada rincón. Su diseño intuitivo, sus gráficos, sus sonidos cautivadores y, sobre todo, su gran accesibilidad le confieren una experiencia realmente atractiva. Registra tu cuenta gratuita y conviértete en un ganador del Casino hoy mismo. Utilizamos ciertas cookies del navegador en un esfuerzo por mejorar su experiencia con nosotros. Al utilizar este sitio, usted reconoce y acepta dicha práctica.',
		'navbar.user.blocked.title': 'Prohibido',
		'navbar.user.blocked.Closed': 'Cerrado',
		'navbar.user.blocked.SelfClosed': 'Auto cerrado',
		'navbar.user.blocked.Frozen': 'Congelado',
		'navbar.user.blocked.Lead': 'Prospecto',
		'navbar.popup.blocked.description':
			'El acceso a tu cuenta por el momento se encuentra restringido. Contacta a <a href="mailto:soporte@winpot.mx">soporte@winpot.mx</a> para saber el status de tu cuenta.',
		'notFound.title': 'Ha ocurrido un problema',
		'notFound.description': 'Por favor, vuelve a intentarlo',
		'notFound.button': 'Volver',
		'navbar.user.titleCongratulation.thankYou': '¡Gracias!',
		'navbar.popup.descCongratulation.checkEmail':
			'Por favor revisa tu correo electrónico para restablecer tu contraseña',
		submitResetPassword: 'Restablecer',
		'resetPasswordByMethods.title': 'Restablecer contraseña',
		'resetPassword.enter.placeholder': 'Contraseña segura',
		'resetPassword.reEnter.placeholder': 'Volver a introducir la contraseña',
		'changePassword.repeat': 'Repetir contraseña',
		'changePassword.reEnter': 'Volver a introducir la contraseña',
		'common.try.again': 'Inténtalo de nuevo',
	},
	ru: {
		...translationLoginInHeader.ru,
		buttonLogin: 'Войти',
		buttonSignUP: 'Зарегистрироваться',
	},
};

export default translations;
