import React from 'react';

const CheckIcon = ({ color = '#FBFBFB' }) => {
	return (
		<svg
			width="11"
			height="8"
			viewBox="0 0 11 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.248 0.231509C10.1753 0.158151 10.0887 0.099926 9.99334 0.0601915C9.89797 0.0204571 9.79567 0 9.69235 0C9.58903 0 9.48673 0.0204571 9.39136 0.0601915C9.29599 0.099926 9.20942 0.158151 9.13667 0.231509L3.80522 5.57012L1.3555 3.11258C1.27996 3.0396 1.19078 2.98222 1.09306 2.94371C0.995343 2.9052 0.890996 2.88632 0.785979 2.88813C0.680961 2.88995 0.57733 2.91243 0.481002 2.9543C0.384674 2.99617 0.297535 3.0566 0.224562 3.13214C0.151588 3.20768 0.0942083 3.29686 0.0556985 3.39458C0.0171886 3.4923 -0.00169723 3.59665 0.000119683 3.70166C0.00193659 3.80668 0.0244206 3.91031 0.0662876 4.00664C0.108155 4.10297 0.168585 4.19011 0.244128 4.26308L3.24953 7.26848C3.32229 7.34184 3.40885 7.40006 3.50422 7.4398C3.5996 7.47953 3.7019 7.49999 3.80522 7.49999C3.90854 7.49999 4.01083 7.47953 4.10621 7.4398C4.20158 7.40006 4.28814 7.34184 4.3609 7.26848L10.248 1.38201C10.3275 1.30872 10.3909 1.21977 10.4342 1.12077C10.4776 1.02176 10.5 0.914848 10.5 0.806761C10.5 0.698675 10.4776 0.59176 10.4342 0.492754C10.3909 0.393749 10.3275 0.304798 10.248 0.231509Z"
				fill={color}
			/>
		</svg>
	);
};

export default CheckIcon;
