import clsx from 'clsx';
import React from 'react';
import styles from './button.module.scss';

const Button = (props) => {
	const {
		text = 'text',
		disabled = false,
		classes = '',
		onClick = () => {},
		type = 'button',
		iconStart,
		forgotPassword,
		smallModal,
		dataTestId = '',
	} = props;

	const icon = iconStart ? <span className={styles.btnIcon}>{iconStart}</span> : null;

	return (
		<>
			<button
				type={type}
				className={
					forgotPassword
						? clsx(styles.btn, styles.forgotPasswordBtn)
						: smallModal
						? clsx(styles.btn, styles.smallModal)
						: clsx(styles.btn, styles[classes])
				}
				onClick={onClick}
				disabled={disabled}
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				{icon}
				{text}
			</button>
		</>
	);
};

export default Button;
