import React from 'react';
import styles from './sidemenu.module.scss';
import { topNav } from '../../utils/consts';
import SideMenuLocalItem from './SideMenuLocalItem';

const Navigation = ({ setShowSpinner }) => {
	const topNavSliced = topNav.slice(0, 5);

	return (
		<div className={styles.navLinks}>
			<div className={styles.navlinksWrapper}>
				{topNavSliced.map(({ id, label, link, Icon }) => {
					return (
						<SideMenuLocalItem
							key={id}
							link={link}
							Icon={Icon}
							label={label}
							setShowSpinner={setShowSpinner}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default Navigation;
