import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './ConfirmationPopup.module.scss';
import { PopupContainer } from '../../Container/Container';
import { actions } from '../../../redux/actions/commonActions';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import Button from '../../../UI/Button/Button';
import screenSizes from '../../../utils/consts';
import clsx from 'clsx';
import FrozenIcon from '../../../UI/icons/FrozenIcon';
import getMessage from '../../../utils/locale';

const ConfirmationPopup = (props) => {
	const {
		title,
		description,
		onClose,
		isFrozenAcc = false,
		isWhite = false,
		icon = <FrozenIcon />,
	} = props;
	const size = useWindowSize();
	const dispatch = useDispatch();
	const isMobile = size.width <= screenSizes.MOBILE_MIDDLE_VIEW;

	const openSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	return (
		<PopupContainer
			classes={clsx(styles.popup, {
				[styles.popup__isFrozen__account]: isFrozenAcc,
				[styles.isWhite]: isWhite,
			})}
			onClose={onClose}
			closeByClick={true}
			displayCloseButton={true}
			displayBackButton={false}
			isShowBackButtonText={true}
			imageSize="large"
			isSmallModal={true}
			open
			isConfirmation={isMobile}
		>
			<div className={styles.popup__icon}>{icon}</div>
			<h2 className={styles.popup__title}>{title}</h2>
			<p
				className={styles.popup__description}
				dangerouslySetInnerHTML={{ __html: description }}
			/>
			<div className={styles.popup__confirmation}>
				{isFrozenAcc && (
					<button
						type="button"
						className={styles.underlinedBtn}
						onClick={openSupport}
					>
						{getMessage('es', 'support')}
					</button>
				)}
				<Button
					text={
						isFrozenAcc
							? getMessage('es', 'buttonOK')
							: getMessage('es', 'login.form.logIn')
					}
					type="button"
					classes={'btnPrimaryBlack'}
					onClick={onClose}
				/>
			</div>
		</PopupContainer>
	);
};

export default ConfirmationPopup;
