import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button from '../../../UI/Button/Button';
import InputLineWithLabel from '../../../UI/InputLineWithLabel/InputLineWithLabel';
import SCREEN_SIZES from '../../../utils/consts';

import { PopupType } from '../SmallModal/SmallModal';
import {
	DEFAULT_COUNTRY_INDEX,
	phoneCodes,
	phoneCodesSort,
} from '../../../UI/MultiSelect/phoneCodes';
import {
	RESET_ERROR_USER_SESSION,
	resetErrorServerMessage,
} from '../../../redux/actions/userSession';
import { actions } from '../../../redux/actions/commonActions';
import { useUserData } from '../../../redux/selectors';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { getPhoneDataByCode, getSignUpPhoneScheme } from '../../../utils/validation';
import { PopupContainer } from '../../Container/Container';
import { onInvalid, onPaste } from '../../../utils/verificationPopup';
import { VerificationTab } from '../ForgotPassword/ForgotPassword';
import { isLoggedIn } from '../../../utils/session';
import getMessage from '../../../utils/locale';
import styles from './EditPhone.module.scss';
import LockIcon from '../../Icons/LockIcon';

const phoneCodeIndex = phoneCodesSort[DEFAULT_COUNTRY_INDEX].ind;

const EditPhone = ({
	activeMethod,
	onResetPassword,
	isDisabled,
	isResetPassword,
	resetPassMethod,
}) => {
	const userData = useUserData();
	const dispatch = useDispatch();
	const size = useWindowSize();

	const phoneData = useMemo(() => {
		return getPhoneDataByCode(phoneCodes[phoneCodeIndex].value);
	}, [phoneCodeIndex]);

	const [isChangeNumber, setIsChangeNumber] = useState(false);
	const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
	const [existPhoneNumber, setExistPhoneNumber] = useState({
		phone: '',
		errorText: '',
	});

	const { register, handleSubmit, formState, watch, setValue, setError } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		resolver: yupResolver(getSignUpPhoneScheme(phoneData)),
		defaultValues: {
			phoneNumber: isLoggedIn()
				? userData?.phone?.substring(phoneCodeIndex?.toString().length + 1)
				: '',
		},
	});

	const formFields = watch();
	const { errors, dirtyFields } = formState;
	const { phoneNumber } = dirtyFields;

	useEffect(() => {
		return () => {
			dispatch({
				type: RESET_ERROR_USER_SESSION,
			});
		};
	}, []);

	useEffect(() => {
		const isFillExistPhoneNumber =
			existPhoneNumber?.phone ===
			`${phoneCodes[phoneCodeIndex].text}${formFields.phoneNumber}`;
		if (
			isChangeNumber ||
			errors.phoneNumber?.message ||
			isFillExistPhoneNumber ||
			isDisabled
		) {
			setDisabledSubmitBtn(true);
			isFillExistPhoneNumber &&
				setError('phoneNumber', {
					type: 'value',
					message: existPhoneNumber?.errorText,
				});
		} else {
			setDisabledSubmitBtn(false);
		}
	}, [
		isChangeNumber,
		errors.phoneNumber?.message,
		existPhoneNumber?.phone,
		phoneCodeIndex,
		isDisabled,
	]);

	const onSubmit = () => {
		const phoneNumber = `${phoneCodes[phoneCodeIndex].text}${formFields.phoneNumber}`;
		if (activeMethod === VerificationTab.Phone) {
			onResetPassword(phoneNumber);
		} else {
			if (!errors.phoneNumber?.message) {
				const mappingData = { phone: phoneNumber || userData.phone };
				dispatch({
					type: actions.UpdatePhone,
					payload: {
						mappingData,
						setError,
						setExistPhoneNumber,
					},
				});
			}
		}
	};

	useEffect(() => {
		dispatch(resetErrorServerMessage());
		setError('phoneNumber', {
			type: 'value',
			message: null,
		});
		setIsChangeNumber(
			`${phoneCodes[phoneCodeIndex].text}${formFields.phoneNumber}` ===
				userData?.phone
		);
	}, [phoneCodeIndex, formFields.phoneNumber, userData?.phone]);

	const closeModal = () => dispatch({ type: actions.ClosePopUp });

	return (
		<PopupContainer
			onClose={closeModal}
			classes={styles.container}
			displayCloseButton={size.width > SCREEN_SIZES.mobileSmall}
			typeModal={activeMethod !== VerificationTab.Phone && PopupType.Verification}
			displayBackButton={isResetPassword}
			onBack={resetPassMethod}
		>
			<div className={styles.mainSection}>
				<div className={styles.icon}>
					<LockIcon />
				</div>
				<h2 className={styles.title}>
					{getMessage('es', 'loginFormForgotPassword')}
				</h2>
				{activeMethod === VerificationTab.Phone && (
					<h3 className={styles.description}>
						{activeMethod === VerificationTab.Phone &&
							getMessage('es', 'login.modal.forgotPassword.desc.phone')}
					</h3>
				)}
				<form
					className={styles.form}
					onSubmit={handleSubmit(onSubmit)}
					autoComplete={'off'}
					noValidate
				>
					<div className={clsx(styles.phoneLabel)}>
						{getMessage('es', 'verification.method.phone')}
						<span>
							{getMessage('es', 'verification.method.phone.subtitle')}
						</span>
					</div>
					<div
						className={clsx(styles.wrapperPhone, styles.wrapperPhoneEdit, {
							[styles.wrapperPhoneError]: errors.phoneNumber?.message,
						})}
					>
						<div className={styles.codeSelect}>
							<CircleFlag
								countryCode={phoneCodes[phoneCodeIndex].codeFlag}
								height={'20'}
							/>
							<span
								className={clsx(styles.codePhone, styles.codePhoneLine)}
							>
								{phoneCodes[phoneCodeIndex].text}
							</span>
						</div>
						<div className={styles.inputCodeSelect}>
							<InputLineWithLabel
								reg={register('phoneNumber')}
								type={'number'}
								inputMode={'numeric'}
								name="phoneNumber"
								placeholder={getMessage(
									'es',
									`verification.method.phone`
								)}
								errorText={
									errors.phoneNumber?.message ||
									userData?.errorServerMessage
								}
								value={formFields.phoneNumber}
								hasValue={phoneNumber}
								showPlaceholder={false}
								onInvalid={onInvalid}
								onPaste={(e) => onPaste(e, setValue)}
								dataTestId="edit-phone-input"
							/>
						</div>
					</div>

					{activeMethod === VerificationTab.Phone && (
						<h4 className={styles.hint}>
							{activeMethod === VerificationTab.Phone &&
								getMessage('es', 'login.modal.forgotPassword.hint.phone')}
						</h4>
					)}

					<div className={styles.buttons}>
						{activeMethod !== VerificationTab.Phone && (
							<button
								className={styles.button_cancel}
								// onClick={onBack}
								type="button"
								data-testid="cancel-button"
							>
								{getMessage('es', 'common.back')}
							</button>
						)}
						<Button
							variant="contained"
							type="submit"
							text={
								activeMethod === VerificationTab.Phone
									? getMessage('es', 'password.submit')
									: getMessage('es', 'verification.button.changePhone')
							}
							dataTestId="save-button"
							disabled={disabledSubmitBtn}
						/>
					</div>
					<p className={styles.needHelp}>
						{getMessage('es', 'verification.needHelp')}
						<span
							className={styles.resendLink}
							onClick={() =>
								dispatch({
									type: actions.ClickedOnSupport,
									payload: true,
								})
							}
							data-testid="support-link"
						>
							{getMessage('es', 'verification.button.support')}
						</span>
					</p>
				</form>
			</div>
		</PopupContainer>
	);
};

export default EditPhone;
