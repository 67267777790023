import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import styles from './DigitCodeInput.module.scss';
import { VERIFICATION_CODE_LENGTH } from '../../components/Modals/CodeVerification/CodeVerification';

const MAX_LENGTH_INPUT = 1;
const MAX_LENGTH_VALUES_INPUT_READY_FOR_REPLACE = 2;

const DigitCodeInput = (props) => {
	const { code, hasError, setCode } = props;
	const [numberCodeInputs, setNumberCodeInputs] = useState([]);
	const [onFocusInputIdx, setOnFocusInputIdx] = useState();
	const [historyCharStr, setHistoryCharStr] = useState([]);

	useEffect(() => {
		const numberCodeForm = document.getElementById('data-number-code-form');
		const numberCodeInputs = [
			...numberCodeForm.querySelectorAll('[data-number-code-input]'),
		];

		setNumberCodeInputs(numberCodeInputs);
	}, []);

	useEffect(() => {
		if (!code && numberCodeInputs.length) {
			numberCodeInputs.forEach((item) => {
				item.value = '';
			});
		}
	}, [code]);

	useEffect(() => {
		console.log("'OTPCredential' in window", 'OTPCredential' in window);
		if ('OTPCredential' in window) {
			window.addEventListener('DOMContentLoaded', (e) => {
				const input = document.querySelector(
					'input[autocomplete="one-time-code"]'
				);
				if (!input) return;
				// Cancel the WebOTP API if the form is submitted manually.
				const ac = new AbortController();
				const form = input.closest('form');
				console.log('form ', form);
				if (form) {
					form.addEventListener('submit', (e) => {
						// Cancel the WebOTP API.
						ac.abort();
					});
				}
				// Invoke the WebOTP API
				navigator.credentials
					.get({
						// @ts-ignore
						otp: { transport: ['sms'] },
						signal: ac.signal,
					})
					.then((otp) => {
						input.value = otp.code;
						// Automatically submit the form when an OTP is obtained.
						if (form) form.submit();
					})
					.catch((err) => {});
			});
		}
	}, []);

	const setCurrentCode = (codeInputs) => {
		const code = codeInputs ? codeInputs.map((item) => item.value) : '';
		setCode(code.join(''));
	};

	const handleInput = ({ target }) => {
		if (!/[0-9]$/.test(target.value)) {
			return (target.value = null);
		}

		if (!target.value.length) {
			setCurrentCode(numberCodeInputs);
			return (target.value = null);
		}

		const inputLength = target.value.length;
		let currentIndex = Number(target.dataset.numberCodeInput);

		if (inputLength > 1) {
			const inputValues = target.value.split('');

			inputValues.forEach((value, valueIndex) => {
				const nextValueIndex = currentIndex + valueIndex;

				if (nextValueIndex >= numberCodeInputs.length) {
					return;
				}
				if (
					numberCodeInputs[currentIndex].value &&
					inputLength <= MAX_LENGTH_VALUES_INPUT_READY_FOR_REPLACE
				) {
					numberCodeInputs[currentIndex].value = value;
				} else {
					numberCodeInputs[nextValueIndex].value = value;
				}
			});

			currentIndex += inputValues.length - 2;
		}

		const nextIndex = currentIndex + 1;

		if (nextIndex < numberCodeInputs.length) {
			numberCodeInputs[nextIndex]?.focus();
		}
		setCurrentCode(numberCodeInputs);
	};

	const handleKeyDown = (e) => {
		const { code, target } = e;
		const currentIndex = Number(target.dataset.numberCodeInput);
		const previousIndex = currentIndex - 1;
		const nextIndex = currentIndex + 1;

		const hasPreviousIndex = previousIndex >= 0;
		const hasNextIndex = nextIndex <= numberCodeInputs.length - 1;

		switch (code) {
			case 'ArrowLeft':
			case 'ArrowUp':
				if (hasPreviousIndex) {
					numberCodeInputs[previousIndex]?.focus();
				}
				e.preventDefault();
				break;

			case 'ArrowRight':
			case 'ArrowDown':
				if (hasNextIndex) {
					numberCodeInputs[nextIndex]?.focus();
				}
				e.preventDefault();
				break;
			case 'Backspace':
				if (!e.target.value.length && hasPreviousIndex) {
					numberCodeInputs[previousIndex].value = null;
					numberCodeInputs[previousIndex]?.focus();
				}
				break;
			default:
				break;
		}

		const charStr = e?.key.toString();
		if (charStr !== 'Backspace' && charStr !== 'Delete') {
			// Control Event for Windows And Meta Event for IOS (click on cmd)
			if (
				!(
					charStr === 'v' &&
					(historyCharStr[historyCharStr.length - 1] === 'Control' ||
						historyCharStr[historyCharStr.length - 1] === 'Meta')
				)
			) {
				if (!charStr.match(/^[0-9-]*$/)) {
					e.preventDefault();
				}
			}
		}
		setHistoryCharStr((prev) => [...prev, charStr]);
		numberCodeInputs[currentIndex].selectionEnd = MAX_LENGTH_INPUT;

		setCurrentCode(numberCodeInputs);
	};

	const codeInput = [];
	codeInput.length = VERIFICATION_CODE_LENGTH;
	codeInput.fill(VERIFICATION_CODE_LENGTH);

	return (
		<form>
			<fieldset
				name="number-code"
				id="data-number-code-form"
				className={styles.inputContainer}
			>
				{codeInput.map((item, idx) => (
					<>
						<span
							className={clsx(styles.inputWrapper, {
								[styles.inputWrapper_active]: onFocusInputIdx === idx,
							})}
						>
							<input
								className={clsx(styles.input, {
									[styles.error]: hasError,
								})}
								onFocus={() => setOnFocusInputIdx(idx)}
								onBlur={() => setOnFocusInputIdx(-1)}
								onChange={handleInput}
								onKeyDown={handleKeyDown}
								min="0"
								max="9"
								name={`number-code-${idx}`}
								data-number-code-input={`${idx}`}
								required
								type="text"
								inputMode="numeric"
								autoComplete="one-time-code"
								pattern="\d{6}"
								data-testid="otp-number-input"
							/>
						</span>
					</>
				))}
			</fieldset>
		</form>
	);
};

export default React.memo(DigitCodeInput, (prevProps, nextProps) => {
	return prevProps.code === nextProps.code && prevProps.hasError === nextProps.hasError;
});
