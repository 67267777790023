const DAYS = Array.from({ length: 31 }, (_, ind) => {
	return { value: `${ind + 1}`, text: `${ind + 1}` };
});

const NAMES_OF_MONTH = [
	'Enero',
	'Feb',
	'Mar',
	'Abr',
	'Mayo',
	'Jun',
	'Jul',
	'Agosto',
	'Sept',
	'Oct',
	'Nov',
	'Dic',
];

const MONTHS = Array.from({ length: 12 }, (_, ind) => {
	return { value: `${ind + 1}`, text: NAMES_OF_MONTH[ind] };
});

const YEARS = Array.from(
	{ length: new Date().getFullYear() - 18 - 1920 + 1 },
	(_, ind) => {
		return { value: `${ind + 1920}`, text: `${ind + 1920}` };
	}
);

export { DAYS, NAMES_OF_MONTH, MONTHS, YEARS };
