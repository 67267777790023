import Cookies from 'js-cookie';
import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SessionExpiredModal from '../../components/Modals/SessionExpired/SessionExpiredModal';
import axios from '../../utils/api/api';
import {
	destroySession,
	getSiteApi,
	initializeSession,
	isLoggedIn,
	isRememberUser,
	isSessionExpired,
} from '../../utils/session';

import { beginUserSession, endUserSession } from '../../redux/actions/userSession';
import { useUserData } from '../../redux/selectors';

let sessionCheckInterval;
let sessionRefreshHandler;

const SessionListener = () => {
	const dispatch = useDispatch();
	const userSession = useUserData();
	const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);

	const handleSessionStorageEvent = (event) => {
		if (event.key === 'userId') {
			if (event.newValue) {
				initializeSession();
				dispatch(
					beginUserSession({
						id: localStorage.getItem('userId'),
						email: localStorage.getItem('email'),
					})
				);
			} else {
				stopHandlePlayerActivity();
				setOpenSessionExpiredModal(false);

				destroySession().then(() => {
					dispatch(endUserSession());
					window.location.href = '/';
				});
			}
		}
	};

	useEffect(() => {
		window.addEventListener('storage', handleSessionStorageEvent);
		return () => {
			window.removeEventListener('storage', handleSessionStorageEvent);
		};
	}, []);

	const startHandlePlayerActivity = () => {
		document.addEventListener('mousemove', sessionRefreshHandler);
		document.addEventListener('keydown', sessionRefreshHandler);
	};

	const stopHandlePlayerActivity = () => {
		document.removeEventListener('mousemove', sessionRefreshHandler);
		document.removeEventListener('keydown', sessionRefreshHandler);
		if (sessionRefreshHandler) {
			sessionRefreshHandler.cancel();
			sessionRefreshHandler = undefined;
		}
	};

	if (!sessionRefreshHandler) {
		sessionRefreshHandler = throttle(() => {
			axios
				.post(
					`${getSiteApi()}player/crm/refreshToken`,
					{ remember: isRememberUser() },
					{
						headers: {
							Authorization: Cookies.get('RefreshToken'),
						},
						withCredentials: true,
					}
				)
				.then(({ data }) => initializeSession({ ...userSession, ...data }));
		}, 60 * 1000); // 1 minute
	}

	const checkSession = (isSessionExpired, isLoggedIn, isRememberUser) => {
		if (isSessionExpired && !openSessionExpiredModal) {
			setOpenSessionExpiredModal(true);
			stopHandlePlayerActivity();
		} else if (isLoggedIn && !isRememberUser) {
			if (openSessionExpiredModal) {
				setOpenSessionExpiredModal(false);
			}
			startHandlePlayerActivity();
		} else {
			stopHandlePlayerActivity();
		}
	};

	useEffect(() => {
		checkSession(isSessionExpired(), isLoggedIn(), isRememberUser());
	}, [isLoggedIn(), isSessionExpired(), isRememberUser()]);

	useEffect(() => {
		if (!sessionCheckInterval && isLoggedIn() && !isRememberUser()) {
			sessionCheckInterval = setInterval(
				() => checkSession(isSessionExpired(), isLoggedIn(), isRememberUser()),
				1000
			);
		}
	}, [isLoggedIn(), isSessionExpired(), isRememberUser()]);

	if (!openSessionExpiredModal) {
		return null;
	}

	return (
		<SessionExpiredModal
			onLoginCallback={() => {
				setOpenSessionExpiredModal(false);
				startHandlePlayerActivity();
			}}
			onLogoutCallback={() => {
				setOpenSessionExpiredModal(false);
				stopHandlePlayerActivity();
			}}
		/>
	);
};

export default SessionListener;
