import { paymentCards, socialData } from './data';

import React, { useState } from 'react';
import clsx from 'clsx';
import screenSizes from '../../../utils/consts';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/actions/commonActions';
import bannerLogo from '../../../images/footer/segoblogo.png';
import awardLogo from '../../../images/footer/customer_support_award.svg';
import WhatsappIcon from '../../../UI/icons/WhatsappIcon';
import getMessage from '../../../utils/locale';
import styles from './footer.module.scss';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import androidBtnImage from '../../../images/footer/AndroidWhite.png';
import appleBtnImage from '../../../images/footer/appStoreWhite.png';
import { WINPOT_CASINO_URL } from '../../../utils/consts';

const Footer = () => {
	const size = useWindowSize();
	const [textOpen, setTextOpen] = useState(false);

	const dispatch = useDispatch();

	const openSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	const linksCasino = [
		{
			title: getMessage('es', 'footerTitleCasinoHome'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}casino`;
			},
			attr: 'footer-home-casino-link',
		},
		{
			title: getMessage('es', 'footerTitleCasinoLive'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}live/lobby`;
			},
			attr: 'footer-live-casino-link',
		},
		{
			title: getMessage('es', 'footerTitleCasinoNew'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}casino?category=New`;
			},
			attr: 'footer-new-link',
		},
		{
			title: getMessage('es', 'footerTitleCasinoSlots'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}casino?category=Slots`;
			},
			attr: 'footer-slots-link',
		},
		{
			title: getMessage('es', 'footerTitleCasinoJackpots'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}casino?category=JACKPOTS`;
			},
			attr: 'footer-jackpots-link',
		},
		{
			title: getMessage('es', 'footerTitleCasinoTables'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}casino?category=Table`;
			},
			attr: 'footer-tables-link',
		},
		{
			title: getMessage('es', 'footerTitleCasinoBingo'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}casino?category=BINGO`;
			},
			attr: 'footer-bingo-link',
		},
	];

	const linksSports = [
		{
			title: getMessage('es', 'footerTitleSportHome'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}sport#/overview`;
			},
			attr: 'footer-sports-casino-link',
		},
		{
			title: getMessage('es', 'footerTitleSportLive'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}live-sport#/live`;
			},
			attr: 'footer-live-sports-link',
		},
		{
			title: getMessage('es', 'footerTitleSportBettingRules'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/betting-rules`;
			},
			attr: 'footer-betting-rules-link',
		},
		{
			title: getMessage('es', 'footerTitleSportBettingRulesVIP'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/betting-rules-vip`;
			},
			attr: 'footer-pool-rules-link',
		},
	];

	const linksPromo = [
		{
			title: getMessage('es', 'footerTitleSportWinpotClub'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}winclub`;
			},
			attr: 'footer-winpot-club-link',
		},
		{
			title: getMessage('es', 'footerTitleSportCasinoPromo'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}promotions?slug=promotions&tab=Casino`;
			},
			attr: 'footer-casino-promos-link',
		},
		{
			title: getMessage('es', 'footerTitleSportSportPromo'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}promotions?slug=promotions&tab=Sport`;
			},
			attr: 'footer-sports-promos-link',
		},
		{
			title: getMessage('es', 'footerTitleAffiliates'),
			onClick: () => {
				window.location.href = 'https://www.affiliatesworld.com/es/';
			},
			attr: 'footer-affiliates-link',
		},
	];

	const linksSupport = [
		{
			title: getMessage('es', 'footerTitleTermsAndConditions'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/terms-and-conditions`;
			},
			attr: 'footer-terms-and-conditions-link',
		},
		{
			title: getMessage('es', 'footerTitlePrivacyPolicy'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/privacy-policy`;
			},
			attr: 'footer-privacy-policy-link',
		},
		{
			title: getMessage('es', 'footerTitleGeneralBonusPolicy'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/general-bonus-policy`;
			},
			attr: 'footer-general-bonus-policy-link',
		},
		{
			title: getMessage('es', 'footerTitleTermsAndConditionsVIP'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/terms-and-conditions-vip`;
			},
			attr: 'footer-terms-and-conditions-link-VIP',
		},
		{
			title: getMessage('es', 'footerTitleFAQ'),
			onClick: () => {
				window.location.href = 'https://faq.winpot.mx/';
			},
			attr: 'footer-faq-link',
		},
	];

	const linksInformation = [
		{
			title: getMessage('es', 'footerTitleBlog'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}blog`;
			},
			attr: 'footer-blog-link',
		},
		{
			title: getMessage('es', 'footerTitleResponsibleGaming'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/responsible-gaming`;
			},
			attr: 'footer-responsible-gaming-link',
		},
		{
			title: getMessage('es', 'footerTitleOpenAnAccount'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/open-an-account`;
			},
			attr: 'footer-open-account-link',
		},
		{
			title: getMessage('es', 'footerTitleDepositsToYourAccount'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/deposits-to-your-account`;
			},
			attr: 'footer-deposits-your-account-link',
		},
		{
			title: getMessage('es', 'footerTitleWithdrawYourProfits'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/withdraw-your-profits`;
			},
			attr: 'footer-withdraw-your-profits-link',
		},
		{
			title: getMessage('es', 'footerTitleBonusInformation'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/bonus-information`;
			},
			attr: 'footer-bonus-information-link',
		},
		{
			title: getMessage('es', 'footerTitlePaymentOptions'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/payment-options`;
			},
			attr: 'footer-payment-options-link',
		},
		{
			title: getMessage('es', 'footerTitleVerificacion'),
			onClick: () => {
				window.location.href = `${WINPOT_CASINO_URL}info/verifications`;
			},
			attr: 'footer-check-link',
		},
	];

	const sectionTitles = [
		{
			title: getMessage('es', 'footerTitleNavCasino'),
			URLs: linksCasino,
		},
		{
			title: getMessage('es', 'footerTitleNavSport'),
			URLs: linksSports,
		},
		{
			title: getMessage('es', 'footerTitleNavPromo'),
			URLs: linksPromo,
		},
		{
			title: getMessage('es', 'footerTitleNavSupport'),
			URLs: linksSupport,
		},
		{
			title: getMessage('es', 'footerTitleNavInformation'),
			URLs: linksInformation,
		},
	];

	const contactsNav = [
		{
			urlTitle: getMessage('es', 'footerTitleLiveChat'),
			lowercased: false,
			onClick: openSupport,
		},
		{
			icon: <WhatsappIcon />,
			urlTitle: getMessage('es', 'footerTitleWhatsapp'),
			urlSubtitle: (
				<span
					dangerouslySetInnerHTML={{
						__html: getMessage('es', 'footerTitleWhatsappSubtitle'),
					}}
				/>
			),
			lowercased: false,
			onClick: () => {
				window.location.href =
					'https://api.whatsapp.com/send/?phone=525532757944&text&type=phone_number&app_absent=0';
			},
		},
		{
			urlTitle: getMessage('es', 'footerTitleSupport'),
			lowercased: true,
			onClick: () => {
				window.location.href = 'mailto:soporte@winpot.mx';
			},
		},
	];

	const setAppLink = () =>
		'https://winpot-cdn.mx/prod/mobile/android/380/winpot_v380.apk';

	const handleTextClick = () => {
		if (size.width <= screenSizes.tablet) {
			setTextOpen(!textOpen);
		}
	};

	return (
		<>
			<div className={styles.footer}>
				<div className={styles.footer__center}>
					<div className={styles.footer__nav__col__wrapper}>
						{sectionTitles.map((item, idx) => {
							return (
								<div className={styles.footer__col} key={idx}>
									<h3 className={styles.footer__title}>{item.title}</h3>
									<ul className={clsx(styles.footer__navbar__list)}>
										{item.URLs.map((itemUrl, idx) => {
											return (
												<li
													className={
														styles.footer__navbar__item
													}
													key={idx}
												>
													<a
														onClick={itemUrl.onClick}
														className={
															styles.footer__navbar__link
														}
														data-testid={itemUrl.attr}
													>
														{itemUrl.title}
													</a>
												</li>
											);
										})}
									</ul>
								</div>
							);
						})}
						<div className={styles.footer__col}>
							<h3 className={styles.footer__title}>
								{getMessage('es', 'footerTitleContacts')}
							</h3>
							<ul
								className={clsx(
									styles.footer__navbar__list,
									styles.footer__navbar__list__contacts
								)}
							>
								{contactsNav.map((item, idx) => {
									return (
										<li
											className={styles.footer__navbar__item}
											key={idx}
										>
											<span
												className={clsx(
													styles.footer__navbar__link,
													styles.footer__navbar__link__contacts
												)}
												onClick={item.onClick}
											>
												<span
													className={
														styles.footer__navbar__icon
													}
												>
													{!!item.icon && item.icon}
												</span>
												<span
													className={clsx({
														[styles.lowercased]:
															item.lowercased,
													})}
												>
													{item.urlTitle}
												</span>
												<span
													className={
														styles.footer__subtitle__contacts
													}
												>
													{!!item.urlSubtitle &&
														item.urlSubtitle}
												</span>
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
					<div className={styles.footer__col__wrapper}>
						<div className={styles.footer__col}>
							<div className={styles.footer__links__item}>
								<h3 className={styles.footer__title}>
									{getMessage('es', 'footerTitleLicense')}
								</h3>
								<div className={styles.footer__logos}>
									<img
										src={bannerLogo}
										alt={'Segob'}
										className={styles.footer__logos__regulator}
									/>
									<div className={styles.footer__logos__age}>18+</div>
								</div>
							</div>
							<div className={styles.award__logo__wrapper}>
								<img
									src={awardLogo}
									alt={awardLogo.alt}
									className={styles.award__logo}
								/>
							</div>
						</div>
						<div className={styles.footer__col}>
							<div className={styles.footer__links__item}>
								<h3 className={styles.footer__title}>
									{getMessage('es', 'footerTitleDownload')}
								</h3>
								<div className={styles.footer__apps}>
									<a
										className={styles.footer__apps__link}
										href={setAppLink()}
										rel="noreferrer"
										data-testid="footer-android-link"
									>
										<img
											src={androidBtnImage}
											alt={getMessage('es', 'FooterGoogleBtnText')}
										/>
									</a>

									<a
										className={styles.footer__apps__link}
										href="https://apps.apple.com/mx/app/winpot-casino/id1632635281"
										rel="noreferrer"
										data-testid="footer-ios-link"
										target="_blank"
									>
										<img
											src={appleBtnImage}
											alt={getMessage('es', 'FooterGoogleBtnText')}
										/>
									</a>
								</div>
							</div>
						</div>
						<div
							className={clsx(
								styles.footer__col,
								styles.award__logo__wrapper__mobile
							)}
						>
							<img
								src={awardLogo}
								alt={awardLogo.alt}
								className={styles.award__logo}
							/>
						</div>
						<div className={styles.footer__col}>
							<div className={styles.footer__links__item}>
								<h3 className={styles.footer__title}>
									{getMessage('es', 'footerTitleSocial')}
								</h3>
								<div className={styles.socialMedia__icons}>
									{socialData(size?.width).map(
										({ icon, alt, url }, idx) => {
											return (
												<a
													className={styles.socialMedia__icon}
													key={idx}
													href={url}
													target={'_blank'}
													rel="noreferrer"
													data-testid={`footer-${alt.toLowerCase()}-link`}
												>
													<img src={icon} alt={alt} />
												</a>
											);
										}
									)}
								</div>
							</div>
						</div>
					</div>

					<div className={styles.footer__col__payments}>
						<div className={styles.footer__payments__wrapper}>
							<h3 className={styles.footer__title}>
								{getMessage('es', 'footerTitlePayments')}
							</h3>
							<div
								className={styles.footer__payments}
								data-testid="footer-payment-options"
							>
								{paymentCards.map((paymentCard) => {
									return (
										<a
											key={paymentCard.icon}
											className={styles.footer__payments__card}
											href={paymentCard.url}
											rel="noreferrer"
											target="_blank"
										>
											<img
												src={paymentCard.icon}
												alt={paymentCard.alt}
											/>
										</a>
									);
								})}
							</div>
						</div>
					</div>
					<div className={styles.award__logo__wrapper__tablet}>
						<img
							src={awardLogo}
							alt={awardLogo.alt}
							className={styles.award__logo}
						/>
					</div>
				</div>
				<div className={styles.footer__text}>
					<h3
						className={clsx(
							styles.footer__title,
							{
								[styles.footer__title__arrow]:
									size.width <= screenSizes.mobileMax,
							},
							{ [styles.footer__title__active]: textOpen }
						)}
						onClick={handleTextClick}
					>
						{getMessage('es', 'footerTitleContent')}
					</h3>
					<div
						className={clsx(styles.footer__content, {
							[styles.footer__isOpen]: textOpen,
						})}
					>
						<p>{getMessage('es', 'FooterWarningTextTop')}</p>
						<p>{getMessage('es', 'FooterWarningTextMiddle')}</p>
						<p>{getMessage('es', 'FooterWarningTextBottom')}</p>
					</div>
				</div>
			</div>
		</>
	);
};
export default Footer;
