const translationsFooter = {
	en: {
		FooterDescSocial: 'Find us at',
		FooterMainText:
			'At Casino, we strive to keep player satisfaction at the' +
			'forefront of our vision, and continually provide it with our winning' +
			'combination of online entertainment services and customer rewards.' +
			'The Casino brand develops and provides superior classic casino' +
			'offerings, social games, scratch cards, and live and mobile casinos.' +
			'Our loyalty tournaments, promotions, and incentives seek to outshine' +
			'the competition time and time again. Thanks to software from industry' +
			'leader Wiztechgroup, a spectacular experience awaits all customers in' +
			'every corner. Its intuitive design, graphics, captivating sounds, and' +
			'above all its great accessibility give it a truly attractive experience.' +
			'Register your free account and become a Casino winner today. We use certain' +
			'browser cookies in an effort to improve your experience with us. By using' +
			'this site, you acknowledge and acknowledge such practice.',
		FooterWarningText:
			'Attention! Gambling can be addictive and is not suitable' +
			'for solving financial problems.Please read terms of conditions and bet' +
			'responsibly. "CASINO" is a registered trademark.Casino is offering' +
			'independent sports book services and is not affiliated with sports' +
			'teams, event organisers or players displayed in its websites.',
		FooterNavbarAboutUs: 'About Us',
		FooterNavbarPrivacyPolicy: 'Privacy Policy',
		FooterNavbarTermsAndConditions: 'Terms and Conditions',
		// FooterNavbar3: 'Fair Game',
		FooterNavbarResponsibleGaming: 'Responsible Gaming',
		FooterNavbarBettingRules: 'Betting Rules',
		FooterNavbarGeneralBonusPolicy: 'General Bonus Policy',
		FooterNavbarFAQ: 'FAQ',
		FooterNavbarBlog: 'Blog',
		FooterNavbarAffiliates: 'Affiliates',
		FooterNavbarNewAccount: 'Open an account',
		FooterNavbarDepositToYourAccount: 'Deposito En Tu Cuenta',
		FooterNavbarWithdrawYourProfits: 'Withdraw Your Profits',
		FooterNavbarBonusInformation: 'Bonus Information',
		FooterNavbarPaymentOptions: 'Payment Options',
		FooterNavbarVerifications: 'Verifications',
		FooterGoogleBtnText: 'Google play button',
		footerTitleNavCasino: 'Casino',
		footerTitleNavSport: 'Sport',
		footerTitleNavPromo: 'Promos',
		footerTitleNavSupport: 'Suport / Legal',
		footerTitleNavInformation: 'Information',
		footerTitlePayments: 'Payment options',
		footerTitleContent: 'About us',
		footerTitleDownload: 'Download in',
		footerTitleLicense: 'Licensed by',
		footerTitleSocial: 'Find us in',
		footerCustomerSupportAwardImageAltText: 'Voted Best Customer Support Service',
		footerTitleContacts: 'Contact us',
		footerTitleLiveChat: 'Live Chat',
		footerTitleWhatsapp: '+52 5532757944',
		footerTitleWhatsappSubtitle: '(9am - 10pm)',
		footerTitleSupport: 'soporte@winpot.mx',
		footerTitleCasinoHome: 'Casino Home',
		footerTitleCasinoLive: 'Casino Live',
		footerTitleCasinoNew: 'New',
		footerTitleCasinoSlots: 'Slots',
		footerTitleCasinoTables: 'Tables',
		footerTitleCasinoJackpots: 'Jackpots',
		footerTitleCasinoBingo: 'Bingo',
		footerTitleCasinoDropAndWins: 'Drop & Wins',
		footerTitleCasinoKashDrops: 'Kash Drops',
		footerTitleSportHome: 'Sport Home',
		footerTitleSportLive: 'Sport Live',
		footerTitleSportBettingRules: 'Betting Rules',
		footerTitleSportBettingRulesVIP: 'Betting Rules VIP',
		footerTitleSportWinpotClub: 'Winpot VIP',
		footerTitleSportCasinoPromo: 'Casino Promotions',
		footerTitleSportSportPromo: 'Sport Promotions',
		footerTitleAffiliates: 'Affiliates',
		footerTitleTermsAndConditions: 'Terms and Conditions',
		footerTitleTermsAndConditionsVIP: 'Terms and Conditions VIP',
		footerTitlePrivacyPolicy: 'Privacy Policy',
		footerTitleGeneralBonusPolicy: 'General Bonus Policy',
		footerTitleFAQ: 'FAQ',
		footerTitleChatLive: 'Chat Live',
		footerTitleBlog: 'Blog',
		footerTitleResponsibleGaming: 'Responsible Gaming',
		footerTitleOpenAnAccount: 'Open An Account',
		footerTitleDepositsToYourAccount: 'Deposits To Your Account',
		footerTitleWithdrawYourProfits: 'Withdraw Your Profits',
		footerTitleBonusInformation: 'Bonus Information',
		footerTitlePaymentOptions: 'Payment Options',
		footerTitleVerificacion: 'Verificacion',
	},
	es: {
		FooterDescSocial: 'Encuéntranos en',
		FooterMainText:
			'Winpot es una empresa mexicana líder en la industria del entretenimiento. ' +
			'Nuestro primer casino abrió en el año 2006 y en el día de hoy contamos con 17 casinos a lo largo de la República Mexicana, los cuales destacan por contar con la más moderna tecnología de máquinas electrónicas, Sports Book, mesas de juego en vivo, servicios de alimentos, bebidas y mucho más.',
		FooterWarningTextTop:
			'En Winpot.mx, nos esforzamos por mantener la satisfacción del jugador en la vanguardia de nuestra visión, y la proporcionamos continuamente con nuestra combinación ganadora de servicios de entretenimiento en línea y recompensas para el cliente. La marca Winpot desarrolla y proporciona ofertas superiores de casino, juegos sociales, tarjetas de rascar y casinos en vivo. Nuestros torneos de fidelidad, promociones e incentivos buscan superar a la competencia una y otra vez. Gracias al software del líder del sector, Wiztechgroup, una experiencia espectacular espera a todos los clientes en cada rincón. Su diseño intuitivo, sus gráficos, sus sonidos cautivadores y, sobre todo, su gran accesibilidad le confieren una experiencia realmente atractiva. Registra tu cuenta gratuita y conviértete en un ganador del Casino hoy mismo! Utilizamos ciertas cookies del navegador en un esfuerzo por mejorar su experiencia con nosotros. Al utilizar este sitio, Usted reconoce y acepta dicha práctica.',
		FooterWarningTextMiddle:
			'Winpot © 2006-2024. Todos los derechos reservados. El dominio winpot.mx es explotado por la empresa “Winpot Online América S.A. de C.V.”, operadora de “Pur Umazal Tov, S.A. de C.V.” bajo el amparo del permiso DGJS/DGAFJ/DCRCA/P-08/2014, con autorización de la Dirección General de Juegos y Sorteos DGJS/393/2018, DGJS/1130/2022, DGJS/1681/2023 y DGJS/1807/2023.',
		FooterWarningTextBottom:
			'El acceso a sitios web de juegos online con dinero está prohibido en ciertos países. El jugador es responsable de asegurarse de que no infringe ninguna ley al acceder al sitio web de Winpot. Juega de manera responsable como entretenimiento, diversión y esparcimiento. Winpot ofrece servicios de apuestas deportivas independientes y no está afiliado a los equipos deportivos, organizadores de eventos o jugadores que aparecen en su sitio web. El acceso al sitio web esta prohibido para los menores de 18 años. ¡Atención! El juego puede ser adictivo y no es adecuado para resolver problemas financieros. Lea los términos y condiciones y apueste de manera responsable. Winpot es una marca registrada. Prohibido para menores.',
		FooterNavbarAboutUs: 'Sobre Nosotros',
		FooterNavbarPrivacyPolicy: 'Política De Privacidad',
		FooterNavbarTermsAndConditions: 'Términos Y Condiciones',
		FooterNavbar3: 'Juego justo',
		FooterNavbarResponsibleGaming: 'Juego Responsable',
		FooterNavbarBettingRules: 'Reglas De Apuestas',
		FooterNavbarGeneralBonusPolicy: 'Politica General De Bonos',
		FooterNavbarFAQ: 'FAQ',
		FooterNavbarBlog: 'Blog',
		FooterGoogleBtnText: 'Botón Google Play',
		FooterAppStoreBtn: 'Botón Apple store',
		FooterNavbarAffiliates: 'Affiliates',
		FooterNavbarNewAccount: 'Abrir Una Cuenta',
		FooterNavbarDepositToYourAccount: 'Deposito En Tu Cuenta',
		FooterNavbarWithdrawYourProfits: 'Retirar Tus Canancias',
		FooterNavbarBonusInformation: 'Informacion Sobre Bonos',
		FooterNavbarPaymentOptions: 'Opciones De Pago',
		FooterNavbarVerifications: 'Verificacion',
		footerTitleNavCasino: 'Casino',
		footerTitleNavSport: 'Deportes',
		footerTitleNavPromo: 'Promos',
		footerTitleNavSupport: 'Soporte / Legal',
		footerTitleNavInformation: 'Información',
		footerTitleNav: 'Información',
		footerTitlePayments: 'Opciones de pago',
		footerTitleContent: 'Sobre nosotros',
		footerTitleDownload: 'Descargar en',
		footerTitleLicense: 'Licenciado por',
		footerTitleSocial: 'Encuéntranos en',
		footerCustomerSupportAwardImageAltText: 'Elegido Méjor servicio al cliente',
		footerTitleContacts: 'Contacto',
		footerTitleLiveChat: 'Chat en vivo',
		footerTitleWhatsapp: '+52 5532757944',
		footerTitleWhatsappSubtitle: '(9am - 10pm)',
		footerTitleSupport: 'soporte@winpot.mx',
		footerTitleCasinoHome: 'Casino Hogar',
		footerTitleCasinoLive: 'Casino En Vivo',
		footerTitleCasinoNew: 'Nuevo',
		footerTitleCasinoSlots: 'Slots',
		footerTitleCasinoTables: 'Mesas',
		footerTitleCasinoJackpots: 'Jackpots',
		footerTitleCasinoBingo: 'Bingo',
		footerTitleCasinoDropAndWins: 'Drop & Wins',
		footerTitleCasinoKashDrops: 'Kash Drops',
		footerTitleSportHome: 'Deportes Hogar',
		footerTitleSportLive: 'Deportes En Vivo',
		footerTitleSportBettingRules: 'Reglas De Apuestas',
		footerTitleSportBettingRulesVIP: 'Reglas De Quiniela',
		footerTitleSportWinpotClub: 'Winpot VIP',
		footerTitleSportCasinoPromo: 'Casino Promos',
		footerTitleSportSportPromo: 'Deportes Promos',
		footerTitleAffiliates: 'Afiliados',
		footerTitleTermsAndConditions: 'Terminos y condiciones',
		footerTitleTermsAndConditionsVIP: 'Términos y condiciones de VIP',
		footerTitlePrivacyPolicy: 'Politica de privacidad',
		footerTitleGeneralBonusPolicy: 'Politica General de Bono',
		footerTitleFAQ: 'FAQ',
		footerTitleChatLive: 'Chat En Vivo',
		footerTitleBlog: 'Blog',
		footerTitleResponsibleGaming: 'Juego Responsable',
		footerTitleOpenAnAccount: 'Abrir Una Cuenta',
		footerTitleDepositsToYourAccount: 'Deposito En Tu Cuenta',
		footerTitleWithdrawYourProfits: 'Retirar Tus Canacias',
		footerTitleBonusInformation: 'Informacion sobre bonos',
		footerTitlePaymentOptions: 'Opciones de Pago',
		footerTitleVerificacion: 'Verificación',
	},
};

export default translationsFooter;
