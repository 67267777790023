import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import styles from './Select.module.scss';
import getMessage from '../../utils/locale';

const Select = (props) => {
	const {
		selectItems,
		topLabel,
		value,
		disabled = false,
		className,
		classNameLabel,
		handleClick,
		classNameWrapper,
		simpleLabel,
		isDocumentUpload = false,
		localesForTranslate,
		isRegistrationSelect = false,
		error,
		dataTestId = '',
	} = props;

	const refList = useRef(null);
	const refElementYear2004 = useRef(null);
	const refElementSelectedYear = useRef(null);
	const [isListOpen, setIsListOpen] = useState(false);

	useEffect(() => {
		function handleClickOutside(event) {
			if (refList.current && !refList.current.contains(event.target)) {
				setIsListOpen(false);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refList.current]);

	useEffect(() => {
		if (selectItems[0].value === '1920') {
			if (!value && refElementYear2004.current) {
				refElementYear2004.current.scrollIntoView();
			}
			if (refElementSelectedYear.current) {
				refElementYear2004.current = null;
				refElementSelectedYear.current.scrollIntoView();
			}
		}
	}, [isListOpen, refElementYear2004.current, refElementSelectedYear.current]);

	const onClickShowList = (e) => {
		e.stopPropagation();
		setIsListOpen((prevState) => !prevState);
	};

	return (
		<div
			className={clsx(styles.wrapper, classNameWrapper)}
			onClick={(e) => onClickShowList(e)}
			data-testid={dataTestId}
		>
			{topLabel && (
				<div
					className={clsx(styles.topLabel, classNameLabel, {
						[styles.isRegistrationSelect]: isRegistrationSelect,
					})}
					onClick={(e) => onClickShowList(e)}
				>
					{value || topLabel}
				</div>
			)}
			{simpleLabel && <label className={styles.simpleLabel}>{simpleLabel}:</label>}
			<div ref={refList}>
				{isListOpen && !disabled && (
					<div
						className={clsx(styles.select, className, {
							[styles.upload__document__list]: isDocumentUpload,
						})}
					>
						<ul className={styles.ul} data-testid={`${dataTestId}-popup`}>
							{selectItems.map((selectItem) => {
								return (
									<li
										key={selectItem.value || selectItem.text}
										className={clsx(styles.option, {
											[styles.selected]: selectItem.text === value,
											[styles.isRegistrationSelect]:
												isRegistrationSelect,
										})}
										onClick={(e) => {
											e.stopPropagation();
											handleClick(
												selectItem.value || selectItem.text
											);
											setIsListOpen(false);
										}}
										ref={
											selectItem.text === '2004' && !value
												? refElementYear2004
												: selectItem.text === value
												? refElementSelectedYear
												: null
										}
									>
										{localesForTranslate
											? getMessage(
													localesForTranslate,
													selectItem.text
											  )
											: selectItem.text}
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
			{error && <div className={styles.errorText}>{error}</div>}
		</div>
	);
};

export default Select;
