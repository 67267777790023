import { all } from 'redux-saga/effects';
import userSaga from './user';
import gamesSaga from './games';
import { simpleErrorNotification } from '../utils/notifications/notifications';

// eslint-disable-next-line require-jsdoc
function* rootSaga() {
	try {
		yield all([userSaga(), gamesSaga()]);
	} catch (e) {
		simpleErrorNotification(e);
	}
}

export default rootSaga;
