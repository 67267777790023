import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../UI/Button/Button';
import { PopupContainer } from '../..//Container/Container';
import ExclamationIcon from '../../../UI/icons/ExclamationIcon';

import { actions } from '../../../redux/actions/commonActions';
import getMessage from '../../../utils/locale';
import styles from './ShowSeonRestrict.module.scss';

const ShowSeonRestrict = () => {
	const dispatch = useDispatch();

	const openSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	const onClose = () => dispatch({ type: actions.ClosePopUp });

	return (
		<PopupContainer classes={styles.container} onClose={onClose} displayCloseButton>
			<div className={styles.icon}>
				<ExclamationIcon />
			</div>
			<h2 className={styles.title}>
				{getMessage('es', 'signUp.error.seon.restrict.title')}
			</h2>
			<p className={styles.leading}>
				{getMessage('es', 'signUp.error.seon.restrict.leading')}
			</p>
			<Button
				type="button"
				text={getMessage('es', 'support')}
				classes={'btnPrimaryBlack'}
				onClick={openSupport}
			/>
		</PopupContainer>
	);
};

export default ShowSeonRestrict;
