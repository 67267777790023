import React from 'react';

const ExclamationIcon = () => (
	<svg
		width="46"
		height="44"
		viewBox="0 0 46 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_40266_301563)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.89732 32.3432H3.89561L0 41.9495L9.8066 39.362C13.5384 42.269 18.2339 44.0003 23.3382 44.0003C35.5075 44.0003 45.3571 34.1591 45.3571 22.0002C45.3571 9.84119 35.5075 0 23.3382 0C11.1688 0 1.31924 9.83949 1.31924 21.9998C1.31924 25.7404 2.25253 29.2607 3.89732 32.3432ZM25.5438 13C25.5438 11.5951 24.4049 10.4562 23 10.4562C21.5951 10.4562 20.4562 11.5951 20.4562 13V23.1751C20.4562 24.58 21.5951 25.7189 23 25.7189C24.4049 25.7189 25.5438 24.58 25.5438 23.1751V13ZM23 27.4562C21.5951 27.4562 20.4562 28.5951 20.4562 30C20.4562 31.4049 21.5951 32.5438 23 32.5438H23.0254C24.4303 32.5438 25.5692 31.4049 25.5692 30C25.5692 28.5951 24.4303 27.4562 23.0254 27.4562H23Z"
				fill="url(#paint0_linear_40266_301563)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_40266_301563"
				x1="22.6786"
				y1="0"
				x2="22.6786"
				y2="44.0003"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#505E77" />
				<stop offset="1" stopColor="#191F2B" />
			</linearGradient>
			<clipPath id="clip0_40266_301563">
				<rect
					width="45.3568"
					height="44"
					fill="white"
					transform="translate(0.321289)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default ExclamationIcon;
