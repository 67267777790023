import React from 'react';

const AndroidIcon = () => {
	return (
		<svg
			width="22"
			height="24"
			viewBox="0 0 22 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5535 11.4629L0.617661 22.0071C0.617661 22.0071 0.617661 22.011 0.617661 22.013C0.921877 23.1577 1.96714 24.0001 3.20936 24.0001C3.70663 24.0001 4.17076 23.8656 4.57053 23.6316L4.60173 23.6121L15.7856 17.1592L10.5515 11.4609L10.5535 11.4629Z"
				fill="#EA4435"
			/>
			<path
				d="M20.6043 9.66754L20.5945 9.66169L15.7661 6.86328L10.3272 11.7035L15.7856 17.1599L20.5867 14.3887C21.4292 13.9324 22.0006 13.0451 22.0006 12.0233C22.0006 11.0014 21.437 10.1239 20.6023 9.66754"
				fill="#F9BC15"
			/>
			<path
				d="M0.61764 1.99219C0.557187 2.21255 0.525986 2.44461 0.525986 2.68253V21.3158C0.525986 21.5557 0.557187 21.7858 0.61764 22.0061L10.8928 11.731L0.615691 1.99219H0.61764Z"
				fill="#547DBF"
			/>
			<path
				d="M10.6256 12.001L15.7681 6.86048L4.59783 0.384172C4.19221 0.140408 3.71833 0 3.21131 0C1.97104 0 0.923828 0.844398 0.617661 1.99106C0.617661 1.99106 0.617661 1.99106 0.617661 1.99301L10.6276 12.001H10.6256Z"
				fill="#33A851"
			/>
		</svg>
	);
};

export default AndroidIcon;
