import React from 'react';

const QuestionIconDark = () => {
	return (
		<svg
			width="46"
			height="44"
			viewBox="0 0 46 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Layer_1" clipPath="url(#clip0_35410_80265)">
				<path
					id="Vector"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.2169 32.3432H4.21861C2.57382 29.2607 1.64053 25.7404 1.64053 21.9998C1.64053 9.83949 11.4901 0 23.6595 0C35.8288 0 45.6784 9.84119 45.6784 22.0002C45.6784 34.1591 35.8288 44.0003 23.6595 44.0003C18.5552 44.0003 13.8597 42.269 10.1279 39.362L0.321289 41.9495L4.2169 32.3432ZM25.8096 32.5274C25.8096 31.3408 24.847 30.379 23.6595 30.379C22.472 30.379 21.5093 31.3408 21.5093 32.5274C21.5093 33.7139 22.472 34.6757 23.6595 34.6757C24.847 34.6757 25.8096 33.7139 25.8096 32.5274ZM23.6595 11.043C19.8652 11.043 16.7787 14.1273 16.7787 17.918C16.7787 18.8672 17.5489 19.6367 18.4989 19.6367C19.4489 19.6367 20.2191 18.8672 20.2191 17.918C20.2191 16.0226 21.7625 14.4805 23.6595 14.4805C25.5564 14.4805 27.0999 16.0226 27.0999 17.918C27.0999 19.8133 25.5564 21.3554 23.6595 21.3554C22.7095 21.3554 21.9393 22.125 21.9393 23.0742V27.3712C21.9393 28.3204 22.7095 29.0899 23.6595 29.0899C24.6095 29.0899 25.3797 28.3204 25.3797 27.3712V24.5754C28.344 23.8103 30.5403 21.1154 30.5403 17.918C30.5403 14.1269 27.4534 11.043 23.6595 11.043Z"
					fill="url(#paint0_linear_35410_80265)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_35410_80265"
					x1="22.9999"
					y1="0"
					x2="22.9999"
					y2="44.0003"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#505E77" />
					<stop offset="1" stopColor="#191F2B" />
				</linearGradient>
				<clipPath id="clip0_35410_80265">
					<rect
						width="45.3568"
						height="44"
						fill="white"
						transform="translate(0.321289)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default QuestionIconDark;
