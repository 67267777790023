import SvgIcon from '@material-ui/core/SvgIcon';

const UnlockIcon = () => (
	<SvgIcon viewBox="0 0 44 44" style={{ width: `44px`, height: `44px` }} fill="none">
		<path
			d="M12.834 20.166V12.8327C12.834 10.4015 13.7998 8.06995 15.5188 6.35087C17.2379 4.63179 19.5695 3.66602 22.0007 3.66602C24.4318 3.66602 26.7634 4.63179 28.4825 6.35087C30.2015 8.06995 31.1673 10.4015 31.1673 12.8327"
			stroke="url(#paint0_linear_34873_259965)"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M34.8333 20.167H9.16667C7.14162 20.167 5.5 21.8086 5.5 23.8337V36.667C5.5 38.692 7.14162 40.3337 9.16667 40.3337H34.8333C36.8584 40.3337 38.5 38.692 38.5 36.667V23.8337C38.5 21.8086 36.8584 20.167 34.8333 20.167Z"
			fill="url(#paint1_linear_34873_259965)"
			stroke="url(#paint2_linear_34873_259965)"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_34873_259965"
				x1="22.0007"
				y1="3.66602"
				x2="22.0007"
				y2="20.166"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#505E77" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_34873_259965"
				x1="22"
				y1="20.167"
				x2="22"
				y2="40.3337"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#505E77" />
				<stop offset="1" stopColor="#191F2B" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_34873_259965"
				x1="22"
				y1="20.167"
				x2="22"
				y2="40.3337"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#505E77" />
				<stop offset="1" stopColor="#191F2B" />
			</linearGradient>
		</defs>
	</SvgIcon>
);

export default UnlockIcon;
