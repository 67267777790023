import { getValueFromBrowser, removeValueFromBrowser, setValueToBrowser } from './consts';

const validKeys = [
	'affiliateId',
	'sourceId',
	'subId',
	'clickId',
	'product',
	'var1',
	'var2',
	'var3',
	'var4',
	'var5',
	'offer',
];
export const trackingQuery = (params) => {
	const affiliateIdKey = validKeys[0];
	if (params[affiliateIdKey] || params[affiliateIdKey.toLowerCase()]) {
		removeTrackingParams();
	}
	Object.keys(params).forEach((key) => {
		let valueKey = params[key];
		if (!validKeys.includes(key)) {
			const findKey = validKeys.find(
				(validKey) => validKey.toLowerCase() === key.toLowerCase()
			);
			valueKey = key === 'product' ? params[key].toString() : valueKey;
			key = findKey;
		}
		if (validKeys.includes(key)) {
			const value = key === 'product' ? valueKey.toString() : valueKey;
			setValueToBrowser(key, value);
		}
	});
};

export const getTrackingValues = (couponCode) => {
	const product = parseInt(getValueFromBrowser('product')) || 1;
	return {
		affiliateId: getValueFromBrowser('affiliateId') || '',
		sourceId: getValueFromBrowser('sourceId') || '',
		subId: getValueFromBrowser('subId') || '',
		clickId:
			getValueFromBrowser('clickId') ||
			getValueFromBrowser('rtkclickid-store') ||
			'',
		product: product,
		fullUrl: getValueFromBrowser('fullUrl') || '',
		couponCode,
		var1: getValueFromBrowser('var1') || '',
		var2: getValueFromBrowser('var2') || '',
		var3: getValueFromBrowser('var3') || '',
		var4: getValueFromBrowser('var4') || '',
		var5: getValueFromBrowser('var5') || '',
		offer: getValueFromBrowser('offer') || '',
	};
};

export const removeTrackingParams = () => {
	validKeys.forEach((key) => removeValueFromBrowser(key));
};

export const AffiliateProduct = Object.freeze({
	Casino: 1,
	Sport: 2,
});

/**
 * The offer type is not case-sensitive
 */
export const OFFER_TYPE = Object.freeze({
	SPOFF1: 'spoff1',
	SPOFF2: 'spoff2',
	SPOFF3: 'spoff3',
	SPOFF5: 'spoff5',
	CA500: 'ca500',
	CAOFF2: 'caoff2',
	CAOFF3: 'caoff3',
	CAOFF4: 'caoff4',
	CAOFF5: 'caoff5',
	CAOFFN500: 'caoffn500',
});
