import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import axios from '../../../utils/api/config';

import styles from './resetPassword.module.scss';
import { PopupType } from '../SmallModal/SmallModal';
import getMessage from '../../../utils/locale';
import usePasswordHints from '../../../utils/hooks/useHintsPassword';
import { PopupContainer } from '../../Container/Container';
import {
	getNewPasswordSchema,
	selectValidMessagesPassword,
} from '../../../utils/validation';
import { getSiteApi } from '../../../utils/session';
import InputLineWithLabel from '../../../UI/InputLineWithLabel/InputLineWithLabel';
import UnlockIcon from '../../Icons/UnlockIcon';
import SuccessIcon from '../../../UI/icons/SuccessIcon';

const ResetPassword = ({ onClose, mobileCode, setModalAfterResetPassword }) => {
	const [filled, setFilled] = useState(false);
	const { hintsPassword, setHintsPassword } = usePasswordHints();

	const [errorServerMessage, setErrorServerMessage] = useState('');

	const schema = useMemo(() => getNewPasswordSchema(), []);

	const { register, handleSubmit, formState, watch } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			newPassword: '',
			confirmPassword: '',
		},
	});

	// TODO: This code will be using in future
	// The modal shows only when we click on the link in the email.
	// So we can't open the modal and invoke dispatch function
	// In order to show the modal we must invoke the dispatch function somewhere
	// const mockFunc = () => {
	//     dispatch(openResetPassword);
	// };

	const formFields = watch();
	const { errors, dirtyFields } = formState;
	const { newPassword, confirmPassword } = dirtyFields;

	useEffect(() => {
		setFilled(Object.keys(dirtyFields).length === 2);
	}, [newPassword, confirmPassword]);

	useEffect(() => {
		selectValidMessagesPassword(
			formFields.newPassword,
			hintsPassword,
			setHintsPassword
		);
	}, [formFields.newPassword]);

	const handleRequest = useCallback(async (newPassword) => {
		axios
			.post(`${getSiteApi()}player/crm/password/confirm`, {
				password: newPassword,
				confirmationCode: mobileCode,
			})
			.then(() => {
				setModalAfterResetPassword({
					open: true,
					title: getMessage('es', 'smallModal.title.congratulations'),
					description: getMessage(
						'es',
						'smallModal.description.changePassword'
					),
					icon: <SuccessIcon />,
					openLogin: true,
				});
				onClose();
			})
			.catch((e) => {
				setErrorServerMessage(getMessage('es', e.response?.data?.message));
			});
	}, []);

	const onSubmit = async (data, e) => {
		e.preventDefault();

		const { newPassword } = data;
		const isValid = !Object.keys(errors).length;
		if (isValid) {
			await handleRequest(newPassword);
		}
	};

	const onChange = () => {
		setErrorServerMessage('');
	};

	return (
		<PopupContainer
			classes={styles.resetPassword}
			onClose={onClose}
			displayCloseButton
			typeModal={PopupType.ResetPassword}
		>
			<div className={styles.resetPassword__icon}>
				<UnlockIcon />
			</div>
			<h2 className={styles.title}>
				{getMessage('es', 'resetPasswordByMethods.title')}
			</h2>
			<form
				className={styles.form}
				onChange={onChange}
				onSubmit={handleSubmit(onSubmit)}
				autoComplete={'off'}
			>
				<InputLineWithLabel
					reg={register('newPassword')}
					type="password"
					name="newPassword"
					label={getMessage(
						'es',
						'login.modal.resetPassword.input.newPassword',
						{
							ns: 'login',
						}
					)}
					placeholder={getMessage('es', 'resetPassword.enter.placeholder')}
					errorText={errors.newPassword?.message}
					hints={Object.values(hintsPassword)}
					hasValue={newPassword}
					isRegistrationInput
					showPlaceholder={false}
				/>
				<InputLineWithLabel
					reg={register('confirmPassword')}
					type="password"
					name="confirmPassword"
					label={getMessage('es', 'changePassword.repeat')}
					placeholder={getMessage('es', 'resetPassword.reEnter.placeholder')}
					errorText={errors.confirmPassword?.message}
					hasValue={confirmPassword}
					isRegistrationInput
					showPlaceholder={false}
				/>
				{errorServerMessage && (
					<div className={styles.errorText}>{errorServerMessage}</div>
				)}
				<div className={styles.buttons}>
					<button
						type="submit"
						disabled={!filled}
						className={styles.button_apply}
					>
						{getMessage('es', 'submitResetPassword')}
					</button>
				</div>
			</form>
		</PopupContainer>
	);
};

export default ResetPassword;
