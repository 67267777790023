import Cookies from 'js-cookie';
import axios from './api/api';
import {
	removeCookieFromDomains,
	removeValueFromBrowser,
	setCookieToDomains,
} from './consts';

const DELAY_BEFORE_EXPIRATION_FACTOR = 0.8;
const ACTIVITY_CHECK_DELAY = 10 * 1000; // 10 sec
const ACTIVITY_EXPIRATION_DELAY = 30 * 60 * 1000; // 30 min
const MAX_REFRESH_TOKEN_TIMEOUT = 2147483647;

let refreshTimeout;
let activityCheckInterval;

const now = () => new Date().getTime();

export const getSiteApi = () => process.env.REACT_APP_URL;

export const isLoggedIn = () =>
	Cookies.get('AuthorizationToken') && !localStorage.getItem('coolOffUntilDate');

export const isSessionExpired = () =>
	isLoggedIn() &&
	checkSessionLifetime() &&
	!isRememberUser() &&
	!isRememberUserCheckCookies();

export const checkSessionLifetime = () => now() > +Cookies.get('tokenExpirationDate');

export const isRememberUser = () =>
	localStorage.getItem('rememberUser')
		? localStorage.getItem('rememberUser') === 'remember'
		: false;

export const isRememberUserCheckCookies = () =>
	Cookies.get('rememberUser') ? Cookies.get('rememberUser') === 'remember' : false;

export const forceLogout = () => {
	destroySession().then(() => location.replace('/'));
};

const checkActivityExpiration = () =>
	new Date().getTime() - (+localStorage.getItem('lastRequestTime') || 0) >
	ACTIVITY_EXPIRATION_DELAY;

export const checkRememberUser = () =>
	localStorage.getItem('rememberUser') === 'remember';

export const initializeSession = (sessionData = null) => {
	// Set default language to Spanish. In order to override the accept-language header we need to set the required default language
	setCookieToDomains('NEXT_LOCALE', 'es');
	if (sessionData) {
		setCookieToDomains(
			'tokenExpirationDate',
			sessionData.expiresIn.tokenExpirationDate
		);
		setCookieToDomains('AuthorizationToken', `Bearer ${sessionData.accessToken}`);
		setCookieToDomains('RefreshToken', `Bearer ${sessionData.refreshToken}`);
	}

	const tokenExpirationDate = parseInt(Cookies.get('tokenExpirationDate'));

	if (!tokenExpirationDate || tokenExpirationDate <= new Date().getTime()) {
		removeCookieFromDomains('tokenExpirationDate');
		removeCookieFromDomains('AuthorizationToken');
		clearTimeout(refreshTimeout);
		clearInterval(activityCheckInterval);
		return null;
	}

	if (!refreshTimeout) {
		let refreshTokenTimeout = Math.round(
			(tokenExpirationDate - new Date().getTime()) * DELAY_BEFORE_EXPIRATION_FACTOR
		);
		if (refreshTokenTimeout < 0) {
			forceLogout();
			return;
		} else if (refreshTokenTimeout > MAX_REFRESH_TOKEN_TIMEOUT) {
			refreshTokenTimeout = MAX_REFRESH_TOKEN_TIMEOUT;
		}
		refreshTimeout = setTimeout(() => {
			if (checkRememberUser() || !checkActivityExpiration()) {
				axios
					.post(`${getSiteApi()}player/crm/refreshToken`, {
						remember: checkRememberUser(),
					})
					.then((response) => {
						refreshTimeout = undefined;
						initializeSession(response.data);
					});
			} else {
				forceLogout();
			}
		}, refreshTokenTimeout);
	}

	if (!activityCheckInterval) {
		activityCheckInterval = setInterval(() => {
			if (checkActivityExpiration() && !checkRememberUser()) {
				forceLogout();
				clearInterval(activityCheckInterval);
				activityCheckInterval = 0;
			}
		}, ACTIVITY_CHECK_DELAY);
	}
};

export const removeLocalAuthData = () => {
	localStorage.removeItem('email');
	localStorage.removeItem('userId');
	localStorage.removeItem('rememberUser');
	localStorage.removeItem('lastRequestTime');
	localStorage.removeItem('_wz_verification_popup_opened');
	removeCookieFromDomains('tokenExpirationDate');
	removeCookieFromDomains('AuthorizationToken');
	removeCookieFromDomains('sb');
	removeValueFromBrowser('product');
	sessionStorage.removeItem('showVerificationModalDate');
	sessionStorage.removeItem('showBonusModalDate');
	clearTimeout(refreshTimeout);
	clearInterval(activityCheckInterval);
};

export const destroySession = async () => {
	const authorizationHeader = Cookies.get('AuthorizationToken');

	if (!authorizationHeader) {
		return Promise.resolve();
	}

	await axios.post(`${getSiteApi()}player/crm/logout`, {});

	sessionStorage.removeItem('prevPath');

	removeLocalAuthData();

	return true;
};
