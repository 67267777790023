export const TABS_TYPE = {
	DETAILS: 'Details',
	TERMS: 'Terms',
};

export const tabs = [
	{
		tab: 'DETAILS',
		title: 'promoTabDetails',
	},
	{
		tab: 'TERMS',
		title: 'promoTabTerms',
	},
];
