import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './sidemenu.module.scss';
import { topNavUser } from '../../utils/consts';
import SideMenuLocalItemUser from './SideMenuLocalItemUser';
import getMessage from '../../utils/locale';
import Infoicon from '../../UI/icons/SideMenu/InfoIcon';

const NavigationUser = ({ setShowSpinner }) => {
	const [listOpen, setListOpen] = useState(false);

	const listHandler = () => {
		setListOpen(!listOpen);
	};

	return (
		<div className={styles.navLinksInfo}>
			<h2
				className={clsx(styles.navInfoTitle, {
					[styles.navInfoTitle_active]: listOpen,
				})}
				onClick={listHandler}
			>
				<Infoicon />
				{getMessage('es', 'NavigationInfo')}
			</h2>
			<div
				className={clsx(styles.navLinksInfoList, {
					[styles.navLinksInfoList_open]: listOpen,
				})}
			>
				{topNavUser.map(({ id, label, link }) => {
					return (
						<SideMenuLocalItemUser
							key={id}
							link={link}
							label={label}
							setShowSpinner={setShowSpinner}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default NavigationUser;
