import React from 'react';

const SupportIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="mail">
				<path
					id="Vector"
					d="M3.2 3.96484H12.8C13.46 3.96484 14 4.47109 14 5.08984V11.8398C14 12.4586 13.46 12.9648 12.8 12.9648H3.2C2.54 12.9648 2 12.4586 2 11.8398V5.08984C2 4.47109 2.54 3.96484 3.2 3.96484Z"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_2"
					d="M14 4.96484L8 8.96484L2 4.96484"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default SupportIcon;
