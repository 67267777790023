import React from 'react';

const VisibleIcon = ({ color }) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		color={color}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.664062 8.00008C0.664062 8.00008 3.33073 2.66675 7.9974 2.66675C12.6641 2.66675 15.3307 8.00008 15.3307 8.00008C15.3307 8.00008 12.6641 13.3334 7.9974 13.3334C3.33073 13.3334 0.664062 8.00008 0.664062 8.00008Z"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default VisibleIcon;
