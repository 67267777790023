import getMessage from './locale';
import { getSiteApi } from './session';
import {
	getValueFromBrowser,
	NO_CONSECUTIVE_SPECIAL_CHARS_REG_EXP,
	REACT_APP_WINPOT_INTERNAL_LIVE_LOBBY,
	setCookieToDomains,
} from './consts';
import { isAndroid, isIOS } from 'react-device-detect';
import { actions } from '../redux/actions/commonActions';
import { PopupType } from '../components/Modals/SmallModal/SmallModal';
import isEmail from 'validator/es/lib/isEmail';
import { AffiliateProduct, OFFER_TYPE } from './trackingQueryHook';
import sportSpoff1Banner from '../images/signup/sport-spoff1.jpg';
import sportSpoff2Banner from '../images/signup/sport-spoff2.jpg';
import sportSpoff3Banner from '../images/signup/sport-spoff3.jpg';
import sportSpoff5Banner from '../images/signup/sport-spoff5.jpg';
import sportDefaultBanner from '../images/signup/sport-default.jpg';
import casinoCa500Banner from '../images/signup/casino-ca500.jpg';
import casinoCaoff2Banner from '../images/signup/casino-caoff2.jpg';
import casinoCaoff3Banner from '../images/signup/casino-caoff3.jpg';
import casinoCaoff5Banner from '../images/signup/casino-caoff5.jpg';
import casinoCaoffn500Banner from '../images/signup/casino-caoffn500.jpg';
import casinoDefaultBanner from '../images/signup/casino-default.jpg';

const MAX_INITIAL_LENGTH = 30;

export const showInitials = (firstName, lastName) => {
	if (!firstName || !lastName) return '';
	if (firstName.length + lastName.length > MAX_INITIAL_LENGTH) {
		return firstName;
	}

	return `${firstName} ${lastName}`;
};

export const setShowVerificationModalTime = (time) => {
	sessionStorage.setItem('showVerificationModalDate', (Date.now() + time).toString());
};

export const validateUniqueFields = (errorMessage, setError) => {
	const errorField = errorMessage.split('.');
	let firstErrorInput = errorField[1];
	const secondErrorInput = errorField[2];
	const thirdErrorInput = errorField[3];

	if (firstErrorInput === 'phone') {
		firstErrorInput += 'Number';
	}
	setError(firstErrorInput, {
		type: 'value',
		message: getMessage('es', `duplicate.${firstErrorInput}`),
	});
	if (secondErrorInput) {
		setError(secondErrorInput, {
			type: 'value',
			message: getMessage('es', `duplicate.${secondErrorInput}`),
		});
	}
	if (thirdErrorInput) {
		setError(thirdErrorInput, {
			type: 'value',
			message: getMessage('es', `duplicate.${thirdErrorInput}`),
		});
	}
};

export const handleFBRegistration = () => {
	const otherInfo = {
		currency: 'MXN',
		language: 'es',
		citizenship: 'MX',
	};
	setCookieToDomains('otherInfo', JSON.stringify(otherInfo));
	window.location.href = `${getSiteApi()}auth/facebook`;
};

export const loginRegistrationAdditionalHeaders = () => {
	let platform = 'web';
	if (isIOS) {
		platform += '-ios';
	} else if (isAndroid) {
		platform += '-android';
	}

	return {
		headers: {
			'x-platform': platform,
		},
	};
};

export const addQueryParamFromUrl = (key, value) => {
	const params = new URLSearchParams(window.location.search);
	params.set(key, value);
	const newUrl = `${window.location.pathname}?${params.toString()}`;
	window.history.pushState({}, '', newUrl);
};

export const removeQueryParamFromUrl = (key) => {
	const params = new URLSearchParams(window.location.search);
	params.delete(key);
	const newUrl = `${window.location.pathname}?${params.toString()}`;
	window.history.pushState({}, '', newUrl);
};

export const clickedOnLink = (link, dispatch, beforeRedirect = () => {}) => {
	if (REACT_APP_WINPOT_INTERNAL_LIVE_LOBBY) {
		beforeRedirect && beforeRedirect();
		window.location.href = link;
	} else {
		if (link.includes('/live/lobby')) {
			dispatch({
				type: actions.OpenPopUp,
				payload: {
					category: PopupType.LoginPromo,
				},
			});
		} else {
			beforeRedirect && beforeRedirect();
			window.location.href = link;
		}
	}
};

export const isValidEmail = (email = '', isExtended = false) => {
	const preprocessEmail = String(email).trim();
	if (isExtended) {
		return (
			NO_CONSECUTIVE_SPECIAL_CHARS_REG_EXP.test(preprocessEmail) &&
			isEmail(preprocessEmail)
		);
	}
	return isEmail(preprocessEmail);
};

export const getSignUpBanner = () => {
	let userOffer = getValueFromBrowser('offer') || '';
	userOffer = userOffer.toLowerCase();
	const userProduct =
		+getValueFromBrowser('product') ||
		+getValueFromBrowser('product_1') ||
		+getValueFromBrowser('product_2') ||
		1;
	if (userProduct === AffiliateProduct.Sport) {
		switch (userOffer) {
			case OFFER_TYPE.SPOFF1:
				return sportSpoff1Banner;
			case OFFER_TYPE.SPOFF2:
				return sportSpoff2Banner;
			case OFFER_TYPE.SPOFF3:
				return sportSpoff3Banner;
			case OFFER_TYPE.SPOFF5:
				return sportSpoff5Banner;
			default:
				return sportDefaultBanner;
		}
	} else {
		switch (userOffer) {
			case OFFER_TYPE.CA500:
				return casinoCa500Banner;
			case OFFER_TYPE.CAOFF2:
				return casinoCaoff2Banner;
			case OFFER_TYPE.CAOFF3:
				return casinoCaoff3Banner;
			case OFFER_TYPE.CAOFF5:
				return casinoCaoff5Banner;
			case OFFER_TYPE.CAOFFN500:
				return casinoCaoffn500Banner;
			default:
				return casinoDefaultBanner;
		}
	}
};

export const handleClickOnGamification = (dispatch, historyPopupType) => {
	localStorage.setItem('winclubPath', '/winclub');
	dispatch({
		type: actions.OpenPopUp,
		payload: {
			category: PopupType.LoginPromo,
			historyPopupType: [...historyPopupType, PopupType.LoginPromo],
		},
	});
};
