import clsx from 'clsx';
import React, { useState } from 'react';

import styles from './Input.module.scss';
import SearchIcon from '../icons/SearchIcon';

export const Input = ({
	initialValue,
	type = 'text',
	inputMode,
	errorText,
	label,
	name,
	size = 'auto',
	disabled,
	required,
	setSelectedValue,
	placeholder,
	search,
	searchForGames,
}) => {
	const [value, setValue] = useState(initialValue || '');
	const handleChange = async (e) => {
		setSelectedValue &&
			setSelectedValue((prev) => ({
				...prev,
				[e.target.name]: e.target.value,
			}));
		setValue(e.target.value);
	};

	return (
		<div
			className={clsx(styles.wrapperInput, {
				[styles.medium]: size === 'medium',
			})}
		>
			{label && (
				<label className={styles.label} htmlFor={label}>
					{t(label)}:
				</label>
			)}
			{search && (
				<SearchIcon
					className={clsx(styles.searchIcon, {
						[styles.searchForGames]: searchForGames,
					})}
				/>
			)}
			<input
				type={type}
				inputMode={inputMode}
				name={name}
				required={required}
				className={clsx(styles.input, {
					[styles.error]: errorText,
					[styles.search]: search,
					[styles.searchForGames]: searchForGames,
				})}
				onChange={(e) => handleChange(e)}
				placeholder={placeholder}
				value={value}
				disabled={disabled}
			/>
			{errorText && <div className={styles.errorText}>{errorText}</div>}
		</div>
	);
};
export default Input;
