import React, { useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '../../../UI/Button/Button';
import InputLineWithLabel from '../../../UI/InputLineWithLabel/InputLineWithLabel';
import { PopupContainer } from '../../Container/Container';

import {
	DEFAULT_COUNTRY_INDEX,
	phoneCodes,
	phoneCodesSort,
} from '../../../UI/MultiSelect/phoneCodes';

import getMessage from '../../../utils/locale';
import screenSizes, {
	checkIfPreRelease,
	PASSWORD_REG_EXP_WITHOUT_CYRILLIC,
	REACT_APP_WINPOT_SHOW_FB_BUTTON,
	REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
} from '../../../utils/consts';

import {
	checkEnterCharactersOnlyNumbers,
	checkUserAbove18,
	getPhoneDataByCode,
	getSignUpScheme,
	isValidDayOfMonth,
	selectValidMessagesPassword,
} from '../../../utils/validation';
import prepareRequestsData from './prepareRequestsData';

import styles from './signup.module.scss';
import { actions } from '../../../redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import SuccessRegistrationPopup from '../SuccessRegistrationPopup/SuccessRegistrationPopup';
import { appData, useModal, useUserData } from '../../../redux/selectors';
import { resetErrorServerMessage } from '../../../redux/actions/userSession';
import CheckBox from '../../../UI/CheckBox/CheckBox';
import loadable from '@loadable/component';
import { handleFBRegistration, validateUniqueFields } from '../../../utils/common';
import clsx from 'clsx';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { PopupType } from '../SmallModal/SmallModal';
import IconFacebook from '../../../UI/icons/social/Facebook';

const MultiSelect = loadable(() => import('../../../UI/MultiSelect/MultiSelect'));

let timerFocusFromDayToMonth;
let timerFocusFromMonthToYear;

const SignUpModalOldVersion = () => {
	const dispatch = useDispatch();
	const userDataRedux = useUserData();
	const { category, historyPopupType = [] } = useModal();
	const size = useWindowSize();
	const isMobileView = size.width < screenSizes.mobileMax;

	const [openCongratulationPopUp, setOpenCongratulationPopUp] = useState(false);
	const [errorServerMessage, setErrorServerMessage] = useState(['', '']);
	const [errorValidAge, setErrorValidAge] = useState('');
	const [errorDayMessage, setErrorDayMessage] = useState('');

	const refForm = useRef(null);
	const widthForm = refForm?.current?.offsetWidth;
	const { loading } = appData();

	const [isShowMultiSelectItems, setIsShowMultiSelectItems] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [filled, setFilled] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState('');

	const [hintsPassword, setHintsPassword] = useState({
		hintPasswordMinMax: {
			isValid: false,
			text: getMessage('es', 'hintPasswordMinMax'),
			name: 'hintPasswordMinMax',
		},
		hintPasswordUppercase: {
			isValid: false,
			text: getMessage('es', 'hintPasswordUppercase'),
			name: 'hintPasswordUppercase',
		},
		hintPasswordLowercase: {
			isValid: false,
			text: getMessage('es', 'hintPasswordLowercase'),
			name: 'hintPasswordLowercase',
		},
		hintPasswordNumber: {
			isValid: false,
			text: getMessage('es', 'hintPasswordNumber'),
			name: 'hintPasswordNumber',
		},
		hintPasswordSpaces: {
			isValid: true,
			text: getMessage('es', 'hintPasswordSpaces'),
			name: 'hintPasswordSpaces',
		},
	});

	const [phoneCodeIndex, setPhoneCodeIndex] = useState(
		phoneCodesSort[DEFAULT_COUNTRY_INDEX].ind
	);
	// 	const [allowPromotions, setAllowPromotions] = useState({ allowPromotions: true });
	const [allowMarketing, setAllowMarketing] = useState({ allowMarketing: true });

	const phoneData = useMemo(() => {
		return getPhoneDataByCode(phoneCodes[phoneCodeIndex].value);
	}, [phoneCodeIndex]);

	const schema = useMemo(() => getSignUpScheme(phoneData), [phoneCodeIndex]);

	const { register, handleSubmit, formState, reset, setError, watch } = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: '',
			lastName: '',
			phoneNumber: '',
			nickName: '',
			email: '',
			day: '',
			month: '',
			year: '',
			password: '',
			couponCode: '',
		},
		shouldFocusError: true,
	});

	const { errors, dirtyFields } = formState;
	const formFields = watch();

	useEffect(() => {
		[150, 300, 500, 700].forEach((delay) => {
			setTimeout(() => reset(), delay);
		});
	}, []);

	const {
		firstName,
		lastName,
		phoneNumber,
		email,
		nickName,
		day,
		month,
		year,
		password,
		couponCode,
	} = dirtyFields;

	useEffect(() => {
		selectValidMessagesPassword(formFields.password, hintsPassword, setHintsPassword);
	}, [formFields.password]);

	useEffect(() => {
		const currentErrors = Object.keys(errors);
		const isFilledAllFields = !!(
			!!dateOfBirth &&
			!errorValidAge &&
			PASSWORD_REG_EXP_WITHOUT_CYRILLIC.test(formFields.password) &&
			!currentErrors.length &&
			!errorServerMessage[0].length &&
			!errorDayMessage &&
			dateOfBirth.length > 7 &&
			formFields.year?.length === 4 &&
			(Object.keys(dirtyFields).length === 9 ||
				Object.keys(dirtyFields).length === 10)
		);
		setFilled(isFilledAllFields);
	}, [dateOfBirth, errorValidAge, formFields]);

	useEffect(() => {
		const { day, month, year } = formFields;
		const birthDate = `${year}-${month}-${day}`;
		setDateOfBirth(birthDate);

		const isValidCurrentDay = isValidDayOfMonth(
			parseInt(day),
			parseInt(month),
			parseInt(year)
		);

		if (day && month && year?.length === 4) {
			setErrorDayMessage(() =>
				isValidCurrentDay ? '' : getMessage('es', 'incorrect.day')
			);
		} else {
			setErrorDayMessage('');
		}

		if (errors.day?.message || errors.month?.message || errors.year?.message) {
			errorValidAge && setErrorValidAge('');
		} else {
			birthDate.length > 7 &&
				year.length === 4 &&
				setErrorValidAge(checkUserAbove18(birthDate));
		}

		return () => setErrorValidAge('');
	}, [
		formFields.day,
		formFields.month,
		formFields.year,
		errors.day?.message,
		errors.month?.message,
		errors.year?.message,
	]);

	useEffect(() => {
		clearTimeout(timerFocusFromDayToMonth);
		if (
			formFields.day.length === 2 &&
			!errors.day?.message &&
			!errorDayMessage &&
			formFields.day <= 31
		) {
			timerFocusFromDayToMonth = setTimeout(() => {
				document.querySelector("[name='month']").focus();
			}, 300);
		}
	}, [formFields.day, errors.day?.message, errorDayMessage]);

	useEffect(() => {
		clearTimeout(timerFocusFromMonthToYear);
		if (
			formFields.month.length === 2 &&
			!errors.month?.message &&
			formFields.month <= 12
		) {
			timerFocusFromMonthToYear = setTimeout(() => {
				document.querySelector("[name='year']").focus();
			}, 300);
		}
	}, [formFields.month, errors.month?.message]);

	const onSubmit = async (data) => {
		dispatch(resetErrorServerMessage());
		setErrorServerMessage(['', '']);

		if (errorValidAge) return;

		const phoneData = phoneCodes[phoneCodeIndex];
		const {
			firstName,
			lastName,
			phoneNumber,
			email,
			nickName,
			password,
			couponCode,
		} = data;
		const currentUserData = {
			dateOfBirth,
			firstName,
			lastName,
			phoneNumber,
			password,
			// confirmPassword,
			nickName,
			email,
			phoneCode: phoneData.value,
			isPushNewsletter: allowMarketing.allowMarketing,
			isPhoneNewsletter: allowMarketing.allowMarketing,
			isEmailNewsletter: allowMarketing.allowMarketing,
			allowPromotions: true,
			// allowPromotions: allowPromotions.allowPromotions,
			couponCode,
		};

		const { profileData } = prepareRequestsData(currentUserData);
		localStorage.setItem('singUpRequest', true);
		dispatch({
			type: actions.SignUp,
			payload: {
				profileData,
				email,
				nickName,
				password,
				t: getMessage,
				validateUniqueFields,
				setError,
			},
		});
	};

	useEffect(() => {
		localStorage.getItem('email') && dispatch({ type: actions.ClosePopUp });
	}, [window.localStorage.getItem('email')]);

	useEffect(() => {
		localStorage.removeItem('singUpRequest');
	}, []);

	useEffect(() => {
		const [field = '', errorMessage = ''] = userDataRedux?.errorServerMessage
			? Object.entries(userDataRedux?.errorServerMessage)[0]
			: ['', ''];
		setErrorServerMessage([field, errorMessage]);
	}, [userDataRedux?.errorServerMessage]);

	useEffect(() => {
		if (userDataRedux?.registrationStep) {
			dispatch({ type: actions.ClosePopUp });
		}
	}, [userDataRedux.registrationStep]);

	const onClose = () => {
		dispatch(resetErrorServerMessage());
		setErrorServerMessage(['', '']);
		dispatch({
			type: actions.ClosePopUp,
			historyPopupType: [...historyPopupType, PopupType.None],
		});
	};

	const onCloseForm = () => {
		onClose();
	};

	const onBackHandle = () => {
		onClose();
	};

	const onBlur = (data) => {
		if (data?.target?.name === 'email') {
			dispatch({
				type: actions.ValidateEmail,
				payload: {
					body: data?.target?.name,
					data: data?.target?.value.trim(),
					t: getMessage,
					validateUniqueFields,
					setError,
				},
			});
		}
		// When we will decide to use validate api for phone number also
		// else if(data?.target?.name === NamesOfInputs.PhoneNumber) {
		//     dispatch({
		//         type: actions.ValidatePhone,
		//         payload: {
		//             body: 'phone',
		//             data: {
		//                 phone: data?.target?.valueAsNumber,
		//                 regionCode: 'MX',
		//             },
		//             t: tOther,
		//         },
		//     });
		// }
	};

	return (
		<>
			<PopupContainer
				classes={styles.container}
				displayCloseButton
				onClose={onCloseForm}
				typeModal={category}
				displayBackButton={isMobileView}
				onBack={onBackHandle}
			>
				<div className={styles.header}>
					<p
						className={clsx(styles.title, {
							[styles.isMobileView]: isMobileView,
						})}
					>
						{getMessage('es', 'signUp.form.button.open.account')}
					</p>
				</div>
				{REACT_APP_WINPOT_SHOW_FB_BUTTON && (
					<>
						<div className={styles.wrapperBtnFC}>
							<Button
								text={getMessage('es', 'loginFormButtonFacebook')}
								classes={'btnPrimaryBlue'}
								iconStart={<IconFacebook />}
								onClick={handleFBRegistration}
								disabled={checkIfPreRelease()}
							/>
						</div>
						<div className={styles.borderDiv}>
							<p className={styles.borderSpan}>
								<span className={styles.borderSpanSign}>
									{getMessage('es', 'login.form.or')}
								</span>
							</p>
						</div>
					</>
				)}
				<form
					className={clsx(styles.content, {
						[styles.newSignUpForm]: REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
					})}
					onSubmit={handleSubmit(onSubmit)}
					ref={refForm}
					autoComplete="off"
					onBlur={onBlur}
				>
					<div className={styles.form__inner}>
						<InputLineWithLabel
							name="firstName"
							type="text"
							placeholder={getMessage('es', 'fieldFirstName')}
							errorText={errors.firstName?.message}
							reg={register('firstName')}
							hasValue={firstName}
						/>
						<InputLineWithLabel
							name="lastName"
							placeholder={getMessage('es', 'fieldLastName')}
							errorText={errors.lastName?.message}
							type="text"
							reg={register('lastName')}
							hasValue={lastName}
						/>
						<InputLineWithLabel
							reg={register('email', {
								setValueAs: (v) => v.trim(),
							})}
							type="text"
							name="email"
							placeholder={getMessage('es', 'fieldEmail')}
							errorText={
								errors.email?.message ||
								(errorServerMessage[0] === 'email' &&
									errorServerMessage[1])
							}
							hasValue={email}
						/>
						<div className={styles.datePickerWrapper}>
							<div className={styles.datePicker}>
								<InputLineWithLabel
									name="day"
									maxlength={'2'}
									inputMode={'numeric'}
									placeholder={getMessage('es', 'fieldDay')}
									errorText={errors.day?.message || errorDayMessage}
									reg={register('day', {
										onChange: (e) =>
											checkEnterCharactersOnlyNumbers(e),
									})}
									hasValue={day}
								/>
								<InputLineWithLabel
									name="month"
									maxlength={'2'}
									inputMode={'numeric'}
									placeholder={getMessage('es', 'fieldMonth')}
									errorText={errors.month?.message}
									reg={register('month', {
										onChange: (e) =>
											checkEnterCharactersOnlyNumbers(e),
									})}
									hasValue={month}
								/>
								<InputLineWithLabel
									name="year"
									maxlength={'4'}
									inputMode={'numeric'}
									placeholder={getMessage('es', 'fieldYear')}
									errorText={errors.year?.message}
									reg={register('year', {
										onChange: (e) =>
											checkEnterCharactersOnlyNumbers(e),
									})}
									hasValue={year}
								/>
							</div>
							{errorValidAge && (
								<div className={styles.errorText}>{errorValidAge}</div>
							)}
						</div>
						<div
							className={clsx(styles.wrapperPhone, {
								[styles.newSignUpForm]: REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
							})}
						>
							<div className={styles.wrapperCodePhone}>
								<MultiSelect
									setPhoneCodeIndex={setPhoneCodeIndex}
									items={phoneCodesSort}
									iconFlag
									search
									selectedItem={phoneCodeIndex}
									size={'tiny'}
									widthContent={widthForm}
									isShowSelectItems={isShowMultiSelectItems}
									setIsShowSelectItems={setIsShowMultiSelectItems}
									disabled={true}
								/>
							</div>
							<InputLineWithLabel
								reg={register('phoneNumber', {
									onChange: (e) => checkEnterCharactersOnlyNumbers(e),
								})}
								name="phoneNumber"
								placeholder={getMessage('es', 'fieldPhoneNumber')}
								errorText={
									errors.phoneNumber?.message ||
									(errorServerMessage[0] === 'phoneNumber' &&
										errorServerMessage[1])
								}
								hint={
									!errors.phoneNumber?.message
										? getMessage('es', 'hintPhoneNumber')
										: ''
								}
								size="medium"
								hasValue={phoneNumber}
							/>
						</div>
						<InputLineWithLabel
							reg={register('nickName', {
								setValueAs: (v) => v.trim(),
							})}
							type="text"
							name="nickName"
							placeholder={getMessage('es', 'fieldUserName')}
							autocomplete="off"
							errorText={
								errors.nickName?.message ||
								(errorServerMessage[0] === 'nickName' &&
									errorServerMessage[1])
							}
							hint={
								!errors.nickName?.message
									? getMessage('es', 'hintNickName')
									: ''
							}
							hasValue={nickName}
						/>
						<InputLineWithLabel
							reg={register('password')}
							type="password"
							name="password"
							iconPlace="loginFB"
							placeholder={getMessage('es', 'fieldPassword')}
							errorText={errors.password?.message}
							hints={Object.values(hintsPassword)}
							hasValue={password}
						/>
						{/*
						<CheckBox
							name="allowPromotions"
							label={getMessage('es', 'fieldAllowPromotions')}
							setSelectedValue={setAllowPromotions}
							checked={allowPromotions.allowPromotions}
							className={styles.checkbox}
							checkedColor="secondary"
							checkedClassName={styles.checkedCheckBox}
							labelClassName={styles.checkBoxLabel}
						/>
						*/}
						<div className={styles.checkBox}>
							<CheckBox
								name="allowMarketing"
								label={getMessage('es', 'fieldAllowMarketing')}
								setSelectedValue={setAllowMarketing}
								checked={allowMarketing.allowMarketing}
								className={styles.checkbox}
								checkedColor="secondary"
								checkedClassName={styles.checkedCheckBox}
								labelClassName={styles.checkBoxLabel}
							/>
						</div>
						<InputLineWithLabel
							reg={register('couponCode')}
							type="text"
							name="couponCode"
							placeholder={getMessage('es', 'fieldCouponCode')}
							errorText={errors.couponCode?.message}
							hasValue={couponCode}
						/>
					</div>
					<div className={styles.buttonSubmit}>
						<Button
							text={getMessage('es', 'signUpFormButtonSubmit')}
							classes={'btnPrimaryGreen'}
							type={'submit'}
							disabled={!filled || checkIfPreRelease() || loading}
						/>
					</div>
				</form>

				<div className={styles.divider} />

				<div className={styles.rulesAgreement}>
					<div
						dangerouslySetInnerHTML={{
							__html: getMessage('es', 'formMessageConditions'),
						}}
					/>
				</div>
			</PopupContainer>
			{openCongratulationPopUp && (
				<SuccessRegistrationPopup setOpen={setOpenCongratulationPopUp} />
			)}
		</>
	);
};

export default SignUpModalOldVersion;
