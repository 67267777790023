import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const CellPhoneIcon = ({ color, size }) => {
	return (
		<SvgIcon
			viewBox="0 0 44 44"
			style={{ fill: 'none', width: `${size}px`, height: `${size}px` }}
		>
			<path
				d="M40.3336 31.0195V36.5195C40.3357 37.0301 40.2311 37.5355 40.0266 38.0033C39.822 38.4712 39.522 38.8911 39.1458 39.2363C38.7695 39.5815 38.3253 39.8442 37.8417 40.0078C37.358 40.1714 36.8455 40.2322 36.337 40.1862C30.6955 39.5732 25.2764 37.6455 20.5153 34.5579C16.0856 31.7431 12.3301 27.9875 9.51528 23.5579C6.41691 18.7751 4.48873 13.3297 3.88695 7.66286C3.84114 7.15588 3.90139 6.64492 4.06387 6.1625C4.22635 5.68009 4.4875 5.23679 4.83069 4.86083C5.17388 4.48487 5.59159 4.18449 6.05723 3.97882C6.52288 3.77314 7.02624 3.66667 7.53528 3.66619H13.0353C13.925 3.65744 14.7876 3.9725 15.4622 4.55267C16.1368 5.13284 16.5774 5.93851 16.702 6.81953C16.9341 8.57965 17.3646 10.3079 17.9853 11.9712C18.2319 12.6274 18.2853 13.3405 18.1391 14.0261C17.9929 14.7117 17.6532 15.3411 17.1603 15.8395L14.832 18.1679C17.4418 22.7577 21.2421 26.558 25.832 29.1679L28.1603 26.8395C28.6587 26.3466 29.2881 26.0069 29.9737 25.8607C30.6593 25.7145 31.3724 25.7679 32.0286 26.0145C33.692 26.6352 35.4202 27.0657 37.1803 27.2979C38.0709 27.4235 38.8842 27.8721 39.4656 28.5583C40.047 29.2445 40.3559 30.1204 40.3336 31.0195Z"
				fill="url(#paint0_linear_36234_139046)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_36234_139046"
					x1="22.1034"
					y1="3.66602"
					x2="22.1034"
					y2="40.2011"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#505E77" />
					<stop offset="1" stopColor="#191F2B" />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};

export default CellPhoneIcon;
