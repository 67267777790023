import { ICON_COLORS, ICON_VARIANT } from './IconColors';
import Casino from '../UI/icons/SideMenu/Casino';
import AppleIcon from '../UI/icons/SideMenu/AppleIcon';
import AndroidIcon from '../UI/icons/SideMenu/AndroidIcon';
import { isIOS } from 'react-device-detect';
import Sports from '../UI/icons/SideMenu/Sports';
import SportsLive from '../UI/icons/SideMenu/SportsLive';
import Virtuales from '../UI/icons/SideMenu/Virtuales';
import Slots from '../UI/icons/SideMenu/Slots';
import Cookies from 'js-cookie';
import Promotions from '../UI/icons/SideMenu/Promotions';
import Winclub from '../UI/icons/SideMenu/Winclub';

export const LEGAL_AGE = 18;
export const MIN_FULL_YEAR_FOR_REGISTRATION = '1920-01-01T00:00:00.000Z';

export let ImageSize;

// export const urlAuthFacebook = `${getSiteApi()}auth/facebook`;

export const currentDomain = window.location.hostname;

(function (ImageSize) {
	ImageSize[(ImageSize['Small'] = 20)] = 'Small';
	ImageSize[(ImageSize['Medium'] = 24)] = 'Medium';
	ImageSize[(ImageSize['Large'] = 32)] = 'Large';
	ImageSize[(ImageSize['ExtraLarge'] = 40)] = 'ExtraLarge';
})(ImageSize || (ImageSize = {}));

const screenSizes = {
	mobileSmall: 320,
	mobile: 414,
	mobileMiddle: 480,
	mobileMax: 600,
	tablet: 700,
	tabletMax: 900,
	tabletMiddle: 1024,
	tabletLandScapeMax: 1200,
	desktop: 1366,

	MOBILE_VIEW: 414,
	MOBILE_MIDDLE_VIEW: 480,
	MAX_MOBILE_VIEW: 600,
	TABLET_VIEW: 700,
	MAX_TABLET_PORTRAIT: 900,
	TABLET_MIDDLE_VIEW: 1024,
	MAX_TABLET_LANDSCAPE: 1200,
	MENU_VISIBILITY: 1280,
};

export default screenSizes;

export const getAuthToken = () => {
	return Cookies.get('AuthorizationToken');
};

export const getRefreshToken = () => {
	return Cookies.get('RefreshToken');
};

export const getTokenExpirationDate = () => {
	return Cookies.get('tokenExpirationDate');
};

export const WINPOT_CASINO_URL = process.env.REACT_APP_WINPOT_CASINO;
export const productionDomains = ['winpot.mx'];
export const stageDomains = [
	'winpot-stage.wiztechgroup-services.com',
	'wiztechgroup-services.com',
];
export const localDomains = ['localhost'];

const envMode = process.env.REACT_APP_NODE_ENV || 'local';

let domains = [];
if (envMode === 'production') {
	domains = productionDomains;
} else if (envMode === 'stage') {
	domains = stageDomains;
} else {
	domains = localDomains;
}

export const setCookieToDomains = (key, value) => {
	domains.forEach((domain) => {
		Cookies.set(key, value, {
			domain: domain,
			expires: 10,
		});
	});
};

export const setValueToBrowser = (key, value) => {
	domains.forEach((domain) => {
		Cookies.set(key, value, {
			domain: domain,
			expires: 10,
		});
	});
	localStorage.setItem(key, value);
	sessionStorage.setItem(key, value);
};

export const getValueFromBrowser = (key) => {
	return Cookies.get(key) || localStorage.getItem(key) || sessionStorage.getItem(key);
};

export const removeValueFromBrowser = (key) => {
	domains.forEach((domain) => {
		Cookies.remove(key, {
			domain: domain,
		});
	});
	localStorage.removeItem(key);
	sessionStorage.removeItem(key);
};

export const removeCookieFromDomains = (key) => {
	domains.forEach((domain) => {
		Cookies.remove(key, {
			domain: domain,
		});
	});
};

export const checkIfPreRelease = () => {
	return false;
};

export const getRedirectAfterRegistration = () => {
	switch (parseInt(Cookies.get('product'))) {
		case 1:
			return 'casino';
		case 2:
			return 'sport';
		default:
			return 'casino';
	}
};
const isDeveloperMode = () => {
	const developerMode = Cookies.get('developer_mode');
	return developerMode === '1';
};

export const topNav = [
	{
		id: 1,
		label: 'Casino',
		link: `${WINPOT_CASINO_URL}casino`,
		Icon: Casino,
	},
	{
		id: 2,
		label: 'LiveCasino',
		link: `${WINPOT_CASINO_URL}live/lobby`,
		Icon: Slots,
	},
	{
		id: 3,
		label: 'Sport',
		link: `${WINPOT_CASINO_URL}sport`,
		Icon: Sports,
	},
	{
		id: 4,
		label: 'SportLive',
		link: `${WINPOT_CASINO_URL}live-sport`,
		Icon: SportsLive,
	},
	{
		id: 5,
		label: 'VirtualSports',
		link: `${WINPOT_CASINO_URL}virtual-sports`,
		Icon: Virtuales,
	},
	{
		id: 6,
		label: 'Promotions',
		link: `${WINPOT_CASINO_URL}promotions`,
		Icon: Promotions,
	},
];

export const topNavSecondary = [
	{
		id: 0,
		label: 'Promotions',
		link: `${WINPOT_CASINO_URL}promotions`,
		Icon: Promotions,
	},
	{
		id: 1,
		label: 'Winclub',
		link: `${WINPOT_CASINO_URL}winclub`,
		Icon: Winclub,
	},
	{
		id: 2,
		label: 'App',
		link: isIOS
			? 'https://apps.apple.com/mx/app/winpot-casino/id1632635281'
			: 'https://winpot-cdn.mx/prod/mobile/android/380/winpot_v380.apk',
		Icon: isIOS ? AppleIcon : AndroidIcon,
	},
];

export const topNavUser = [
	// {
	// 	id: 0,
	// 	label: 'InfoAccount',
	// 	link: `${WINPOT_CASINO_URL}info/open-an-account`,
	// },
	{
		id: 1,
		label: 'InfoDeposit',
		link: `${WINPOT_CASINO_URL}info/deposits-to-your-account`,
	},
	{
		id: 2,
		label: 'InfoWithdraw',
		link: `${WINPOT_CASINO_URL}info/withdraw-your-profits`,
	},
	{
		id: 3,
		label: 'InfoBonds',
		link: `${WINPOT_CASINO_URL}info/bonus-information`,
	},
	{
		id: 4,
		label: 'InfoBetRules',
		link: `${WINPOT_CASINO_URL}info/betting-rules`,
	},
	{
		id: 5,
		label: 'InfoVerification',
		link: `${WINPOT_CASINO_URL}info/verifications`,
	},
	{
		id: 6,
		label: 'InfoPayment',
		link: `${WINPOT_CASINO_URL}info/payment-options`,
	},
];

export const getCurrentColor = (color) => {
	return color === ICON_VARIANT.Action ? ICON_COLORS.Action : ICON_COLORS.Primary;
};

const PASSWORD_REG_EXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,30}$)/;
const PASSWORD_REG_EXP_WITHOUT_CYRILLIC =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=^\S+$)(?=.[^А-Яа-яЁёáéíñóúüÁÉÍÑÓÚÜ]*$)(?=.{8,30}$)/;
const EMAIL_REX_EXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const NAME_REG_EXP =
	/^([a-zA-ZáéíñóúüÁÉÍÑÓÚÜ][\w'\-,.]|[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ'\-. ])[^А-Яа-яЁё0-9\[\]_!¡?÷¿,№"\\/+=@^#$%ˆ\\&*(){}|~<>;:`]{1,}$/;
const NICK_NAME_REG_EXP =
	/^([\w'\-,.]|[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ0-9'\-.])[^А-Яа-яЁё\\[\]!¡?÷¿.,№"`'\\/+=@^#$%ˆ&*(){}|~<>;:\-\s]{2,}$/;
const NAME_EMAIL_REGEX = new RegExp(
	`${EMAIL_REX_EXP.source}|${NICK_NAME_REG_EXP.source}`
);
const NON_WHITESPACE_REG_EXP = /^(?=^\S+$)/;
const ONLY_WORD = /^(?=^\w+$)/;
const RUSSIAN_SPANISH_WORD = /[А-Яа-яЁёáéíñóúüÁÉÍÑÓÚÜ]/;
const ONLY_NUMBERS_REG_EXP =
	/[A-Za-zА-Яа-яЁёáéíñóúüÁÉÍÑÓÚÜ`,./\\';<>()[\]!¡?÷¿№=^#$%ˆ&*{}|~:@"\-+_\s]/gi;
export const NO_CONSECUTIVE_SPECIAL_CHARS_REG_EXP =
	/^(?:(?![!@#%^&*()_+\-=\[\]{}\\|;':",.\/<>?~`]{2,}).)*$/;

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;

const AccountStatus = {
	Blocked: 'Blocked',
	Frozen: 'Frozen',
	CoolOff: 'CoolOff',
	Lead: 'Lead',
	Closed: 'Closed',
	SelfClosed: 'SelfClosed',
};

const MAX_PHONE_NUMBER_LENGTH = 15;

const REACT_APP_WINPOT_SHOW_FB_BUTTON =
	process.env.REACT_APP_WINPOT_SHOW_FB_BUTTON === 'true' || false;

const REACT_APP_WINPOT_SHOW_NEW_SIGN_UP =
	process.env.REACT_APP_WINPOT_SHOW_NEW_SIGN_UP === 'true' || false;

const REACT_APP_WINPOT_INTERNAL_LIVE_LOBBY =
	process.env.REACT_APP_WINPOT_INTERNAL_LIVE_LOBBY === 'true' || false;

const isTestChatMode = () => {
	return true;
};

export {
	EMAIL_REX_EXP,
	NAME_EMAIL_REGEX,
	NAME_REG_EXP,
	NICK_NAME_REG_EXP,
	NON_WHITESPACE_REG_EXP,
	ONLY_WORD,
	PASSWORD_REG_EXP,
	ONE_MINUTE_IN_MILLISECONDS,
	AccountStatus,
	MAX_PHONE_NUMBER_LENGTH,
	PASSWORD_REG_EXP_WITHOUT_CYRILLIC,
	RUSSIAN_SPANISH_WORD,
	isDeveloperMode,
	REACT_APP_WINPOT_SHOW_FB_BUTTON,
	REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
	REACT_APP_WINPOT_INTERNAL_LIVE_LOBBY,
	ONLY_NUMBERS_REG_EXP,
	isTestChatMode,
	envMode,
};
