import { actions } from '../actions/commonActions';
import {
	BEGIN_USER_SESSION,
	END_USER_SESSION,
	PUT_ERROR_SERVER_MESSAGE,
	PUT_ERROR_SERVER_MESSAGE_OBJECT,
	RESET_ERROR_SERVER_MESSAGE,
	RESET_ERROR_USER_SESSION,
	RESET_USER_CHANGE_PASSWORD,
} from '../actions/userSession';

const initialState = {
	// statusRequest: 0,
	accessToken: '',
	emailVerified: false,
	statusError: 0,
	dataError: {},
	isChangePassword: false,
	documentsKYC: [],
	historyKYC: [],
	userInfo: {},
	registrationStep: '',
	errorServerMessage: null,
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.PutDataUser: {
			return {
				...state,
				...action.payload.userInfo,
				...action.payload,
			};
		}

		case BEGIN_USER_SESSION:
			return {
				...state,
				...action.payload,
			};

		case RESET_ERROR_USER_SESSION:
			return {
				...state,
				statusError: 0,
				dataError: {},
			};

		case END_USER_SESSION:
			return initialState;

		case RESET_USER_CHANGE_PASSWORD:
			return {
				...state,
				isChangePassword: false,
			};

		case PUT_ERROR_SERVER_MESSAGE_OBJECT:
			return {
				...state,
				errorServerMessage: { ...action.payload },
			};

		case PUT_ERROR_SERVER_MESSAGE:
			return {
				...state,
				errorServerMessage: action.payload,
			};

		case RESET_ERROR_SERVER_MESSAGE:
			return {
				...state,
				errorServerMessage: null,
				dataError: {},
			};

		case actions.CloseSignUp:
			return {
				...state,
				isPhoneValidated: false,
				isEmailValidated: false,
			};

		default:
			return state;
	}
};
