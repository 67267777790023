export const actions = {
	// for saga watcher
	Login: 'Login',
	SignUp: 'SignUp',
	GetInfo: 'GetInfo',
	GetBalance: 'GetBalance',

	// for redux
	StartRequest: 'StartRequest',
	StartRequestValidateFields: 'StartRequestValidateFields',
	PutDataUser: 'PutDataUser',
	PutDataFinance: 'PutDataFinance',
	FinishRequest: 'FinishRequest',
	FinishRequestValidateFields: 'FinishRequestValidateFields',

	OpenPopUp: 'OpenPopUp',
	ClosePopUp: 'ClosePopUp',
	ClickedOnSupport: 'ClickedOnSupport',
	ValidateEmail: 'ValidateEmail',
	ValidatePhone: 'ValidatePhone',
	CloseSignUp: 'CloseSignUp',
	UpdatePhone: 'UpdatePhone',
};

export const startRequest = () => {
	return {
		type: actions.StartRequest,
	};
};

export const startRequestValidateFields = () => {
	return {
		type: actions.StartRequestValidateFields,
	};
};

export const putDataUser = (data) => {
	return {
		type: actions.PutDataUser,
		payload: data,
	};
};

export const closeSignUp = () => {
	return {
		type: actions.CloseSignUp,
	};
};

export const putDataFinance = (data) => {
	return {
		type: actions.PutDataFinance,
		payload: data,
	};
};

export const finishRequest = () => {
	return {
		type: actions.FinishRequest,
	};
};

export const finishRequestValidateFields = () => {
	return {
		type: actions.FinishRequestValidateFields,
	};
};

export const clickedOnSupport = (data) => {
	return {
		type: actions.ClickedOnSupport,
		payload: data,
	};
};

export const validateUserEmail = (data) => {
	return {
		type: actions.ValidateEmail,
		payload: data,
	};
};

export const validateUserPhone = (data) => {
	return {
		type: actions.ValidatePhone,
		payload: data,
	};
};
