import clsx from 'clsx';
import React from 'react';

import styles from './header.module.scss';
import Navbar from './Navbar';

const Header = ({ setShowSpinner }) => {
	return (
		<div className={clsx(styles.header)}>
			<Navbar setShowSpinner={setShowSpinner} />
		</div>
	);
};

export default Header;
