import SportIcon from '../../../images/tab_ball.svg';
import CasinoIcon from '../../../images/tab_card.svg';

const tabsData = [
	{
		src: CasinoIcon,
		title: 'Casino',
	},
	{
		src: SportIcon,
		title: 'Deportes',
	},
];

export default tabsData;
