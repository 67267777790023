import React from 'react';

const SearchIcon = ({ className = '', color = '#bdbdbd' }) => (
	<div className={className}>
		<svg
			width="16"
			height="16"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.7722 19.1726L14.8912 14.2917C16.1045 12.8328 16.8354 10.9593 16.8354 8.91797C16.8354 4.27628 13.0592 0.5 8.41776 0.5C3.77618 0.5 0 4.27628 0 8.91797C0 13.5593 3.77618 17.3353 8.41776 17.3353C10.459 17.3353 12.3326 16.6045 13.7915 15.3912L18.6726 20.2722C18.8244 20.4241 19.0235 20.5 19.2224 20.5C19.4214 20.5 19.6204 20.4241 19.7723 20.2722C20.076 19.9685 20.076 19.4763 19.7722 19.1726ZM1.55518 8.91797C1.55518 5.13381 4.6337 2.05518 8.41776 2.05518C12.2017 2.05518 15.2801 5.13381 15.2801 8.91797C15.2801 12.7018 12.2017 15.7801 8.41776 15.7801C4.6337 15.7801 1.55518 12.7018 1.55518 8.91797Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default SearchIcon;
