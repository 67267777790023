import { object, string } from 'yup';
import { PhoneNumberUtil as PhoneNumberUtilLib } from 'google-libphonenumber';
const phoneNumberInstance = PhoneNumberUtilLib.getInstance();

import {
	EMAIL_REX_EXP,
	MAX_PHONE_NUMBER_LENGTH,
	NAME_EMAIL_REGEX,
	NAME_REG_EXP,
	NICK_NAME_REG_EXP,
	NON_WHITESPACE_REG_EXP,
	ONLY_NUMBERS_REG_EXP,
	PASSWORD_REG_EXP,
	PASSWORD_REG_EXP_WITHOUT_CYRILLIC,
	RUSSIAN_SPANISH_WORD,
} from './consts';
import { phoneCodes } from '../UI/MultiSelect/phoneCodes';
import getMessage from './locale';
import { LEGAL_AGE } from './consts';
import * as yup from 'yup';

const getTranslates =
	(t) =>
	(locale, key, params = {}) =>
		t(locale, key, params);

const getPhoneDataByCode = (phoneCodeValue) => {
	return phoneCodes.find((code) => code.value === phoneCodeValue) || null;
};

const validatePhone = ({
	context,
	phoneCodeData,
	fullPhoneNumber,
	errorMessageTranslation,
}) => {
	const { path, createError } = context;
	const checkedFullPhoneNumber = fullPhoneNumber.replace(ONLY_NUMBERS_REG_EXP, '');
	const phoneNumberWithoutExtension = checkedFullPhoneNumber.replace(
		phoneCodeData.text,
		''
	);
	if (phoneNumberWithoutExtension.length > MAX_PHONE_NUMBER_LENGTH) {
		return createError({
			path,
			message: errorMessageTranslation('es', 'field.length.max', {
				fieldName: errorMessageTranslation('es', 'fieldPhoneNumber'),
				length: `${MAX_PHONE_NUMBER_LENGTH}`,
			}),
		});
	} else if (checkedFullPhoneNumber.length < 6) {
		return createError({
			path,
			message: errorMessageTranslation('es', 'incorrect.phone'),
		});
	}

	const regionCode = phoneCodeData.codeFlag;
	const phoneNumber = phoneNumberInstance.parseAndKeepRawInput(
		checkedFullPhoneNumber,
		regionCode
	);

	return (
		phoneNumberInstance.isValidNumber(phoneNumber) ||
		createError({ path, message: errorMessageTranslation('es', 'incorrect.phone') })
	);
};

const getSignUpScheme = (phoneCodeData) => {
	const _t = getTranslates(getMessage);
	return object().shape({
		firstName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		lastName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		phoneNumber: string()
			.required(_t('es', 'incorrect.phone'))
			.test(`phoneValidation`, `phoneValidation`, function (value) {
				const fullPhoneNumber = `${phoneCodeData.text}${value}`;
				return validatePhone({
					// eslint-disable-next-line no-invalid-this
					context: this,
					phoneCodeData,
					fullPhoneNumber,
					errorMessageTranslation: _t,
				});
			}),
		email: string()
			.min(7, `${_t('es', 'minimum.char', { value: 7 })}`)
			.matches(EMAIL_REX_EXP, _t('es', 'incorrect.email'))
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		nickName: string()
			.min(4, `${_t('es', 'minimum.char', { value: 4 })}`)
			.matches(NICK_NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(20, `${_t('es', 'maximum.char', { value: 20 })}`),
		day: string().test(
			'Validate day',
			`${_t('es', 'incorrect.day')}`,
			(value) => !(value.length > 2 || Number(value) > 31 || Number(value) < 1)
		),
		month: string().test(
			'Validate month',
			`${_t('es', 'incorrect.month')}`,
			(value) => !(value.length > 2 || Number(value) > 12 || Number(value) < 1)
		),
		year: string().test(
			'Validate year',
			`${_t('es', 'incorrect.year')}`,
			(value) =>
				!(
					value.length > 4 ||
					value.length < 4 ||
					Number(value) > 2200 ||
					Number(value) < 1920
				)
		),
		couponCode: string().max(20, `${_t('es', 'maximum.char', { value: 20 })}`),
		// password: string()
		// .min(8, `${_t('es', 'minimum.char', { value: 8 })}`)
		// .required(_t('es', 'minimum.char.includes.letters.number'))
		// .matches(PASSWORD_REG_EXP, _t('es', 'includes.letters.number'))
		// .required()
		// .matches(NON_WHITESPACE_REG_EXP, _t('es', 'whitespace.not.allowed'))
		// .required()
		// .required(_t('es', 'latin.spanish.only'))
		// .max(30, `${_t('es', 'maximum.char', { value: 30 })}`),
	});
};

const getSignUpEmailAndPhoneScheme = (phoneCodeData) => {
	const _t = getTranslates(getMessage);
	return object().shape({
		email: string()
			.min(7, `${_t('es', 'minimum.char', { value: 7 })}`)
			.matches(EMAIL_REX_EXP, _t('es', 'incorrect.email'))
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		phoneNumber: string()
			.required(_t('es', 'incorrect.phone'))
			.test(`phoneValidation`, `phoneValidation`, function (value) {
				const fullPhoneNumber = `${phoneCodeData.text}${value}`;
				return validatePhone({
					// eslint-disable-next-line no-invalid-this
					context: this,
					phoneCodeData,
					fullPhoneNumber,
					errorMessageTranslation: _t,
				});
			}),
	});
};

const getSignUpPhoneScheme = (phoneCodeData) => {
	const _t = getTranslates(getMessage);
	return object().shape({
		phoneNumber: string()
			.required(_t('es', 'incorrect.phone'))
			.test(`phoneValidation`, `phoneValidation`, function (value) {
				const fullPhoneNumber = `${phoneCodeData.text}${value}`;
				return validatePhone({
					// eslint-disable-next-line no-invalid-this
					context: this,
					phoneCodeData,
					fullPhoneNumber,
					errorMessageTranslation: _t,
				});
			}),
	});
};

const getNewPasswordSchema = () => {
	const _t = getTranslates(getMessage);
	const fieldName = _t('es', 'confirmPassword');
	return yup.object().shape({
		newPassword: yup
			.string()
			.min(8, `${_t('es', 'minimum.char', { value: 8 })}`)
			.max(30, `${_t('es', 'maximum.char', { value: 30 })}`)
			.required(_t('es', 'minimum.char.includes.letters.number'))
			.matches(PASSWORD_REG_EXP, _t('es', 'includes.letters.number'))
			.matches(PASSWORD_REG_EXP_WITHOUT_CYRILLIC, _t('es', 'latin.numbers.only'))
			.matches(NON_WHITESPACE_REG_EXP, _t('es', 'whitespace.not.allowed')),
		confirmPassword: yup
			.string()
			.required(
				`${_t('es', 'required.field', {
					fieldName,
				})}`
			)
			.oneOf([yup.ref('newPassword'), null], _t('es', 'password.mismatch')),
	});
};

const getSignUpFullNameScheme = () => {
	const _t = getTranslates(getMessage);
	return object().shape({
		firstName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		lastName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		couponCode: string().max(20, `${_t('es', 'maximum.char', { value: 20 })}`),
	});
};

const getLoginSchema = (t) => {
	const _t = getTranslates(t);
	return object().shape({
		email: string()
			.required(_t('es', 'notification.empty.email'))
			.matches(NAME_EMAIL_REGEX, _t('es', 'login.incorrect')),
		password: string()
			.required(_t('es', 'notification.empty.password'))
			.min(4, _t('es', 'password.short')),
	});
};

const getEmailSchema = (t) => {
	const _t = getTranslates(t);
	return object().shape({
		email: string()
			.matches(EMAIL_REX_EXP, _t('es', 'incorrect.email'))
			.required(_t('es', 'incorrect.email')),
	});
};

const getEmptyScheme = () => object().shape({});

const getSignUpNamesScheme = (t) => {
	const _t = getTranslates(t);
	return object().shape({
		firstName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		lastName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
	});
};

const getSignUpSecondStepScheme = (t, phoneCodeData) => {
	const _t = getTranslates(t);
	return object().shape({
		phoneNumber: string()
			.required(_t('es', 'incorrect.phone'))
			.test(`phoneValidation`, `phoneValidation`, function (value) {
				const fullPhoneNumber = `${phoneCodeData.text}${value}`;
				return validatePhone({
					// eslint-disable-next-line no-invalid-this
					context: this,
					phoneCodeData,
					fullPhoneNumber,
					errorMessageTranslation: _t,
				});
			}),
		email: string()
			.min(7, `${_t('es', 'minimum.char', { value: 7 })}`)
			.matches(EMAIL_REX_EXP, _t('es', 'incorrect.email'))
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		nickName: string()
			.min(4, `${_t('es', 'minimum.char', { value: 4 })}`)
			.matches(NICK_NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(20, `${_t('es', 'maximum.char', { value: 20 })}`),
		day: string().test(
			'Validate day',
			`${_t('es', 'incorrect.day')}`,
			(value) => !(value.length > 2 || Number(value) > 31 || Number(value) < 1)
		),
		month: string().test(
			'Validate month',
			`${_t('es', 'incorrect.month')}`,
			(value) => !(value.length > 2 || Number(value) > 12 || Number(value) < 1)
		),
		year: string().test(
			'Validate year',
			`${_t('es', 'incorrect.year')}`,
			(value) =>
				!(
					value.length > 4 ||
					value.length < 4 ||
					Number(value) > 2200 ||
					Number(value) < 1920
				)
		),
		couponCode: string().max(20, `${_t('es', 'maximum.char', { value: 20 })}`),
		// password: string()
		// 	.min(8, `${_t('es', 'minimum.char', { value: 8 })}`)
		// 	.required(_t('es', 'minimum.char.includes.letters.number'))
		// 	.matches(PASSWORD_REG_EXP, _t('es', 'includes.letters.number'))
		// 	.matches(NON_WHITESPACE_REG_EXP, _t('es', 'whitespace.not.allowed'))
		// 	.required(_t('es', 'latin.spanish.only'))
		// 	.max(30, `${_t('es', 'maximum.char', { value: 30 })}`),
	});
};

const getCompleteProfileSchema = (t, phoneCodeData) => {
	const _t = getTranslates(t);
	return object().shape({
		firstName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		lastName: string()
			.min(2, `${_t('es', 'minimum.char', { value: 2 })}`)
			.matches(NAME_REG_EXP, `${_t('es', 'latin.spanish.only')}`)
			.max(50, `${_t('es', 'maximum.char', { value: 50 })}`),
		phoneNumber: string().test(
			`phoneValidation`,
			`phoneValidation`,
			function (value) {
				const fullPhoneNumber = `${phoneCodeData.text}${value}`;
				return validatePhone({
					// eslint-disable-next-line no-invalid-this
					context: this,
					phoneCodeData,
					fullPhoneNumber,
					errorMessageTranslation: _t,
				});
			}
		),
		email: string()
			.required(_t('es', 'incorrect.email'))
			.matches(EMAIL_REX_EXP, _t('es', 'incorrect.email')),
		nickName: string()
			.min(4, _t('es', 'field.length.min', { fieldName: 'Username', length: '4' }))
			.max(
				20,
				_t('es', 'field.length.max', { fieldName: 'Username', length: '20' })
			)
			.matches(NICK_NAME_REG_EXP, _t('es', 'latin.spanish.only'))
			.required(_t('es', 'latin.spanish.only')),
	});
};

export const selectValidMessagesPassword = (value, messagesObj, setMessagesObj) => {
	if (
		value.length >= 8 &&
		value.length <= 30 &&
		!RUSSIAN_SPANISH_WORD.test(value) &&
		!messagesObj['hintPasswordMinMax'].isValid
	) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordMinMax: { ...prev['hintPasswordMinMax'], isValid: true },
		}));
	}
	if (
		(value.length < 8 && messagesObj['hintPasswordMinMax'].isValid) ||
		value.length > 30 ||
		RUSSIAN_SPANISH_WORD.test(value)
	) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordMinMax: { ...prev['hintPasswordMinMax'], isValid: false },
		}));
	}
	if (/[A-Z]/g.test(value) && !messagesObj['hintPasswordUppercase'].isValid) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordUppercase: { ...prev['hintPasswordUppercase'], isValid: true },
		}));
	}
	if (!/[A-Z]/g.test(value) && messagesObj['hintPasswordUppercase'].isValid) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordUppercase: { ...prev['hintPasswordUppercase'], isValid: false },
		}));
	}
	if (/[a-z]/g.test(value) && !messagesObj['hintPasswordLowercase'].isValid) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordLowercase: { ...prev['hintPasswordLowercase'], isValid: true },
		}));
	}
	if (!/[a-z]/g.test(value) && messagesObj['hintPasswordLowercase'].isValid) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordLowercase: { ...prev['hintPasswordLowercase'], isValid: false },
		}));
	}
	if (/\d/g.test(value) && !messagesObj['hintPasswordNumber'].isValid) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordNumber: { ...prev['hintPasswordNumber'], isValid: true },
		}));
	}
	if (!/\d/g.test(value) && messagesObj['hintPasswordNumber'].isValid) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordNumber: { ...prev['hintPasswordNumber'], isValid: false },
		}));
	}
	if (
		NON_WHITESPACE_REG_EXP.test(value) &&
		!messagesObj['hintPasswordSpaces'].isValid
	) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordSpaces: { ...prev['hintPasswordSpaces'], isValid: true },
		}));
	}
	if (
		!NON_WHITESPACE_REG_EXP.test(value) &&
		messagesObj['hintPasswordSpaces'].isValid
	) {
		setMessagesObj((prev) => ({
			...prev,
			hintPasswordSpaces: { ...prev['hintPasswordSpaces'], isValid: false },
		}));
	}
};

const checkUserAbove18 = (birthDate) => {
	const transformBirthDay = birthDate
		.split('-')
		.map((number) => (number.length < 2 ? `0${number}` : number))
		.join('-');
	const birthDay = new Date(transformBirthDay);
	const diffYears = Math.abs(Date.now() - birthDay.getTime());
	const millisecondInOneYear = 1000 * 60 * 60 * 24 * 365;
	const leapYearInMillisecond = 1000 * 60 * 60 * 24;
	const isUnderLegalAge =
		(diffYears - leapYearInMillisecond * 4) / millisecondInOneYear < LEGAL_AGE;
	if (isUnderLegalAge || diffYears <= 0) {
		return getMessage('es', 'error.ageVerification');
	}
};

const daysInMonth = (m, y) => {
	switch (m) {
		case 2:
			return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;

		case 4:
		case 6:
		case 9:
		case 11:
			return 30;

		default:
			return 31;
	}
};

const isValidDayOfMonth = (d = 0, m = 0, y = 0) => {
	if (m > 0 && m <= 12 && d > 0) {
		return d <= daysInMonth(m, y);
	}
	return true;
};

const checkEnterCharactersOnlyNumbers = (e) => {
	e.target.value = e.target.value?.replace(ONLY_NUMBERS_REG_EXP, '');
};

const trimSpaceStart = (e) => {
	e.target.value = e.target.value?.replace(/^\s+/g, '');
};

const trimSpaceEnd = (e) => {
	e.target?.value?.replace(/^\s+$/g, '');
};

export {
	getPhoneDataByCode,
	getSignUpScheme,
	getSignUpEmailAndPhoneScheme,
	getSignUpFullNameScheme,
	getLoginSchema,
	getEmailSchema,
	getEmptyScheme,
	getSignUpNamesScheme,
	getSignUpSecondStepScheme,
	getCompleteProfileSchema,
	checkUserAbove18,
	isValidDayOfMonth,
	checkEnterCharactersOnlyNumbers,
	trimSpaceStart,
	trimSpaceEnd,
	getSignUpPhoneScheme,
	getNewPasswordSchema,
};
