import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import screenSizes from '../../utils/consts';
// import useKey from '../../utils/useKey';
import useWindowSize from '../../utils/hooks/useWindowSize';
import styles from './container.module.scss';
// import getMessage from '../../utils/locale';
import IconClose from '../../UI/icons/IconClose/IconClose';
import Close from '../../UI/icons/Close/Close';
import iconBack from '../../images/back.svg';
import { PopupType } from '../Modals/SmallModal/SmallModal';
import getMessage from '../../utils/locale';
import { addQueryParamFromUrl, removeQueryParamFromUrl } from '../../utils/common';

export const PopupContainer = (props) => {
	const size = useWindowSize();

	const {
		children,
		onClose,
		onBack,
		classes = '',
		background = '',
		displayCloseButton,
		displayBackButton = false,
		isShowBackButtonText = false,
		onlyCloseOnButtonClick,
		closeIconTop = false,
		closeIconBottom,
		closeSuccessPopup,
		open = true,
		typeModal = PopupType.None,
		setOpen = null,
	} = props;

	const refContainer = useRef(null);
	const refBack = useRef(null);

	const onCloseHandler = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	const onBackHandler = useCallback(() => {
		onBack();
	}, [onBack]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (onlyCloseOnButtonClick) return;
			const classNameTarget = event.target.className;
			if (
				refContainer.current &&
				!refContainer.current.contains(event.target) &&
				(typeof classNameTarget === 'string'
					? classNameTarget?.includes('background')
					: false)
			) {
				onCloseHandler();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refContainer.current]);

	useEffect(() => {
		const modalName = new URL(window.location.href).searchParams.get('modal');
		const modalWithQueryURL =
			typeModal === PopupType.Login ||
			typeModal === PopupType.SignUp ||
			typeModal === PopupType.ForgotPassword ||
			typeModal === PopupType.LoginPromo;
		if (!modalName && modalWithQueryURL && open) {
			addQueryParamFromUrl('modal', typeModal);
			window.dispatchEvent(new Event('popstate'));
		}

		return () => {
			if (modalWithQueryURL) {
				removeQueryParamFromUrl('modal');
			}
		};
	}, [typeModal]);

	// const onCloseRef = useRef(onCloseHandler);
	// useKey(['Escape'], onCloseRef);

	const modal = open ? (
		ReactDOM.createPortal(
			<div
				ref={refBack}
				className={clsx({
					[styles.background]: true,
					background,
					[styles.small__container]: isShowBackButtonText,
					[styles.no_padding]: PopupType.PromoPopup,
				})}
			>
				<div
					className={clsx(
						styles.container,
						{
							[styles.container_white_theme]:
								typeModal === PopupType.ForgotPassword ||
								typeModal === PopupType.SmallModal,
						},
						classes
					)}
					ref={refContainer}
				>
					{closeIconTop && (
						<IconClose
							onclick={setOpen ? () => setOpen(false) : onCloseHandler}
						/>
					)}
					{!closeIconBottom && displayCloseButton && !closeSuccessPopup && (
						<IconClose onclick={onCloseHandler} />
					)}
					{!closeIconBottom &&
						displayCloseButton &&
						isShowBackButtonText &&
						size.width <= screenSizes.mobileMiddle && (
							<IconClose onclick={onCloseHandler} />
						)}
					{!closeIconBottom && closeSuccessPopup && (
						<Close onclick={onCloseHandler} />
					)}
					{displayBackButton && (
						<button
							type="button"
							onClick={() => onBackHandler()}
							className={styles.popup__simpleBackButton}
							data-testid="close-button"
						>
							<img src={iconBack} alt="back" />{' '}
							{getMessage('es', 'buttonBack')}
						</button>
					)}
					{children}
					{closeIconBottom && <IconClose onclick={onCloseHandler} />}
				</div>
			</div>,
			document.querySelector('body')
		)
	) : (
		<noscript></noscript>
	);

	return modal;
};
