import clsx from 'clsx';
import React, { useState } from 'react';

import styles from './Input.module.scss';
import stylesInputLine from './InputLine.module.scss';
import IconPassword from '../icons/iconPassword/IconPassword';
import { REACT_APP_WINPOT_SHOW_NEW_SIGN_UP } from '../../utils/consts';

export const InputLineWithLabel = (props) => {
	const {
		type = 'text',
		inputMode,
		errorText,
		label,
		name,
		size = 'auto',
		disabled,
		placeholder,
		reg,
		autocomplete = 'off',
		hint,
		hints = [],
		value,
		hasValue,
		classNameIconPassword = '',
		forgotPassword,
		iconPlace,
		maxlength = '256',
		labelSubTitle = '',
		showPlaceholder = false,
		dataTestId = '',
	} = props;

	const { onBlur } = reg;

	const [showPassword, setShowPassword] = useState(false);
	const [onFocus, setOnFocus] = useState(false);

	const showLabel = () => {
		if (labelSubTitle) {
			return (
				<label className={styles.label} htmlFor={label}>
					{label}
					<span className={styles.label__subtitle}>{labelSubTitle}</span>
				</label>
			);
		} else {
			return (
				<label className={styles.label} htmlFor={label}>
					{label}
				</label>
			);
		}
	};

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	let eyeIcon;
	if (type === 'password') {
		eyeIcon = (
			<IconPassword
				showPassword={showPassword}
				classNameIconPassword={classNameIconPassword}
				handleClickShowPassword={handleClickShowPassword}
				iconPlace={iconPlace}
			/>
		);
	}

	return (
		<div
			className={clsx(styles.wrapperInput, stylesInputLine.wrapperInputLine, {
				[styles.medium]: size === 'medium',
				[styles.newSignUpForm]: REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
			})}
		>
			{label && showLabel()}
			{showPlaceholder && (
				<label
					className={clsx(
						styles.topLabel_focus,
						forgotPassword
							? stylesInputLine.forgotPasswordTopLabelLine_focus
							: stylesInputLine.topLabelLine_focus,
						{
							[stylesInputLine.newSignUpForm]:
								REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
						}
					)}
					htmlFor={name}
				>
					{label}
				</label>
			)}
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<input
					style={eyeIcon ? { paddingRight: '40px' } : {}}
					id={name}
					type={showPassword ? 'text' : type}
					inputMode={inputMode}
					name={name}
					maxLength={maxlength}
					className={clsx(
						styles.input,
						forgotPassword
							? stylesInputLine.forgotPasswordInputLine
							: stylesInputLine.inputLine,
						{
							[styles.filled_disabled]: value && disabled,
							[styles.absentValue_disabled]: !value,
							[stylesInputLine.input_active]: hasValue,
							[styles.error]: errorText,
							[styles.errorNewSignUpForm]:
								errorText && REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
							[stylesInputLine.error]:
								errorText && !REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
							[styles.newSignUpForm]: REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
							[stylesInputLine.newSignUpForm]:
								REACT_APP_WINPOT_SHOW_NEW_SIGN_UP,
						}
					)}
					disabled={disabled}
					autoComplete={autocomplete}
					value={value}
					placeholder={onFocus ? undefined : placeholder}
					onFocus={() => setOnFocus(true)}
					{...reg}
					onBlur={(e) => {
						setOnFocus(false);
						onBlur(e);
					}}
					data-testid={dataTestId}
				/>
				{eyeIcon}
			</div>
			<div
				className={clsx(styles.errorText, {
					[styles.hint_phoneNumber]:
						name === 'phoneNumber' || name === 'password',
					[styles.hint_with_error]: errorText,
				})}
			>
				{errorText}
			</div>
			{hint && <p className={clsx(styles.hint)}>{hint}</p>}
			{hints.length > 0 && (
				<div className={styles.hints__wrapper}>
					{hints.map((hint) => {
						return (
							<p
								className={clsx(styles.hint, styles.password, {
									[styles.isValid]: hint?.isValid,
									[styles.isNotValid]: !hint?.isValid && hasValue,
								})}
								key={hint.text}
							>
								{hint.text}
							</p>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default InputLineWithLabel;
