import React, { useEffect, useState, useRef } from 'react';
import { LiveChatWidget } from '@livechat/widget-react';
import { appData, useUserData } from '../../redux/selectors';
import { initializeSession } from '../../utils/session';
import clsx from 'clsx';
import { isFirefox } from 'react-device-detect';

import General from './General/General';
import { actions } from '../../redux/actions/commonActions';
import { beginUserSession } from '../../redux/actions/userSession';
import { loginSocialNetworkUser } from '../../utils/api/user';
import styles from './layout.module.scss';
import { trackingQuery } from '../../utils/trackingQueryHook';
import { useDispatch } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import {
	setCookieToDomains,
	isTestChatMode,
	setValueToBrowser,
} from '../../utils/consts';
import { setShowVerificationModalTime } from '../../utils/common';
import { ONE_MINUTE_IN_MILLISECONDS } from '../../utils/consts';
import { errorNotification } from '../../utils/notifications/notifications';
import getMessage from '../../utils/locale';

const Layout = () => {
	const dispatch = useDispatch();
	const { isOpenSupport, loading } = appData();
	const layout = useRef(null);
	const {
		id,
		language,
		firstName,
		lastName,
		email: customerEmail = '',
	} = useUserData();

	const customerName =
		firstName && lastName ? `${firstName + ' ' || ''}${lastName || ''}` : '';
	const [showSpinner, setShowSpinner] = useState(false);
	const [isSport, setIsSport] = useState(false);

	useEffect(() => {
		try {
			if (isOpenSupport) {
				dispatch({
					type: actions.ClickedOnSupport,
					payload: false,
				});
				if (isTestChatMode()) {
					const chat = window?.LiveChatWidget;
					chat && chat.call('maximize');
				} else {
					zE('messenger', 'open');
				}
			}
		} catch (e) {}
	}, [isOpenSupport]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		setValueToBrowser('fullUrl', window.location.href);
		trackingQuery(params);
		if (params.socialNetworkToken) {
			loginSocialNetworkUser(params.socialNetworkToken)
				.then(({ data: loginInfo }) => {
					initializeSession(loginInfo);
					setShowVerificationModalTime(ONE_MINUTE_IN_MILLISECONDS);
					dispatch(
						beginUserSession({
							...loginInfo,
							birthDate: loginInfo?.birthDate
								? new Date(loginInfo.birthDate)
								: null,
						})
					);
				})
				.catch((e) => {
					errorNotification(null, getMessage('es', 'user.not.found'));
				});
		}
		if (params['pre-release-user']) {
			setCookieToDomains('pre-release-user', 'true');
		}
		if (params['developer_mode']) {
			setCookieToDomains('developer_mode', params.developer_mode);
		}
		if (params['testChat']) {
			setCookieToDomains('testChat', params.testChat);
		}
	}, []);

	useEffect(() => {
		if (id && language) {
			// eslint-disable-next-line camelcase
			window._smartico_user_id = id;
			// eslint-disable-next-line camelcase
			window._smartico_language = language;
		} else {
			// eslint-disable-next-line camelcase
			window._smartico_user_id = null;
			// eslint-disable-next-line camelcase
			window._smartico_language = null;
		}
	}, [id, language]);

	const checkHeroPosition = () => {
		const sportBanner = document?.getElementById('banner');
		if (sportBanner) {
			if (sportBanner?.getBoundingClientRect().top <= window.innerHeight) {
				setIsSport(true);
			} else {
				setIsSport(false);
			}
		}
	};

	useEffect(() => {
		layout.current && layout.current.addEventListener('scroll', checkHeroPosition);

		return () => {
			layout.current &&
				layout.current.removeEventListener('scroll', checkHeroPosition);
		};
	}, [layout.current]);

	return (
		<>
			{isTestChatMode() && (
				<LiveChatWidget
					license="14860299"
					visibility="hidden"
					customerName={customerName || null}
					customerEmail={customerEmail || null}
					// onNewEvent={handleNewEvent}
				/>
			)}
			<div
				className={clsx(styles.layout_general, {
					[styles.afterSport]: isSport,
					[styles.isFirefox]: isFirefox,
				})}
				ref={layout}
			>
				<General setShowSpinner={setShowSpinner} />
			</div>
			<Spinner isShow={loading || showSpinner} />
		</>
	);
};

export default Layout;
