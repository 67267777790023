import React from 'react';

const WhatsappIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="WhatsApp 1">
				<path
					id="Vector"
					d="M2 13.9648L2.91835 10.6268C2.351 9.6476 2.053 8.53678 2.05437 7.40634C2.05586 3.85445 4.95932 0.964844 8.52723 0.964844C10.2586 0.96572 11.8837 1.63649 13.1059 2.85412C14.3282 4.07174 15.0006 5.69021 15 7.41151C14.9984 10.9631 12.0945 13.8533 8.52714 13.8533H8.52432C7.44112 13.8528 6.37673 13.5823 5.43129 13.0691L2 13.9648Z"
					fill="white"
				/>
				<path
					id="Vector_2"
					d="M8.50215 1.96486C5.46832 1.96486 3.00108 4.43107 3.00001 7.46242C2.99851 8.49754 3.29002 9.51195 3.84082 10.3884L3.97168 10.5964L3.41596 12.6251L5.49764 12.0793L5.69864 12.1984C6.54288 12.6994 7.51087 12.9643 8.49801 12.9648H8.50008C11.5316 12.9648 13.9988 10.4984 14 7.46683C14.0023 6.74428 13.8612 6.02845 13.5849 5.36078C13.3087 4.69311 12.9027 4.08688 12.3906 3.57717C11.8812 3.06467 11.2753 2.65828 10.6079 2.38152C9.94042 2.10477 9.2247 1.96315 8.50215 1.96486Z"
					fill="url(#paint0_linear_42701_288374)"
				/>
				<path
					id="Vector_3"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.77979 5.24355C6.6507 4.97852 6.51485 4.97316 6.39222 4.96857L6.06208 4.96484C5.94723 4.96484 5.76061 5.00467 5.60285 5.16398C5.44509 5.32329 5 5.70831 5 6.49144C5 7.27456 5.61718 8.03128 5.70318 8.1376C5.78919 8.24392 6.89465 9.90221 8.6453 10.5402C10.1001 11.0704 10.3961 10.965 10.712 10.9385C11.0279 10.912 11.731 10.5535 11.8745 10.1818C12.0179 9.81009 12.018 9.49164 11.975 9.42506C11.932 9.35848 11.8171 9.31891 11.6447 9.23926C11.4724 9.1596 10.6258 8.77458 10.4679 8.72142C10.31 8.66826 10.1953 8.64185 10.0803 8.80116C9.96539 8.96047 9.63572 9.31883 9.53519 9.42506C9.43467 9.5313 9.33434 9.54463 9.16196 9.46506C8.98958 9.38549 8.43498 9.21735 7.77695 8.6751C7.26497 8.25319 6.91938 7.73214 6.81876 7.57292C6.71815 7.4137 6.80808 7.32747 6.89446 7.24816C6.97175 7.17681 7.06665 7.06227 7.15293 6.96937C7.23921 6.87647 7.2676 6.81006 7.32493 6.704C7.38227 6.59793 7.35369 6.50477 7.3106 6.4252C7.2675 6.34564 6.93287 5.55836 6.77979 5.24355Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_42701_288374"
					x1="8.38802"
					y1="2.62496"
					x2="8.44377"
					y2="12.0251"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#57D163" />
					<stop offset="1" stopColor="#23B33A" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default WhatsappIcon;
