const detailsSportFirst = () => {
	return (
		<div>
			<h3>Ahora los empates 0:0 te garantizan ganancias con Winpot Sports</h3>
			<p>
				Comienza a ganar hoy mismo si haces una apuesta por cualquiera de estos
				mercados &quot;Marcador correcto, medio tiempo/tiempo completo,
				primer/último/en cualquier momento Goleador, marcador correcto de medio
				tiempo/tiempo completo&quot; y queda empatado 0:0, te reembolsamos tu
				dinero al 100%.
			</p>
			<p>En Winpot Sports ganas hasta con un empate.</p>
		</div>
	);
};

const detailsSportSecond = () => {
	return (
		<div>
			<h3>
				¡APROVECHA AL MÁXIMO TUS APUESTAS CON EL &quot;PAGO ANTICIPADO&quot; DE
				WINPOT SPORTS!
			</h3>
			<p>
				¿Conoces esa sensación donde tu apuesta está ganando, pero aún esperas a
				que termine el juego para cobrar? Bueno, ahora la espera ha terminado. Con
				nuestra función &quot;Pago Anticipado&quot;, puedes cobrar tus ganancias
				incluso mientras la acción continúa.
			</p>
			<p>
				El caso más clásico de un &quot;Pago Anticipado&quot; es para el mercado
				de fútbol &quot;1x2&quot; (&quot;Pago Anticipado con 2 Goles de
				Ventaja&quot;). Esto significa que si un equipo tiene una ventaja de dos
				goles en cualquier momento durante el partido, la selección de ese equipo
				en el mercado &quot;1x2&quot; antes del inicio del partido debería
				liquidarse como ganadora.
			</p>
			<p>¿Cómo funciona nuestro Pago Anticipado?</p>
			<ol>
				<li>
					Realiza tu apuesta: Coloca una apuesta en uno de los mercados
					elegibles para el &quot;Pago Anticipado&quot;. Todos los mercados
					elegibles están marcados con las letras “Pago Anticipado” en la
					esquina superior derecha.
				</li>
				<li>
					Sigue el desarrollo del partido y si en algún momento durante el
					partido tu equipo tiene ventaja.
				</li>
				<li>
					Si se cumple la condición para el &quot;Pago Anticipado&quot;, podrás
					cobrar tus ganancias en ese momento, incluso antes de que el partido
					haya terminado.{' '}
				</li>
			</ol>
			<p>
				<strong>Ejemplo:</strong>
			</p>
			<p>
				<em>
					Evento: Juventus vs. Inter. Se ofrece el pago anticipado. Las apuestas
					se realizan en el mercado &quot;1x2&quot;. En algún momento durante el
					juego, Juventus lleva la delantera 2-0.
				</em>
			</p>
			<p>
				<em>
					Esto significa que las selecciones de &quot;1&quot; en el mercado
					&quot;1x2&quot; deberían liquidarse como ganadoras para todas las
					apuestas antes del inicio del partido.
				</em>
			</p>
			<p>
				<em>Si el resultado final es 2-2, entonces:</em>
			</p>
			<ul>
				<li>
					<em>
						Las selecciones de &quot;1&quot; antes del inicio del partido se
						liquidan como ganadoras.
					</em>
				</li>
				<li>
					<em>
						Las selecciones de &quot;1&quot; durante el juego (en vivo) se
						liquidan como perdedoras.
					</em>
				</li>
				<li>
					<em>Las selecciones de &quot;X&quot; se liquidan como ganadoras.</em>
				</li>
				<li>
					<em>Las selecciones de &quot;2&quot; se liquidan como perdedoras.</em>
				</li>
			</ul>
		</div>
	);
};

const termsSportFirst = () => {
	return (
		<div>
			<p>
				1. Esta es una promoción de WINPOT solo válida para jugadas realizadas a
				través de Winpot.mx y para usuarios registrados en Winpot.mx, mayores de
				18 años, residentes en México exclusivamente.
			</p>
			<p>
				2. Promoción válida desde 1 de marzo de 2023 hasta el 31 de diciembre de
				2023 a las 23:59 hrs (horario de la Ciudad de México).
			</p>
			<p>
				3. PARTICIPACIÓN: El usuario debe hacer una apuesta antes del comienzo del
				partido, en los tipos de mercado de la tabla siguiente y, si el partido
				termina en empate 0-0, su apuesta será reembolsada en su totalidad.
			</p>
			<p>
				4. Los reembolsos se abonarán lo antes posible una vez finalizado el
				partido. Si, mientras tanto, el jugador interfiere con la apuesta de
				alguna manera (por ejemplo, retiro/reintegro parcial), la apuesta ya no se
				aplicará a la oferta Bore Draw.
			</p>
			<p>
				5. La oferta es válida para apuestas simples, múltiples, de sistema y Bet
				Builder realizadas ÚNICAMENTE antes del inicio del partido y no durante el
				mismo.
			</p>
			<p>
				6. Mercados incluidos: Marcador correcto, medio tiempo/tiempo completo,
				primer/último/en cualquier momento Goleador, marcador correcto de medio
				tiempo/tiempo completo
			</p>
			<p>7. Ligas incluidas: </p>
			<p>
				Solo ligas seleccionadas y donde se muestre el símbolo DE (Devolución
				Empate)
			</p>
			<p>México: Liga Mx, Liga Expansión Mx, Liga Mx Femenil</p>
			<p>
				Inglaterra: Premier League, Championship, FA CUP, EFL CUP, Community
				Shield, Carabao CUP
			</p>
			<p>España: La Liga, España Segunda División, Copa Del Rey, Supercopa&nbsp;</p>
			<p>Alemania: Bundesliga, DFB Pokal Copa&nbsp;</p>
			<p>Italia: Serie A, Copa de Italia, Italia Supercopa</p>
			<p>Francia: Liga 1, Francia Liga 2, Copa de Francia&nbsp;&nbsp;</p>
			<p>Portugal: Primera Liga, Copa Portugal&nbsp;</p>
			<p>Holanda: Eredivisie, Copa Holanda KNVB</p>
			<p>Estados Unidos: MLS, MLS Cup</p>
			<p>
				Argentina: Primera División; Brasil: Serie A; Colombia: Primera A;
				Bélgica: Primera División A; Perú: Primera División; Turquía: Super liga;
				Uruguay: Primera división; Australia: A League; China: Super League;
			</p>
			<p>
				Otras: Champions League, Europa league, Conference League, Copa
				Libertadores, Liga Campeones CONCACAF, CONCACAF League, CONCACAF Nations
				League, Recopa Sudamericana, Copa Sudamericana, Copa del Mundo, Copa del
				Mundo Clasificatorios (Asia, África, Europa, Oceanía, South América),
				Eurocopa Clasificatorios al Campeonato, Euro Championship, Copa América,
				Mundiales de Clubes, UEFA Nations League, UEFA Super Cup, Eurocopa Mujere,
				FIFA Copa Confederaciones, Copa del Mundo de Mujeres.
			</p>
			<p>
				8. WINPOT Casino se reserva el derecho de eliminar dichas ganancias del
				saldo del jugador en circunstancias especiales a discreción de la
				gerencia.
			</p>
			<p>
				9. Aquellos jugadores que actualmente tengan retiros pendientes en su
				cuenta no recibirán ningún bono, promoción o premio relacionado. El uso
				indebido de esta condición tal como está definida en el casino, puede
				causar la cancelación de las ganancias del jugador.
			</p>
			<p>
				10. WINPOT Casino se reserva el derecho de revisar los registros de
				transacciones en cualquier momento y por cualquier motivo. Si en una de
				estas revisiones, el (los) jugador(es) se encuentra (n) involucrado (s) en
				estrategias que el casino, a su sola discreción, considera abusivas, el
				casino se reserva el derecho de anular el privilegio de dicho jugador a
				utilizar la promoción.
			</p>
			<p>
				11. WINPOT Casino se reserva el derecho de modificar y/o cancelar esta
				promoción en cualquier momento.
			</p>
			<p>
				12. En caso de que haya algún problema, las decisiones que tome el casino
				serán decisivas.
			</p>
			<p>
				13.&nbsp; Si se retira el monto del depósito original antes de cumplir los
				requisitos de juego, el dinero de la bonificación más cualquier ganancia
				asociada se cancelarán.&nbsp;
			</p>
			<p>
				14. El jugador solo puede participar en las promociones si su transacción
				con nuestros servicios fue un depósito. • Promoción personal e
				intransferible.
			</p>
			<p>
				15. Las promociones, retiros cancelados y bonos no contarán como
				depósitos.
			</p>
			<p>
				16. Esta promoción no aplica en conjunto con otras, en caso de que se
				indique lo contrario.
			</p>
			<p>
				17.&nbsp; El jugador acepta y concibe los términos aplicables a cada bono,
				winpot.mx se reserva el derecho de anular, previa notificación de 15 días
				de antelación a la Dirección de Juegos y Sorteos, cualquier bono, oferta
				especial o promoción en cualquier momento. Dando aviso a los clientes y
				público en general de la cancelación a través de los medios de
				comunicación.
			</p>
			<p>
				18. Lo no previsto por las presentes reglas, se resolverá conforme a lo
				dispuesto por la ley federal de juegos y sorteos, su reglamento y el
				reglamento de operación del establecimiento, sus ampliaciones y
				modificaciones autorizadas por la Dirección General de Juegos y Sorteos de
				la Secretaría de Gobernación.
			</p>
			<p>
				19. Publicidad dirigida a mayores de edad (+18). Prohibidas las apuestas y
				el acceso a menores de edad. Juegue de manera responsable, sana e
				informada con el propósito de entretenimiento, diversión y esparcimiento.{' '}
			</p>
			<p>
				20. La ludopatía es una enfermedad que puede afectar el desarrollo de la
				persona y familia. Ayuda llame a CONACID: 800 911 2000.
			</p>
			<p>
				21. Todos los Términos y Condiciones del Bono están sujetos en todo
				momento a los Términos y Condiciones de WINPOT.MX Casino.
			</p>
			<p>
				El dominio winpot.mx es explotado por la empresa&quot;, Winpot Online
				América S.A. de C.V.&quot;, operadora de &quot;PUR UMAZAL TOV,S.A. de
				C.V.&quot; bajo el amparo del permiso DGJS/DGAFJ/DCRCA/p-08/2014, con
				autorización de la Dirección General de Juegos y Sorteos DGJS/393/2018
				DCJS/1130/2022 DGJS/1681/2018 y DGJS/1807/2023 Promoción validada en
				oficio de autorización DGJS/DGAFJ/DCRCA/P-08/2014.Juegue responsablemente.
				Juegos prohibidos para menores de dad. Oficios No.DGJS-4819/2022,&nbsp;
				DGJS-1568/2022 DGJS-0913-2023 &quot;TORNEOS&quot; Válido MARZO 2024.
				Juegue responsablemente. Juegos prohibidos para menores de edad.
			</p>
		</div>
	);
};

const termsSportSecond = () => {
	return (
		<div>
			<p>
				1. Esta es una promoción de WINPOT solo válida para jugadas realizadas a
				través de Winpot.mx y para usuarios registrados en Winpot.mx, mayores de
				18 años, residentes en México exclusivamente.
			</p>
			<p>
				2. Promoción válida desde 1 de marzo de 2023 hasta el 31 de diciembre de
				2024 a las 23:59 hrs (horario de la Ciudad de México).
			</p>
			<p>
				3. Con el &quot;Pago Anticipado&quot;, algunas selecciones del mercado de
				apuestas de un partido se deciden (y probablemente se liquidan) antes de
				que se determine el resultado final del mercado en realidad.
			</p>
			<p>4. Este concepto se aplica a siguente deportes y mercados: </p>
			<ul>
				<li>Fútbol: Tipo de Mercado: 1x2, Parámetro: 2 Goles </li>
				<li>
					Fútbol Americano: Tipo de Mercado: Ganador (incluye tiempo extra),
					Parámetro: 17 Puntos
				</li>
				<li>
					Baloncesto: Tipo de Mercado: Ganador (incluye tiempo extra),
					Parámetro: 18 Puntos
				</li>
				<li>Tenis: Tipo de Mercado: Ganador, Parámetro: 2 Sets </li>
				<li>
					Béisbol: Tipo de Mercado: Ganador (incluye entradas extras),
					Parámetro: 5 Carreras
				</li>
				<li>
					Hockey sobre Hielo: Tipo de Mercado: Ganador (incluye tiempo extra y
					penales), Parámetro: 3 Goles
				</li>
			</ul>
			<p>
				5. La oferta es válida únicamente para apuestas simples, combinadas, de
				sistema y del Crear apuesta ANTES del inicio del partido y no durante el
				partido.
			</p>
			<p>
				6. La oferta no se aplica a las apuestas realizadas en la selección de
				Empate en cada partido. Tu apuesta se pagará en su totalidad si tu equipo
				tiene la ventaja correspondiente en el partido, independientemente del
				resultado final.
			</p>
			<p>
				7. Esta oferta no se aplicará a las apuestas en las que se haya utilizado
				el Cash Out. En las apuestas múltiples, la concesión de la oferta para una
				selección o selecciones específicas de la apuesta no se verá afectada
				retrospectivamente si el cliente realiza el cobro anticipado de las
				apuestas de las selecciones restantes.
			</p>
			<p>
				8. Todas las ganancias se acreditarán lo antes posible una vez que el
				equipo tenga la ventaja correspondiente.
			</p>
			<p>
				9. Esta oferta no se aplicará cuando una apuesta haya sido cobrada
				completamente. Cuando una apuesta haya sido cobrada parcialmente y tu
				equipo avance como se requiere, la apuesta se resolverá sobre el monto
				activo restante. Si tu apuesta se paga anticipadamente de acuerdo con esta
				oferta, no se pagará nuevamente si tu selección sigue adelante y gana el
				partido.
			</p>
			<p>
				10. WINPOT Casino se reserva el derecho de eliminar dichas ganancias del
				saldo del jugador en circunstancias especiales a discreción de la
				gerencia.
			</p>
			<p>
				11. Aquellos jugadores que actualmente tengan retiros pendientes en su
				cuenta no recibirán ningún bono, promoción o premio relacionado. El uso
				indebido de esta condición tal como está definida en el casino, puede
				causar la cancelación de las ganancias del jugador.
			</p>
			<p>
				12. WINPOT Casino se reserva el derecho de revisar los registros de
				transacciones en cualquier momento y por cualquier motivo. Si en una de
				estas revisiones, el (los) jugador(es) se encuentra (n) involucrado (s) en
				estrategias que el casino, a su sola discreción, considera abusivas, el
				casino se reserva el derecho de anular el privilegio de dicho jugador a
				utilizar la promoción.
			</p>
			<p>
				13 WINPOT Casino se reserva el derecho de modificar y/o cancelar esta
				promoción en cualquier momento.
			</p>
			<p>
				14. En caso de que haya algún problema, las decisiones que tome el casino
				serán decisivas.
			</p>
			<p>
				15.&nbsp;Si se retira el monto del depósito original antes de cumplir los
				requisitos de juego, el dinero de la bonificación más cualquier ganancia
				asociada se cancelarán.&nbsp;
			</p>
			<p>
				16. El jugador solo puede participar en las promociones si su transacción
				con nuestros servicios fue un depósito.
			</p>
			<p>
				17. Las promociones, retiros cancelados y bonos no contarán como
				depósitos.
			</p>
			<p>
				18. Esta promoción no aplica en conjunto con otras, en caso de que se
				indique lo contrario.
			</p>
			<p>
				19.&nbsp; El jugador acepta y concibe los términos aplicables a cada bono,
				winpot.mx se reserva el derecho de anular, previa notificación de 15 días
				de antelación a la Dirección de Juegos y Sorteos, cualquier bono, oferta
				especial o promoción en cualquier momento. Dando aviso a los clientes y
				público en general de la cancelación a través de los medios de
				comunicación.
			</p>
			<p>
				20. Lo no previsto por las presentes reglas, se resolverá conforme a lo
				dispuesto por la ley federal de juegos y sorteos, su reglamento y el
				reglamento de operación del establecimiento, sus ampliaciones y
				modificaciones autorizadas por la Dirección General de Juegos y Sorteos de
				la Secretaría de Gobernación.{' '}
			</p>
			<p>
				21. Publicidad dirigida a mayores de edad (+18). Prohibidas las apuestas y
				el acceso a menores de edad. Juegue de manera responsable, sana e
				informada con el propósito de entretenimiento, diversión y esparcimiento.{' '}
			</p>
			<p>
				22. Todos los Términos y Condiciones del Bono están sujetos en todo
				momento a los Términos y Condiciones de WINPOT.MX Casino.
			</p>
			<p>
				El dominio winpot.mx es explotado por la empresa&quot;, Winpot Online
				América S.A. de C.V.&quot;, operadora de &quot;PUR UMAZAL TOV,S.A. de
				C.V.&quot; bajo el amparo del permiso DGJS/DGAFJ/DCRCA/p-08/2014, con
				autorización de la Dirección General de Juegos y Sorteos DGJS/393/2018
				DCJS/1130/2022 DGJS/1681/2018 y DGJS/1807/2023 Promoción validada en
				oficio de autorización DGJS/DGAFJ/DCRCA/P-08/2014.Juegue responsablemente.
				Juegos prohibidos para menores de dad. Oficios No.DGJS-4819/2022,&nbsp;
				DGJS-1568/2022 DGJS-0913-2023 &quot;TORNEOS&quot; Válido MARZO 2024.
				Juegue responsablemente. Juegos prohibidos para menores de edad.
			</p>
		</div>
	);
};

export { detailsSportFirst, detailsSportSecond, termsSportFirst, termsSportSecond };
