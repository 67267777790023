import React from 'react';
import { getCurrentColor } from '../../../utils/consts';

const Virtuales = ({ color }) => {
	const currentColor = getCurrentColor(color);

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 0H8.64178L17 15.8928L12.4668 24L0 0Z" fill={currentColor} />
			<path d="M13 0H23L17.9117 9V3.04426L13 0Z" fill={currentColor} />
		</svg>
	);
};

export default Virtuales;
