import React from 'react';
import clsx from 'clsx';
import styles from '../../SimpleInput/simpleInput.module.scss';
import VisibleIcon from '../forInputs/VisibleIcon';
import InvisibleIcon from '../forInputs/InvisibleIcon';

const IconPassword = ({
	showPassword,
	handleClickShowPassword,
	classNameIconPassword,
	iconPlace,
}) => {
	let color;
	switch (iconPlace) {
		case 'header':
			color = '#bdbdbd';
			break;
		case 'login':
			color = '#9CA4BF';
			break;
		default:
			color = '#898996';
			break;
	}
	return (
		<div
			className={clsx(styles.wrapperIconEnd, {
				[styles.isSignUp]: iconPlace === 'loginFB',
			})}
			onClick={handleClickShowPassword}
		>
			{showPassword ? (
				<VisibleIcon color={color} />
			) : (
				<InvisibleIcon color={color} />
			)}
		</div>
	);
};

export default IconPassword;
