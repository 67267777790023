import countryTelData from 'country-telephone-data';
import cloneDeep from 'lodash/cloneDeep';

const phoneCodes = countryTelData.allCountries
	.map((country) => {
		const format = country.format?.slice(country.dialCode.length + 2);
		return {
			value: country.dialCode,
			text: `+${country.dialCode}`,
			info: format,
			codeFlag: country.iso2,
			name: country.name,
		};
	})
	.sort((a, b) => a.value - b.value)
	// .filter((country, index, arr) => arr[index - 1]?.value !== country.value)
	.map((country, ind) => ({ ...country, ind }));

const phoneCodesSort = cloneDeep(phoneCodes);
phoneCodesSort.sort((a, b) => {
	if (a.name < b.name) {
		return -1;
	} else if (a.name > b.name) {
		return 1;
	}
	return 0;
});

const DEFAULT_COUNTRY_INDEX = 141; // Mexico

export { phoneCodes, phoneCodesSort, DEFAULT_COUNTRY_INDEX };
