import { useState } from 'react';
import getMessage from '../locale';

function usePasswordHints() {
	const [hintsPassword, setHintsPassword] = useState({
		hintPasswordMinMax: {
			isValid: false,
			text: getMessage('es', 'hintPasswordMinMax'),
			name: 'hintPasswordMinMax',
		},
		hintPasswordSpaces: {
			isValid: true,
			text: getMessage('es', 'hintPasswordSpaces'),
			name: 'hintPasswordSpaces',
		},
		hintPasswordNumber: {
			isValid: false,
			text: getMessage('es', 'hintPasswordNumber'),
			name: 'hintPasswordNumber',
		},
		hintPasswordUppercase: {
			isValid: false,
			text: getMessage('es', 'hintPasswordUppercase'),
			name: 'hintPasswordUppercase',
		},
		hintPasswordLowercase: {
			isValid: false,
			text: getMessage('es', 'hintPasswordLowercase'),
			name: 'hintPasswordLowercase',
		},
	});

	return { hintsPassword, setHintsPassword };
}

export default usePasswordHints;
