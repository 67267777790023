import React from 'react';

const MailIcon = () => {
	return (
		<svg
			width="38"
			height="30"
			viewBox="0 0 38 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="Subtract"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.666 0.333984H4.33268C2.6492 0.333984 1.22123 1.48381 0.795343 3.03691L18.9995 15.7798L37.2034 3.0371C36.7776 1.48391 35.3496 0.333984 33.666 0.333984ZM37.3327 5.38792L19.573 17.8197C19.2287 18.0607 18.7704 18.0607 18.426 17.8197L0.666016 5.3877V26.0007C0.666016 28.0173 2.31602 29.6673 4.33268 29.6673H33.666C35.6827 29.6673 37.3327 28.0173 37.3327 26.0007V5.38792Z"
				fill="url(#paint0_linear_34873_259933)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_34873_259933"
					x1="18.9993"
					y1="0.333984"
					x2="18.9993"
					y2="29.6673"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#505E77" />
					<stop offset="1" stopColor="#191F2B" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default MailIcon;
