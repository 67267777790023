import translations from '../translations/translation';

const getMessage = (locale, key, params = {}) => {
	let translation;

	if (locale && typeof locale === 'object') {
		translation = locale[key] ? locale[key] : translations['en'][key];
	} else {
		const translationExists = translations[locale] && translations[locale][key];
		translation = translationExists
			? translations[locale][key]
			: translations['en'][key];
	}

	return translation?.replace(/\{(\w+)\}/g, (_, name) => params[name] || '');
};

export default getMessage;
