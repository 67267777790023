import getMessage from '../locale';

const onInvalid = (event) => {
	event.target.setCustomValidity(getMessage('es', 'verification.input.number.invalid'));
};

const onPaste = (event, setValue) => {
	const { clipboardData } = event;
	const pastedText = clipboardData.getData('text').replace(/[-*+ ]/g, '');

	event.preventDefault();

	if (pastedText.match(/^([0-9]*)$/)) {
		setValue('phoneNumber', pastedText);
	}
};

export { onInvalid, onPaste };
