import getMessage from '../../../utils/locale';

import casinoImageFirst from '../../../images/promo/promo_1.jpg';
import casinoImageFirstSlider from '../../../images/promo/promo_1_slider.png';
import sportImageFirst from '../../../images/promo/promo_2.jpg';
import sportImageFirstSlider from '../../../images/promo/promo_2_slider.jpg';
import sportImageSecond from '../../../images/promo/promo_3.jpg';
import sportImageSecondSlider from '../../../images/promo/promo_3_slider.png';

import { detailsCasinoFirst, termsCasinoFirst } from './promoCasinoText';

import {
	detailsSportFirst,
	detailsSportSecond,
	termsSportFirst,
	termsSportSecond,
} from './promoSportText';

const casinoTitleFirst = getMessage('es', 'promoCasinoTitleFirst');
const sportTitleFirst = getMessage('es', 'promoSportTitleFirst');
const sportTitleSecond = getMessage('es', 'promoSportTitleSecond');

const slides = [
	{
		src: casinoImageFirstSlider,
		popupData: {
			popupTitle: casinoTitleFirst,
			popupImage: casinoImageFirst,
			detailsText: detailsCasinoFirst(),
			termsText: termsCasinoFirst(),
		},
	},
	{
		src: sportImageFirstSlider,
		popupData: {
			popupTitle: sportTitleFirst,
			popupImage: sportImageFirst,
			detailsText: detailsSportFirst(),
			termsText: termsSportFirst(),
		},
	},
	{
		src: sportImageSecondSlider,
		popupData: {
			popupTitle: sportTitleSecond,
			popupImage: sportImageSecond,
			detailsText: detailsSportSecond(),
			termsText: termsSportSecond(),
		},
	},
];

export default slides;
