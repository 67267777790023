const translationLoginInHeader = {
	en: {
		loginFormFieldUsernameEmail: 'Username or Email',
		loginFormFieldPassword: 'Password',
		loginFormFieldRememberMe: 'Remember Me',
		loginFormForgotPassword: 'Forgot your password?',
		headerNavbarButtonLogIn: 'Login',
		headerNavbarButtonSignUp: 'Signup',
		headerNavbarSupportIcon: 'support icon',
		headerNavbarPromoIcon: 'promo Games',
	},
	es: {
		fieldUsernameEmail: 'Usuario o Correo Electrónico',
		loginFormFieldPassword: 'Contraseña',
		loginFormFieldRememberMe: 'Recuérdame',
		loginFormForgotPassword: 'Forgot your password?',
		headerNavbarButtonLogIn: 'Acceder',
		headerNavbarButtonSignUp: 'Regístrate',
		headerNavbarSupportIcon: 'icono de apoyo',
		headerNavbarPromoIcon: 'promo Juegos',
	},
	ru: {
		loginFormFieldUsernameEmail: 'Никнейм/Емаил',
	},
};

export default translationLoginInHeader;
