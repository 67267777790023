import React, { useEffect, useState } from 'react';
import styles from './registrationForm.module.scss';
import getMessage from '../../utils/locale';
import clsx from 'clsx';
import Button from '../../UI/Button/Button';
import SimpleInput from '../../UI/SimpleInput/SimpleInput';
import CheckBox from '../../UI/CheckBox/CheckBox';
import { useForm } from 'react-hook-form';
import { actions } from '../../redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import { useModal, useUserData } from '../../redux/selectors';
import { PopupType } from '../Modals/SmallModal/SmallModal';
import { resetErrorServerMessage } from '../../redux/actions/userSession';
import { checkIfPreRelease, REACT_APP_WINPOT_SHOW_FB_BUTTON } from '../../utils/consts';
import IconFacebook from '../../UI/icons/social/Facebook';
import { handleFBRegistration, isValidEmail } from '../../utils/common';
import { getTrackingValues } from '../../utils/trackingQueryHook';
import { getSignUpBanner } from '../../utils/common';

const RegistrationForm = ({ inModal = false, isFlying, isLoginPromoPopUp = false }) => {
	const dispatch = useDispatch();
	const userDataRedux = useUserData();
	const { historyPopupType = [] } = useModal();

	const [rememberUser, setRememberUSer] = useState({ remember: false });
	const [isSomeErrorFields, setIsSomeErrorFields] = useState(false);
	const [filled, setFilled] = useState(false);
	const [lockLoginBtn, setLockLoginBtn] = useState(false);

	const isDisabledButtonSubmit =
		(inModal && !filled) || lockLoginBtn || isSomeErrorFields;

	const { register, handleSubmit, formState, watch } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const formFields = watch();
	const { errors, dirtyFields } = formState;
	const { email, password } = dirtyFields;

	useEffect(() => {
		setIsSomeErrorFields(!!Object.keys(errors).length);
	}, [errors.email, errors.password]);

	useEffect(() => {
		setFilled(Object.keys(dirtyFields).length === 2);
	}, [email, password]);

	const openForgotPasswordModal = () =>
		dispatch({
			type: actions.OpenPopUp,
			payload: {
				category: PopupType.ForgotPassword,
			},
		});

	const onSubmit = async (data) => {
		dispatch(resetErrorServerMessage());
		setLockLoginBtn(true);
		setTimeout(() => {
			setLockLoginBtn(false);
		}, 2000);
		const { email, password } = data;

		const requestData = isValidEmail(email)
			? {
					email: email.trim(),
					password,
					remember: rememberUser.remember,
					affiliateInformation: getTrackingValues(''),
			  }
			: {
					nickName: email.trim(),
					password,
					remember: rememberUser.remember,
					affiliateInformation: getTrackingValues(''),
			  };

		localStorage.setItem('loginRequest', 'true');

		dispatch({
			type: actions.Login,
			payload: {
				requestData,
				t: getMessage,
			},
		});
	};

	useEffect(() => {
		dispatch(resetErrorServerMessage());
		localStorage.removeItem('loginRequest');
	}, []);

	useEffect(() => {
		const failedLoginAttempts = sessionStorage.getItem('failedLoginAttempts');
		if (failedLoginAttempts === '2') {
			openForgotPasswordModal();
			dispatch(resetErrorServerMessage());
		}
	}, [userDataRedux.errorServerMessage]);

	return (
		<div className={!inModal ? styles.wrapperFormBlock : ''}>
			<div
				className={clsx(styles.wrapperForm, 'flexColumn', {
					[styles.wrapperFormModal]: inModal,
					[styles.fly]: isFlying,
					[styles.inModal]: inModal,
				})}
			>
				<div className={styles.header}>
					{isLoginPromoPopUp ? (
						<img
							className={styles.loginFormImage}
							src={getSignUpBanner()}
							alt="login form image"
						/>
					) : (
						<p>
							{isFlying
								? getMessage('es', 'signUp.form.button.open.account')
								: getMessage('es', 'loginFormTitle')}
						</p>
					)}
				</div>
				<div
					className={clsx(styles.wrpBtn, 'flexColumn')}
					style={{ width: '100%' }}
				>
					{(isLoginPromoPopUp || isFlying) && (
						<Button
							text={getMessage('es', 'loginFormButtonSignUp')}
							classes={'btnPrimaryGreen'}
							onClick={() => {
								dispatch({
									type: actions.OpenPopUp,
									payload: {
										category: PopupType.SignUp,
										historyPopupType: [
											...historyPopupType,
											PopupType.SignUp,
										],
									},
								});
							}}
						/>
					)}
					{REACT_APP_WINPOT_SHOW_FB_BUTTON && (
						<div
							className={clsx(styles.wrapperBtnFC, {
								[styles.containImage]: isLoginPromoPopUp || isFlying,
							})}
						>
							<Button
								text={getMessage('es', 'loginFormButtonFacebook')}
								classes={'btnPrimaryBlue'}
								iconStart={<IconFacebook />}
								onClick={handleFBRegistration}
								disabled={checkIfPreRelease()}
							/>
						</div>
					)}
				</div>

				{(isLoginPromoPopUp || isFlying) && (
					<div
						className={clsx(styles.borderDiv, {
							[styles.borderDivModal]: inModal,
						})}
					>
						<p className={styles.borderSpan}>
							<span className={styles.borderSpanSign}>
								{getMessage(
									'es',
									inModal
										? 'login.form.or'
										: 'login.form.or.has.account'
								)}
							</span>
						</p>
					</div>
				)}
				<form
					className={clsx(styles.form, 'flexColumn', {
						[styles.inModal]: inModal,
					})}
					onSubmit={handleSubmit(onSubmit)}
					autoComplete="off"
				>
					<label className={styles.loginLabel} htmlFor="email">
						{getMessage('es', 'fieldUsernameEmail')}
					</label>
					<SimpleInput
						reg={register('email')}
						name="email"
						errorText={errors.email?.message}
						value={formFields.email}
						classNameError={styles.errorInRegForm}
						dataTestId={'email-input'}
					/>
					<label className={styles.loginLabel} htmlFor="password">
						{getMessage('es', 'fieldPassword')}
					</label>
					<SimpleInput
						reg={register('password')}
						type="password"
						name="password"
						iconPlace="login"
						classNameIconPassword={styles.iconPassword}
						errorText={errors.password?.message}
						value={formFields.password}
						classNameError={styles.errorInRegForm}
						dataTestId={'password-input'}
					/>
					<div className={styles.remember__bar}>
						<CheckBox
							name="remember"
							label={getMessage('es', 'loginFormFieldRememberMe')}
							setSelectedValue={setRememberUSer}
							checked={rememberUser.remember}
							className={styles.remember__checkbox}
							checkedColor="secondary"
							checkedClassName={styles.checkedCheckBox}
							labelClassName={styles.checkBoxLabel}
							modal={inModal ? 'login' : ''}
							dataTestId={'remember-checkbox'}
						/>
						<p
							className={styles.forgotPassword}
							onClick={openForgotPasswordModal}
							data-testid="forgot-password"
						>
							{getMessage('es', 'loginFormForgotPassword')}
						</p>
					</div>
					{userDataRedux?.errorServerMessage && (
						<div className={styles.errorText}>
							{userDataRedux?.errorServerMessage}
						</div>
					)}
					<Button
						text={getMessage('es', 'loginFormButtonSubmit')}
						classes={'btnPrimaryBlack'}
						type={'submit'}
						disabled={isDisabledButtonSubmit}
						dataTestId={'submit-button'}
					/>
				</form>
				<div
					className={clsx(styles.hints, {
						[styles.inModal]: inModal,
					})}
				>
					{inModal && (
						<p className={styles.noAccount}>
							{getMessage('es', 'loginFormNoAccount')}
							<span
								onClick={() => {
									dispatch({
										type: actions.OpenPopUp,
										payload: {
											category: PopupType.SignUp,
											historyPopupType: [
												...historyPopupType,
												PopupType.SignUp,
											],
										},
									});
								}}
								data-testid="create-account"
							>
								{getMessage('es', 'loginFormButtonCreate')}
							</span>
						</p>
					)}
					{inModal && <div className={clsx(styles.divider, styles.inModal)} />}
					<p
						className={clsx(styles.conditions, {
							[styles.inModal]: inModal,
						})}
						dangerouslySetInnerHTML={{
							__html: getMessage('es', 'formMessageConditions'),
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default RegistrationForm;
