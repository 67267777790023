import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './sidemenu.module.scss';
import { IconColors } from './Menu';
import getMessage from '../../utils/locale';
import { actions } from '../../redux/actions/commonActions';
import { clickedOnLink } from '../../utils/common';

const SideMenuLocalItem = (props) => {
	const { link, Icon, label, setShowSpinner } = props;

	const isSupportItem = label === 'Support';
	const isAppLink = label === 'App';
	const dispatch = useDispatch();
	const handleClickOnLink = (isSupportItem) => {
		if (isSupportItem) {
			dispatch({
				type: actions.ClickedOnSupport,
				payload: true,
			});
		} else {
			clickedOnLink(link, dispatch, () => {
				if (!isAppLink) {
					setShowSpinner(true);
				}
			});
		}
	};

	const setIcon = () => {
		return <Icon color={IconColors.Primary} />;
	};

	return (
		<div className={styles.navLinks__link}>
			<div
				className={styles.navLinks__title}
				onClick={() => handleClickOnLink(isSupportItem)}
			>
				{setIcon()}
				<div>{getMessage('es', `Navigation${label}`)}</div>
			</div>
		</div>
	);
};

export default SideMenuLocalItem;
