import React from 'react';

const Winclub = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9992 3H12.0005C12.7553 3 13.369 3.61226 13.369 4.36842C13.369 4.70661 13.2468 5.01653 13.0436 5.25701H13.0449L16.6109 9.79249C16.8591 10.1076 17.2474 10.303 17.6782 10.303C17.8981 10.303 18.1065 10.2503 18.2903 10.1577L21.2686 8.74443H21.2712C21.2661 8.69556 21.2635 8.64541 21.2635 8.59526C21.2635 7.83913 21.8756 7.22701 22.6318 7.22701C23.3879 7.22701 24 7.8391 24 8.59526C24 9.25752 23.5306 9.81048 22.9056 9.93779L21.6261 18.748C21.6068 18.9075 21.5155 19.0476 21.3844 19.1287V19.1299C19.3127 20.3773 15.6861 21.073 12.0006 21.073C8.32143 21.073 4.70148 20.3799 2.62978 19.1376C2.49089 19.0553 2.39573 18.9152 2.37516 18.7493L1.09435 9.93773H1.09692C0.470667 9.81171 0 9.25874 0 8.59519C0 7.83907 0.612094 7.22694 1.36825 7.22694C2.1244 7.22694 2.73782 7.83904 2.73782 8.59519C2.73782 8.64534 2.73524 8.6955 2.72881 8.74565L2.73139 8.74436L5.75469 10.1795C5.92703 10.2592 6.11863 10.303 6.32181 10.303C6.76805 10.303 7.16284 10.0869 7.40974 9.75513L10.9563 5.25685L10.9576 5.25556C10.7544 5.01639 10.6309 4.70644 10.6309 4.36825C10.6309 3.61341 11.243 3 11.9979 3H11.9992ZM12.013 15.3904C13.0527 15.3904 13.8956 14.2104 13.8956 12.7548C13.8956 11.2992 13.0527 10.1191 12.013 10.1191C10.9733 10.1191 10.1304 11.2992 10.1304 12.7548C10.1304 14.2104 10.9733 15.3904 12.013 15.3904Z"
				fill="white"
			/>
		</svg>
	);
};

export default Winclub;
