import React from 'react';

const Warn = ({ color }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.2448 3.52422C12.1518 3.36318 11.9195 3.36333 11.8267 3.52414L11.8266 3.52419L2.20984 20.1809L2.20983 20.1809C2.11687 20.342 2.23314 20.5431 2.41889 20.5431H21.6524C21.8382 20.5431 21.9544 20.342 21.8615 20.1809L12.2448 3.52422ZM10.5722 2.80003C11.2226 1.67336 12.8487 1.67352 13.4991 2.79996L13.4991 2.79999L23.1158 19.4567C23.7663 20.5833 22.9533 21.9915 21.6524 21.9915H2.41889C1.11799 21.9915 0.30512 20.5833 0.955452 19.4567L0.955469 19.4567L10.5722 2.80003ZM11.9787 9.00057C11.4519 9.00057 11.0407 9.28325 11.0407 9.78439C11.0407 10.5489 11.0857 11.4804 11.1307 12.4119L11.1307 12.412L11.1307 12.4121C11.1756 13.3436 11.2206 14.2751 11.2206 15.0396C11.2206 15.438 11.5676 15.605 11.9787 15.605C12.2871 15.605 12.724 15.438 12.724 15.0396C12.724 14.2751 12.769 13.3436 12.8139 12.4121V12.4121C12.8589 11.4805 12.9038 10.5489 12.9038 9.78439C12.9038 9.28329 12.4798 9.00057 11.9787 9.00057ZM11.0036 17.4812C11.0036 16.9415 11.4277 16.4918 11.993 16.4918C12.5198 16.4918 12.9696 16.9415 12.9696 17.4812C12.9696 18.008 12.5198 18.4706 11.993 18.4706C11.4276 18.4706 11.0036 18.008 11.0036 17.4812Z"
			fill={color}
		/>
	</svg>
);

export default Warn;
