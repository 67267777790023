import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../saga/index';

import { appConfigurationReducer } from './reducers/appConfigurationReducer';
import { appReducer } from './reducers/appReducer';
import { gamesReducer } from './reducers/gamesReducer';
import { userReducer } from './reducers/userReducer';
import { initStore } from '../utils/api/api';
import { modalReducer } from './reducers/modalReducer';

const reducers = combineReducers({
	appConfiguration: appConfigurationReducer,
	appData: appReducer,
	userSession: userReducer,
	modal: modalReducer,
	games: gamesReducer,
});

const bindMiddleware = (middleware) => {
	if (process.env.NODE_ENV !== 'production') {
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
	const { persistStore, persistReducer, createTransform } = require('redux-persist');
	const storage = require('redux-persist/lib/storage').default;

	const BirthDateTransform = createTransform(
		// transform state on its way to being serialized and persisted.
		(inboundState) => {
			return { ...inboundState };
		},
		// transform state being rehydrated
		(outboundState) => {
			if (outboundState.birthDate) {
				return {
					...outboundState,
					birthDate: new Date(outboundState.birthDate),
				};
			}

			return { ...outboundState };
		}
	);

	const persistConfig = {
		key: 'nextjs',
		whitelist: ['userSession'],
		storage,
		transforms: [BirthDateTransform],
	};

	const persistedReducer = persistReducer(persistConfig, reducers);

	const store = createStore(persistedReducer, bindMiddleware([sagaMiddleware]));
	store.__persistor = persistStore(store);
	sagaMiddleware.run(rootSaga);
	initStore(store);
	return store;
};
