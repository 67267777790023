export const PopupType = {
	None: 'None',
	Login: 'Login',
	LoginPromo: 'LoginPromo',
	SignUp: 'SignUp',
	SignUpMobile: 'SignUpMobile',
	Congratulation: 'Congratulation',
	CompleteProfile: 'CompleteProfile',
	Finances: 'Finances',
	SideMenu: 'SideMenu',
	Profile: 'Profile',
	Confirm: 'Confirm',
	ChangePassword: 'ChangePassword',
	showSeonRestrict: 'showSeonRestrict',
	SmallModal: 'SmallModal',
	ResetPassword: 'ResetPassword',
	ForgotPassword: 'ForgotPassword',
	ExpiredSession: 'ExpiredSession',
	PromoPopup: 'PromoPopup',
	Verification: 'Verification',
};
