import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button from '../../../UI/Button/Button';
import InputWithLabel from '../../../UI/SimpleInput/SimpleInput';
import { PopupContainer } from '../../Container/Container';
import { destroySession, removeLocalAuthData } from '../../../utils/session';
import { getLoginSchema } from '../../../utils/validation';
import QuestionIconDark from '../../../UI/icons/QuestionIconDark';

import { actions } from '../../../redux/actions/commonActions';
import { endUserSession } from '../../../redux/actions/userSession';
import { useUserData } from '../../../redux/selectors';
import getMessage from '../../../utils/locale';
import styles from './SessionExpiredModal.module.scss';
import { getTrackingValues } from '../../../utils/trackingQueryHook';
import { isValidEmail } from '../../../utils/common';

const SessionExpiredModal = ({ onLogoutCallback, onLoginCallback }) => {
	const dispatch = useDispatch();
	const userData = useUserData();

	const [filled, setFilled] = useState(false);
	const [errorServerMessage, setErrorServerMessage] = useState('');

	const { register, handleSubmit, formState, watch } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		resolver: yupResolver(getLoginSchema(getMessage)),
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const { email, password } = watch();
	const { errors, dirtyFields } = formState;

	useEffect(() => {
		setFilled(Object.keys(dirtyFields).length === 2);
	}, [dirtyFields.email, dirtyFields.password]);

	useEffect(() => {
		setErrorServerMessage(userData?.errorServerMessage);
	}, [userData.errorServerMessage]);

	const onLogin = async () => {
		const requestData = isValidEmail(email)
			? {
					email: String(email).trim(),
					password,
					affiliateInformation: getTrackingValues(''),
			  }
			: {
					nickName: String(email).trim(),
					password,
					affiliateInformation: getTrackingValues(''),
			  };
		localStorage.setItem('loginRequest', 'true');
		dispatch({
			type: actions.Login,
			payload: {
				requestData,
				t: getMessage,
				callback: () => {
					onLoginCallback();
				},
			},
		});
	};

	const onLogout = () => {
		destroySession()
			.then(() => {
				dispatch(endUserSession());
				onLogoutCallback();
				window.location.href = '/';
			})
			.catch((error) => {
				if (error.response.data.statusCode === 401) {
					removeLocalAuthData();
					dispatch(endUserSession());
					onLogoutCallback();
					window.location.href = '/';
				}
			});
	};

	return (
		<PopupContainer classes={styles.session_expired}>
			<div className={styles.session_expired__icon}>
				<QuestionIconDark />
			</div>
			<h2 className={styles.session_expired__title}>
				{getMessage('es', 'session.modal.expired.title')}
			</h2>
			<form
				className={styles.session_expired__form}
				onSubmit={handleSubmit(onLogin)}
				autoComplete={'on'}
			>
				<h2 className={styles.session_expired__message}>
					{getMessage('es', 'session.modal.expired.subtitle')}
				</h2>
				<div className={styles.inputDiv}>
					<label htmlFor="email">
						{getMessage('es', 'session.modal.expired.form.email')}
					</label>
					<InputWithLabel
						reg={register('email')}
						type="text"
						name="email"
						id="email"
						errorText={errors.email?.message}
						classNameError={styles.errorInInput}
						value={email}
						autocomplete={'off'}
					/>
				</div>
				<div className={styles.inputDiv}>
					<label htmlFor="password">
						{getMessage('es', 'session.modal.expired.form.password')}
					</label>
					<InputWithLabel
						reg={register('password')}
						type="password"
						name="password"
						id="password"
						errorText={errors.password?.message}
						classNameError={styles.errorInInput}
						value={password}
						autocomplete={'off'}
					/>
				</div>
				{errorServerMessage && (
					<div className={styles.errorText}>{errorServerMessage}</div>
				)}
				<div className={styles.session_expired__form_buttons}>
					<button
						type="button"
						className={styles.button_cancel}
						onClick={onLogout}
					>
						{getMessage('es', 'session.modal.expired.form.logout')}
					</button>
					<Button
						type="submit"
						text={getMessage('es', 'session.modal.expired.form.login')}
						disabled={!filled}
						classes={'btnPrimaryBlack'}
						onClick={onLogin}
					/>
				</div>
			</form>
		</PopupContainer>
	);
};

export default SessionExpiredModal;
