import React from 'react';
import { useDispatch } from 'react-redux';

import { PopupContainer } from '../../Container/Container';
import { END_USER_SESSION, endUserSession } from '../../../redux/actions/userSession';
import styles from './LogOut.module.scss';
import { destroySession } from '../../../utils/session';
import Button from '../../../UI/Button/Button';
import getMessage from '../../../utils/locale';

const LogOutPopUp = ({ open, setOpen, setOpenSideMenu }) => {
	const dispatch = useDispatch();

	const onClose = () => setOpen(false);

	const logout = () => {
		setOpenSideMenu && setOpenSideMenu(false);
		destroySession().then(() => {
			endUserSession();
			dispatch({ type: END_USER_SESSION });
			window.location.replace('/');
		});
	};

	return (
		<PopupContainer
			classes={styles.logOut}
			closeIconTop
			open={open}
			onClose={onClose}
		>
			<h2 className={styles.title}>
				{getMessage('es', 'sideMenu.modal.logOut.title')}
			</h2>

			<div className={styles.buttons}>
				<Button
					type="button"
					variant="outlined"
					text={getMessage('es', 'cancel')}
					classes={'btnPrimaryRed'}
					onClick={() => setOpen(false)}
				/>
				<Button
					type="submit"
					variant="contained"
					text={getMessage('es', 'sideMenu.button.logOut')}
					classes={'btnPrimaryRed'}
					onClick={logout}
				/>
			</div>
		</PopupContainer>
	);
};

export default LogOutPopUp;
