import React from 'react';
import { getCurrentColor } from '../../../utils/consts';

const Slots = ({ color }) => {
	const currentColor = getCurrentColor(color);

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.6296 11.6876C20.4249 7.81582 12.003 1 12.003 1C12.003 1 3.58116 7.81582 2.37641 11.6914C1.34649 14.463 2.37641 18.6606 6.13128 19.1206C8.62439 19.4273 10.3916 18.0511 11.2999 17.0889C10.593 19.0056 9.30467 21.6238 7.21821 23H16.7802C14.6937 21.6276 13.4054 19.0094 12.6985 17.0889C13.6068 18.0511 15.374 19.4273 17.8671 19.1206C21.622 18.6606 22.6557 14.4591 21.622 11.6914L21.6296 11.6876Z"
				fill={currentColor}
			/>
		</svg>
	);
};

export default Slots;
