import React from 'react';

const Infoicon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_28633_273620)">
				<path
					d="M23.0567 7.3289C22.4524 5.89962 21.5872 4.61635 20.4854 3.51456C19.3837 2.41278 18.1004 1.54791 16.6714 0.943277C15.1915 0.317355 13.6196 0 12 0C10.3804 0 8.80888 0.317355 7.3289 0.943277C5.89996 1.54791 4.61668 2.41278 3.5149 3.51456C2.41311 4.61635 1.54791 5.89962 0.943615 7.3289C0.317355 8.80888 0 10.3804 0 12C0 13.6196 0.317355 15.1911 0.943615 16.6711C1.54791 18.1004 2.41311 19.3837 3.5149 20.4854C4.61668 21.5872 5.89996 22.4521 7.3289 23.0567C8.80888 23.6826 10.3804 24 12 24C13.6196 24 15.1915 23.6826 16.6714 23.0567C18.1004 22.4521 19.3837 21.5872 20.4854 20.4854C21.5872 19.3837 22.4524 18.1004 23.0567 16.6711C23.6826 15.1911 24 13.6196 24 12C24 10.3804 23.6826 8.80888 23.0567 7.3289ZM11.8601 5.59004C12.7493 5.59004 13.4698 6.3106 13.4698 7.1998C13.4698 8.089 12.7493 8.80921 11.8601 8.80921C10.9709 8.80921 10.2507 8.08866 10.2507 7.1998C10.2507 6.31093 10.9712 5.59004 11.8601 5.59004ZM14.3097 17.8651H9.69064V15.8093H10.5305V10.6299H9.69064V9.51388H13.4698V15.8093H14.3097V17.8651Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_28633_273620">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Infoicon;
