import React from 'react';

const FrozenIcon = () => {
	return (
		<svg
			width="46"
			height="44"
			viewBox="0 0 46 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="Subtract"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.21861 32.3432H4.2169L0.321289 41.9495L10.1279 39.362C13.8597 42.269 18.5552 44.0003 23.6595 44.0003C35.8288 44.0003 45.6784 34.1591 45.6784 22.0002C45.6784 9.84119 35.8288 0 23.6595 0C11.4901 0 1.64053 9.83949 1.64053 21.9998C1.64053 25.7404 2.57382 29.2607 4.21861 32.3432ZM23.5 12.1C22.3861 12.1 21.3178 12.5425 20.5302 13.3302C19.7425 14.1178 19.3 15.1861 19.3 16.3V20.5H27.7V16.3C27.7 15.1861 27.2575 14.1178 26.4698 13.3302C25.6822 12.5425 24.6139 12.1 23.5 12.1ZM17.2 16.3V20.5H15.3333C14.0447 20.5 13 21.4402 13 22.6V29.95C13 31.1098 14.0447 32.05 15.3333 32.05H31.6667C32.9553 32.05 34 31.1098 34 29.95V22.6C34 21.4402 32.9553 20.5 31.6667 20.5H29.8V16.3C29.8 14.6291 29.1363 13.0267 27.9548 11.8452C26.7733 10.6637 25.1709 10 23.5 10C21.8291 10 20.2267 10.6637 19.0452 11.8452C17.8637 13.0267 17.2 14.6291 17.2 16.3Z"
				fill="#00BBFF"
			/>
		</svg>
	);
};

export default FrozenIcon;
