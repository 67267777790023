import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './sidemenu.module.scss';

import Navigation from './Navigation';
import NavigationSecondary from './NavigationSecondary';
import NavigationUser from './NavigationUser';
import NavigationContacts from './NavigationContacts';
import Gamification from './Gamification';
import LogOutPopUp from '../Modals/LogOut/LogOut';

export const IconColors = {
	Action: 'action',
	Disabled: 'disabled',
	Primary: 'primary',
	Secondary: 'secondary',
};

const Menu = ({ setOpenSideMenu, setShowSpinner }) => {
	// TODO: Implementetion redirect on game site, when user logged
	// const isAuthtentificated = localStorage.getItem('email');
	// const isAuthenticated = isLoggedIn();
	const [openLogOutPopUp, setOpenLogOutPopUp] = useState(false);

	return (
		<>
			<div className={clsx(styles.back, styles.container, styles.background)}>
				<div className={styles.wrapper}>
					<Gamification />
					<Navigation setShowSpinner={setShowSpinner} />
					<NavigationSecondary setShowSpinner={setShowSpinner} />
					<NavigationContacts setShowSpinner={setShowSpinner} />
					<NavigationUser setShowSpinner={setShowSpinner} />
					{/* <SideMenuLocalItem
						href={supportLocalItem.href}
						Icon={supportLocalItem.Icon}
						label={supportLocalItem.label}
						setShowSpinner={setShowSpinner}
					/> */}
					{/* {isAuthenticated && (
						<button
							className={styles.navLinks__link}
							onClick={() => setOpenLogOutPopUp(true)}
						>
							<div className={styles.navLinks__title}>
								<LogoutIcon />
								<span>{getMessage('es', 'buttonLogOut')}</span>
							</div>
						</button>
					)} */}
				</div>
			</div>
			<div
				className={styles.backgroundAllDisplay}
				onClick={() => setOpenSideMenu(false)}
			/>
			{openLogOutPopUp && (
				<LogOutPopUp
					open={openLogOutPopUp}
					setOpen={setOpenLogOutPopUp}
					setOpenSideMenu={setOpenSideMenu}
				/>
			)}
		</>
	);
};

export default Menu;
