const translationsRegInfo = {
	en: {
		title1: 'Sign Up',
		desc1: "It's easy and fast. Claim your sports or casino bonus",
		title2: 'Bet',
		desc2: 'The best odds on sports bets and your favorite casino games',
		title3: 'Win',
		desc3: 'Withdrawing your winnings has never been easier',
	},
	es: {
		title1: 'Regístrate',
		desc1: 'Es fácil y rápido. Reclama tu bono en deportes o casino',
		title2: 'Apuesta',
		desc2: 'Los mejores momios en apuestas deportivas y tus juegos favoritos de casino',
		title3: 'Gana',
		desc3: 'Retirar tus ganancias nunca fue tan fácil',
	},
};

export default translationsRegInfo;
