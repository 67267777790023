import React from 'react';
import styles from './sidemenu.module.scss';
import { topNavSecondary } from '../../utils/consts';
import SideMenuLocalItem from './SideMenuLocalItem';
import clsx from 'clsx';

const NavigationSecondary = ({ setShowSpinner }) => {
	return (
		<div className={styles.navLinks}>
			<div className={clsx(styles.navlinksWrapper, styles.secondary)}>
				{topNavSecondary.map(({ id, label, link, Icon }) => {
					return (
						<SideMenuLocalItem
							key={id}
							link={link}
							Icon={Icon}
							label={label}
							setShowSpinner={setShowSpinner}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default NavigationSecondary;
