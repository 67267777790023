import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './utils/notifications/notifications.scss';
import Layout from './components/layout/layout';
import DateFnsUtils from '@date-io/date-fns';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import theme from './styles/globalStylesForMaterial';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { makeStore } from './redux/store';
import { ToastContainer } from 'react-toastify';
import Close from './UI/icons/Close';
import { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import SessionListener from './components/Session/SessionListener';

const closeButton = <Close size={'medium'} color={'secondary'} className="closeIcon" />;
const limitActiveAlerts = 3;
const timeViewingAlert = 5000;

const App = () => {
	const store = makeStore();

	useEffect(() => {
		// Polyfill for fromEntries method
		if (!Object.fromEntries) {
			Object.fromEntries = function (iterable) {
				// Assume the input is either an iterable object or an array-like object
				return Array.from(iterable).reduce(function (obj, entry) {
					// https://github.com/tc39/proposal-object-from-entries/blob/e4837799c1586a07c101570b27997497e5290c22/polyfill.js#L9-L10
					// contract is that entry has "0" and "1" keys, not that it is an array or iterable.
					obj[entry[0]] = entry[1];
					return obj;
				}, {});
			};
		}
	}, []);

	useEffect(() => {
		if ('serviceWorker' in navigator) {
			window.addEventListener('load', function () {
				navigator.serviceWorker.register('/sw.js').then(
					function (registration) {
						console.log(
							'Service Worker registration successful with scope: ',
							registration.scope
						);
					},
					function (err) {
						console.log('Service Worker registration failed: ', err);
					}
				);
			});
		}
	}, []);

	return (
		<Provider store={store}>
			<PersistGate persistor={store['__persistor']}>
				<ThemeProvider theme={theme}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<BrowserRouter>
							<Routes>
								<Route exact path="/" element={<Layout />} />
								<Route path="*" element={<NotFoundPage />} />
							</Routes>
						</BrowserRouter>
						<SessionListener />
					</MuiPickersUtilsProvider>
					<ToastContainer
						limit={limitActiveAlerts}
						theme="dark"
						className="toast-container"
						closeButton={closeButton}
						hideProgressBar
						autoClose={timeViewingAlert}
					/>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	);
};

export default App;
