import clsx from 'clsx';
import React, { useState } from 'react';

import styles from './simpleInput.module.scss';
import IconPassword from '../icons/iconPassword/IconPassword';

export const InputWithLabel = (props) => {
	const {
		type = 'text',
		inputMode,
		errorText,
		classNameError = '',
		name,
		size = 'auto',
		disabled,
		placeholder,
		reg,
		autocomplete = 'off',
		hint,
		onChange,
		value,
		className = '',
		classNameIconPassword = '',
		iconStart = null,
		inNavbar = false,
		iconPlace,
		dataTestId = '',
	} = props;

	const { onBlur } = reg;

	const [showPassword, setShowPassword] = useState(false);
	const [onFocus, setOnFocus] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	let eyeIcon;
	if (type === 'password') {
		eyeIcon = (
			<IconPassword
				showPassword={showPassword}
				classNameIconPassword={classNameIconPassword}
				handleClickShowPassword={handleClickShowPassword}
				iconPlace={iconPlace}
			/>
		);
	}

	return (
		<div
			className={clsx(styles.wrapperInput, {
				[styles.medium]: size === 'medium',
			})}
		>
			<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
				{iconStart && <div className={styles.wrapperIconStart}>{iconStart}</div>}
				<input
					style={eyeIcon ? { paddingRight: '40px' } : {}}
					id={name}
					type={showPassword ? 'text' : type}
					inputMode={inputMode}
					name={name}
					className={clsx(
						styles.input,
						{
							[styles.error]: errorText,
							[styles.filled_disabled]: value && disabled,
							[styles.absentValue_disabled]: !value,
							[styles.inputWithIconStart]: iconStart,
							[styles.inputInNavbar]: inNavbar,
						},
						className
					)}
					disabled={disabled}
					autoComplete={autocomplete}
					onChange={onChange}
					value={value}
					placeholder={onFocus ? undefined : placeholder}
					{...reg}
					onFocus={() => setOnFocus(true)}
					onBlur={(e) => {
						setOnFocus(false);
						onBlur(e);
					}}
					data-testid={dataTestId}
				/>
				{eyeIcon}
			</div>
			{errorText && (
				<div className={clsx(styles.errorText, classNameError)}>{errorText}</div>
			)}
			{hint && <p className={styles.hint}>{hint}</p>}
		</div>
	);
};

export default InputWithLabel;
