import { toast } from 'react-toastify';
import Warn from '../../UI/icons/notification/Warn';
import Info from '../../UI/icons/notification/Info';
import Error from '../../UI/icons/notification/Error';

export const NotificationType = {
	Error: 'error',
	Warning: 'warning',
	Info: 'info',
	Default: 'default',
};

const notification = (message, notificationType) => {
	switch (notificationType) {
		case NotificationType.Error:
			toast.error(message, {
				icon: Error,
			});
			break;
		case NotificationType.Warning:
			toast.warning(message, {
				icon: Warn,
			});
			break;
		case NotificationType.Info:
			toast.info(message, {
				icon: Info,
			});
			break;
		default:
			toast(message, {
				icon: Info,
			});
			break;
	}
};

export const errorNotification = (t, mess) => {
	notification(mess, NotificationType.Error);
};

export const simpleErrorNotification = (mess) => {
	notification(mess, NotificationType.Error);
};

export default notification;
