import { StoreAction } from '../../utils/storeActions';

export const actionsGames = {
	// for saga watcher
	GetFavouriteGames: 'GetFavouriteGames',

	// for redux
	PutFavouriteGames: 'PutFavouriteGames',
};

export const addFavouriteGames = (data) => {
	return {
		type: StoreAction.ADD_FAVOURITE_GAMES,
		payload: data.result,
	};
};
