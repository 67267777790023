const detailsCasinoFirst = () => {
	return (
		<div>
			<h3>PREMIOS DIARIOS Y SEMANALES ¡EN EFECTIVO!</h3>
			<p>✨</p>
			<p>
				Te traemos los Drops &amp; Wins de <strong>Pragmatic Play</strong>, una
				oportunidad única para llevarte premios diarios y semanales con tus
				tiradas ganadoras en los juegos participantes.
				<strong>¡Más de $40,000,000 en premios!</strong> 🥳
			</p>
			<p>✨</p>
			<p>
				<strong>PARTICIPAR ES MUY FÁCIL:</strong>
			</p>
			<p>
				1) <strong>INGRESA</strong> en tu cuenta de Winpot.mx
			</p>
			<p>
				2) <strong>JUEGA </strong>Inscríbete a través de cualquiera de los juegos
				seleccionados que encontrarás en la categoría &quot;Drops &amp; Wins&quot;
				y apuesta un mínimo de $12,5 para participar de los torneos.
			</p>
			<p>
				3) <strong>GANA </strong>¡Cuanto mayores sean tus ganancias por cada
				jugada, mejor te posicionarás en la tabla, y mayores posibilidades vas a
				tener de ganar alguno de los premios!
			</p>
			<p>✨</p>
			<p>¡El premio puede estar a un sólo giro de distancia! 💰 </p>
		</div>
	);
};

const termsCasinoFirst = () => {
	return (
		<div>
			<p>
				1. Esta es una promoción de Pragmatic Play, solo válida para jugadas
				realizadas a través de Winpot.mx y para usuarios registrados en Winpot.mx,
				mayores de 18 años, residentes en México exclusivamente, que aceptan
				recibir correos electrónicos promocionales por parte de Winpot.mx.
			</p>
			<p>
				2. Promoción válida desde el 5 de abril de 2023,&nbsp; hasta el 6 de marzo
				de 2024 hasta las 23:59h horario México.
			</p>
			<p>
				3. La promoción consiste en una mecánica de caídas y ganancias en efectivo
				sobre los juegos seleccionados del proveedor Pragmatic Play.&nbsp;
			</p>
			<p>
				4. Participan en la promoción solamente las jugadas realizadas en los
				juegos de categoría Drops &amp; Wins dentro de la página de Casino: Bigger
				Bass Bonanza, Wild Wild Riches, Hot Pepper, Mustang Gold, Buffalo King
				Megaways, Big Bass Bonanza Megaways, Big Bass Splash, Sugar Rush, Book of
				the Fallen, Cleocatra, John Hunter and the Tomb of the Scarab Queen, 5
				Lions Megaways, Big Bass Bonanza, Chilli Heat, Floating Dragons
				Hold&amp;Spin, Fruit Party, Gates of Olympus, Great Rhino Megaways, John
				Hunter and the Book of Tut, Juicy Fruits, Starlight Princess, Sweet
				Bonanza, The Dog House, Wolf Gold, Buffalo King, Extra Juicy Megaways,
				Jokers Jewels, Power of Thor Megaways, The Dog House Megaways, Book Of
				Golden Sands, Floating Dragon Megaways Hold&amp;Spin, John Hunter and the
				Book of Tut Respin, The Hand of Midas, Wild West Gold, Wild West Gold
				Megaways.
			</p>
			<p>
				5. Esta actividad promocional incluye doce (12) torneos de siete (7) días
				cada uno con un total de ochenta y cuatro (84) caídas diarias de premios
				que se llevan a cabo semanalmente hasta el 6 de marzo de 2024.&nbsp;
			</p>
			<p>
				6. Todas las tiradas tendrán que tener una apuesta mínima de $12,5 en los
				juegos participantes.&nbsp;
			</p>
			<p>
				7. Los usuarios deberán inscribirse en esta promoción cada semana que
				deseen participar dentro de los juegos participantes.
			</p>
			<p>
				8. Las Reglas del Torneo Semanal y Caída diaria de premios se pueden
				consultar en los juegos clasificatorios.&nbsp;
			</p>
			<ul>
				<li>
					Torneo Semanal: La tabla de clasificación final será visible en los
					juegos clasificatorios durante 24 horas tras la finalización del
					Torneo.
				</li>
				<li>
					Caída diaria de premios: La tabla de premios se actualiza en tiempo
					real mostrando también cuántos premios quedan. El prize drop terminará
					diariamente a las 23:59 CET/CEST o cuando no queden más premios.
				</li>
			</ul>
			<p>
				9. Las “semanas” de esta promoción darán inicio los días Miércoles a las
				12:01 y terminarán el siguiente Miércoles a las 11:59h (horario de
				México).
			</p>
			<p>
				10. Las apuestas realizadas con saldo de Bonos no son válidas para la
				participación del usuario, únicamente aquellas realizadas con dinero real.
			</p>
			<p>
				11. Las entradas a la tabla de premios son creadas automáticamente al
				cumplir con los criterios mencionados, y la posición del jugador en la
				tabla está directamente relacionada con la cantidad obtenida por un giro
				ganador al realizar una apuesta en los Juegos Participantes. Mientras más
				grande sea la ganancia obtenida por cada jugada, independientemente del
				valor de la apuesta, mayor será la posibilidad de obtener un premio.
			</p>
			<p>
				12. Todos los premios serán acreditados a las cuentas de los usuarios
				registrados en Winpot.mx que resulten ganadores en un periodo de tres días
				después de finalizada la Selección de Premios correspondiente.
			</p>
			<p>
				13. WINPOT Casino puede considerar que la conducta de un cliente es un
				&quot;abuso promocional&quot; en cualquiera de los siguientes casos (pero
				no limitado a):
			</p>
			<ul>
				<li>
					Si el importe del depósito original se retira antes de que se cumplan
					los requisitos de la jugada, se perderá el dinero de la bonificación
					más las ganancias asociadas. Se aplican contribuciones al juego.
					Revisa la tabla de contribuciones.
				</li>
				<li>
					Si realiza un retiro/transferencia antes de haber alcanzado los
					requisitos mínimos de apuesta, se cancelarán todas sus bonificaciones
					y ganancias.
				</li>
				<li>
					Si, al usar una oferta promocional de WINPOT Casino, un jugador
					utiliza un patrón de apuestas y juegos profesional (por ejemplo, el
					jugador utiliza un patrón de apuestas consistente mientras apuesta en
					juegos de ruleta)
				</li>
			</ul>
			<p>
				14. WINPOT Casino se reserva el derecho de eliminar dichas ganancias del
				saldo del jugador en circunstancias especiales a discreción de la
				gerencia.
			</p>
			<p>
				15. Los jugadores pueden retirar todas las ganancias usando su dinero de
				juego gratis sin apuestas. En cuanto a los fondos de Free-Play, no se
				pueden canjear y se eliminarán cuando se soliciten.
			</p>
			<p>
				16. Los bonos utilizados para jugar (por ejemplo, Bonos de Juego) se
				proporcionan sólo una vez por persona y/o por cuenta y de acuerdo con
				nuestros términos de uso. Si se abre una segunda cuenta, se cerrará y se
				cancelarán todas las bonificaciones y ganancias del juego. Las solicitudes
				de retiro no serán aprobadas.
			</p>
			<p>
				17. Aquellos jugadores que actualmente tengan retiros pendientes en su
				cuenta no recibirán ningún bono, promoción o premio relacionado. El uso
				indebido de esta condición tal como está definida en el casino, puede
				causar la cancelación de las ganancias del jugador.
			</p>
			<p>
				18. El casino se reserva el derecho de revisar los registros de
				transacciones en cualquier momento y por cualquier motivo. Si en una de
				estas revisiones, el (los) jugador(es) se encuentra (n) involucrado (s) en
				estrategias que el casino, a su sola discreción, considera abusivas, el
				casino se reserva el derecho de anular el privilegio de dicho jugador a
				utilizar la promoción.
			</p>
			<p>
				19. WINPOT se reserva el derecho de modificar y/o cancelar esta promoción
				en cualquier momento.
			</p>
			<p>
				20. En caso de que haya algún problema, las decisiones que tome el casino
				serán decisivas.
			</p>
			<p>
				21. Todos los Términos y Condiciones del Bono están sujetos en todo
				momento a los Términos y Condiciones de WINPOT.MX Casino.
			</p>
			<p>
				El dominio&nbsp;<a href="http://winpot.mx/">winpot.mx</a>&nbsp;es
				explotado por la empresa&quot;, Winpot Online América S.A. de C.V.&quot;,
				operadora de &quot;PUR UMAZAL TOV,S.A. de C.V.&quot; bajo el amparo del
				permiso DGJS/DGAFJ/DCRCA/p-08/2014, con autorización de la Dirección
				General de Juegos y Sorteos DGJS/393/2018 DCJS/1130/2022 DGJS/1681/2018 y
				DGJS/1807/2023 Promoción validada en oficio de autorización
				DGJS/DGAFJ/DCRCA/P-08/2014.Juegue responsablemente. Juegos prohibidos para
				menores de dad. Oficios No.DGJS-4819/2022,DGJS-1568/2022DGJS-0913-2023
				&quot;TORNEOS&quot; Válido MARZO 2024. Juegue responsablemente. Juegos
				prohibidos para menores de edad.
			</p>
		</div>
	);
};

export { detailsCasinoFirst, termsCasinoFirst };
