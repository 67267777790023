const initialState = {
	socketUrl: '',
	siteLanguages: '',
	apiUrl: '',
};

export const appConfigurationReducer = (state = initialState, action) => {
	const { type, data } = action;
	switch (type) {
		case 'update': {
			return { ...state, ...data };
		}
	}
	return state;
};
