import { call, put, takeLeading } from 'redux-saga/effects';

import * as apiUser from '../utils/api/user';
import {
	actions,
	clickedOnSupport,
	finishRequest,
	finishRequestValidateFields,
	putDataUser,
	startRequest,
	startRequestValidateFields,
} from '../redux/actions/commonActions';
import * as actionsUser from '../redux/actions/userSession';
import {
	putErrorServerMessage,
	putErrorServerMessageObject,
	resetErrorServerMessage,
} from '../redux/actions/userSession';
import { initializeSession, isLoggedIn } from '../utils/session';
import {
	errorNotification,
	simpleErrorNotification,
} from '../utils/notifications/notifications';
import {
	loginRegistrationAdditionalHeaders,
	setShowVerificationModalTime,
} from '../utils/common';
import {
	AccountStatus,
	ONE_MINUTE_IN_MILLISECONDS,
	setCookieToDomains,
} from '../utils/consts';
import getMessage from '../utils/locale';

// worker saga: makes the api call when watcher saga sees the action
// eslint-disable-next-line require-jsdoc
function* login({ payload }) {
	// eslint-disable-next-line no-unused-vars
	const { t, callback, requestData, formInHeader = false } = payload;
	yield put(startRequest());
	// let hasError = false;
	try {
		// To update state we need to invoke call and put functions twice
		if (!localStorage.getItem('loginRequest')) return;
		localStorage.removeItem('loginRequest');
		const { data } = yield call(
			apiUser.checkUser,
			{ ...requestData },
			loginRegistrationAdditionalHeaders()
		);
		yield put(
			actionsUser.beginUserSession({
				...data,
				userInfo: {
					email: data.email,
					phone: data?.phone,
					nickName: data.nickName,
					firstName: data.firstName,
					lastName: data.lastName,
					middleName: data?.middleName,
					gender: data?.gender,
					nationalityAlfa2: data?.nationalityAlfa2,
					address: data.address,
					birthDate: data.birthDate ? new Date(data.birthDate) : null,
					language: data.language,
					product: data?.product,
					emailVerified: data.emailVerified,
					phoneVerified: data.phoneVerified,
					isKYC: data.isKYC,
					...data.address,
				},
				errorServerMessage: null,
			})
		);
		initializeSession(data);
		setCookieToDomains(
			'rememberUser',
			requestData.remember ? 'remember' : 'not_remember'
		);
	} catch (e) {
		yield put(
			putDataUser({
				statusError: e.response?.status,
				dataError: e.response?.data,
			})
		);
		if (e.response?.data?.data) {
			const { accountStatus } = e.response?.data?.data;
			if (accountStatus === AccountStatus.Blocked) {
				return;
			}
			const message = t('es', `${e.response?.data?.message}`, {
				field: accountStatus,
			});
			formInHeader
				? simpleErrorNotification(message)
				: yield put(putErrorServerMessage(message));
		} else {
			const failedLoginAttempts = sessionStorage.getItem('failedLoginAttempts');
			if (!failedLoginAttempts) {
				sessionStorage.setItem('failedLoginAttempts', '1');
			} else {
				sessionStorage.setItem('failedLoginAttempts', '2');
			}

			const message = t('es', `${e.response?.data?.message}`);
			yield put(putErrorServerMessage(message));

			formInHeader
				? simpleErrorNotification(message)
				: yield put(putErrorServerMessage(message));
		}
	} finally {
		yield put(finishRequest());
	}
}

// eslint-disable-next-line require-jsdoc
function* registration({ payload }) {
	// eslint-disable-next-line no-unused-vars
	const { profileData, t } = payload;
	yield put(startRequest());
	try {
		let responseRegister;
		if (localStorage.getItem('singUpRequest')) {
			localStorage.removeItem('singUpRequest');
			responseRegister = yield call(
				apiUser.register,
				{ ...profileData },
				loginRegistrationAdditionalHeaders()
			);
		}
		const registrationData =
			responseRegister.status === 201 ? responseRegister?.data : null;
		setShowVerificationModalTime(ONE_MINUTE_IN_MILLISECONDS);
		setCookieToDomains('isRegistration', 'done');
		setCookieToDomains(
			'shouldRecieveBonus',
			responseRegister?.data?.isOptBonusAvailable?.toString()
		);
		initializeSession(registrationData);
		yield put(
			actionsUser.beginUserSession({
				...registrationData,
				birthDate: registrationData.birthDate
					? new Date(registrationData.birthDate)
					: null,
			})
		);
	} catch (e) {
		yield put(
			putDataUser({
				statusError: e.response?.status,
				dataError: e.response?.data,
			})
		);
		const dataMessage = e.response?.data?.data;
		if (dataMessage) {
			const [field, errorMessage] = Object.entries(dataMessage)[0];
			const translateField = t('es', `players.field.${field}`);
			const message = t('es', `${errorMessage}`, {
				field: translateField,
			});
			yield put(
				putErrorServerMessageObject({
					[`${field === 'phone' ? 'phoneNumber' : field}`]: message,
				})
			);
		} else if (
			e.response?.data?.statusCode === 403 &&
			e.response?.data?.message.includes('rejected')
		) {
			payload.showSeonRestrict();
		} else {
			const message = e.response?.data?.message;
			if (message.includes('duplicate')) {
				payload.validateUniqueFields(message, payload.setError);
			} else {
				simpleErrorNotification(message);
			}
		}
	} finally {
		yield put(finishRequest());
	}
}

// eslint-disable-next-line require-jsdoc
function* clickOnSupport({ payload }) {
	yield put(clickedOnSupport(payload));
}

function* validateUserEmail({ payload }) {
	yield put(startRequestValidateFields());
	yield put(putDataUser({ isEmailValidated: false }));
	const { t } = payload;
	try {
		const res = yield call(apiUser.validate, payload);
		yield put(putDataUser({ isEmailValidated: res.data.success }));
		yield put(resetErrorServerMessage());
	} catch (e) {
		yield put(
			putDataUser({
				statusError: e.response?.status,
				dataError: e.response?.data,
				isEmailValidated: false,
			})
		);
		const dataMessage = e.response?.data?.data;
		if (dataMessage) {
			const [field, errorMessage] = Object.entries(dataMessage)[0];

			const message = t(errorMessage, {
				field: t(`players.field.${field}`, { ns: 'players' }),
			});
			yield put(
				putErrorServerMessageObject({
					[`${field}`]: message,
				})
			);
		} else {
			const message = e.response?.data?.message;
			if (message.includes('duplicate')) {
				payload.validateUniqueFields(message, payload.setError);
			} else if (message === 'Invalid domain' || message.includes('black list')) {
				payload.setError('email', {
					type: 'value',
					message: t('es', 'incorrect.email'),
				});
			}
		}
	} finally {
		yield put(finishRequestValidateFields());
	}
}

function* validateUserPhone({ payload }) {
	yield put(startRequestValidateFields());
	yield put(putDataUser({ isPhoneValidated: false }));
	const { t } = payload;
	try {
		const res = yield call(apiUser.validate, payload);
		yield put(putDataUser({ isPhoneValidated: res.data.success }));
		yield put(resetErrorServerMessage());
	} catch (e) {
		yield put(
			putDataUser({
				statusError: e.response?.status,
				dataError: e.response?.data,
				isPhoneValidated: false,
			})
		);
		const dataMessage = e.response?.data?.data;
		if (dataMessage) {
			const [field, errorMessage] = Object.entries(dataMessage)[0];

			const message = t(errorMessage, {
				field: t(`players.field.${field}`, { ns: 'players' }),
			});
			yield put(
				putErrorServerMessageObject({
					['phoneNumber']: message,
				})
			);
		} else {
			const message = e.response?.data?.message;
			if (message.includes('duplicate')) {
				payload.validateUniqueFields(message, payload.setError);
			}
		}
	} finally {
		yield put(finishRequestValidateFields());
	}
}

// eslint-disable-next-line require-jsdoc
function* getInfo() {
	try {
		const { data } = yield call(apiUser.getInfo);
		yield put(
			putDataUser({
				userInfo: {
					...data,
					birthDate: data.birthDate ? data.birthDate : null,
					street: data?.address?.street,
					city: data?.address?.city,
					state: data?.address?.state,
					phoneVerified: data?.phoneVerified,
					emailVerified: data?.emailVerified,
					zipCode: data?.address?.zipCode,
					addressCountryAlfa2: data?.address?.addressCountryAlfa2,
				},
			})
		);
		localStorage.setItem('email', data?.email);
		localStorage.setItem('userId', data?.id);
		if (data?.phoneVerified) {
			setCookieToDomains('isVerifiedPhoneNumber', 'isVerifiedPhoneNumber');
		}
	} catch (e) {
		simpleErrorNotification(e.response?.data?.message);
	}
}

// eslint-disable-next-line require-jsdoc
function* getBalance() {
	try {
		const { data } = yield call(apiUser.getBalance);
		yield put(
			putDataUser({
				balance: data,
			})
		);
	} catch (e) {
		if (isLoggedIn()) {
			simpleErrorNotification(e.response?.data?.message);
		}
	}
}

function* updatePhone({ payload }) {
	const { mappingData, setError, setExistPhoneNumber } = payload;
	setExistPhoneNumber({ phone: '', errorText: '' });
	try {
		const { data } = yield call(apiUser.updatePhone, mappingData);
		yield put(
			putDataUser({
				userInfo: {
					...data,
					birthDate: data.birthDate ? new Date(data.birthDate) : null,
					...data.address,
				},
			})
		);
	} catch (e) {
		if (e.response?.data?.message !== 'nothing.to.update') {
			const dataMessage = e.response?.data?.data;
			if (dataMessage) {
				const [field, errorMessage] = Object.entries(dataMessage)[0];
				if (errorMessage === 'already.exists') {
					let fieldForTranslation = field;
					if (fieldForTranslation === 'phone') {
						fieldForTranslation = 'phoneNumber';
					}
					setExistPhoneNumber({
						phone: mappingData.phone,
						errorText: getMessage('es', `duplicate.${fieldForTranslation}`),
					});
					setError(fieldForTranslation, {
						type: 'value',
						message: getMessage('es', `duplicate.${fieldForTranslation}`),
					});
				} else {
					errorNotification('', errorMessage, {
						field: getMessage('es', `players.field.${field}`),
					});
				}
			} else {
				errorNotification('', getMessage('es', e.response?.data?.message));
			}
		}
	}
}

// watcher root saga for userApi:
// watches for actions dispatched to the store, starts worker saga
// eslint-disable-next-line require-jsdoc
export default function* userSaga() {
	yield takeLeading(actions.Login, login);
	yield takeLeading(actions.SignUp, registration);
	yield takeLeading(actions.ClickedOnSupport, clickOnSupport);
	yield takeLeading(actions.ValidateEmail, validateUserEmail);
	yield takeLeading(actions.ValidatePhone, validateUserPhone);
	yield takeLeading(actions.GetInfo, getInfo);
	yield takeLeading(actions.GetBalance, getBalance);
	yield takeLeading(actions.UpdatePhone, updatePhone);
}
