import createTheme from '@material-ui/core/styles/createTheme';
const primaryColor = '#FFFFFF';
const disableColor = '#4E4E66';
const primaryBackground = '#AE8420';
const primaryBackgroundHover = '#DBA62C';
const disableBackground = '#2B2B3A';
const secondaryBackground = '#E73843';
const toolbarBackground = '#FFFFFF';
const toolbarBorder = '#DAD8D8';
const txtColor = '#000000';
const mainBlockColor = '#000000';
const mainBlockSelectedColor = '#E73843';
const primaryGoldColor = '#AE8420';
const inputPlaceholderColor = '#414158';
const primaryBorder = `1px solid ${primaryBackground}`;
const primaryBorderHover = `1px solid ${primaryBackgroundHover}`;
const primaryBorderRadius = '4px';
const secondaryBorder = `1px solid ${secondaryBackground}`;
const primaryFontFamily = 'Montserrat, sans-serif';
const svgColorInactive = '#323246';
const svgHoverColorInactive = '#7A768F';
const theme = createTheme({
	typography: {
		fontFamily: primaryFontFamily,
		allVariants: {
			color: txtColor,
			fontStyle: 'normal',
		},
		h1: {
			fontWeight: 'bold',
			fontSize: '28px',
			lineHeight: '100%',
			textTransform: 'capitalize',
		},
		h2: {
			fontWeight: 700,
			fontSize: '18px',
			lineHeight: '22px',
			textTransform: 'capitalize',
		},
		body1: {
			fontSize: '14px',
			lineHeight: '32px',
			fontWeight: 'normal',
		},
	},
	overrides: {
		MuiPaper: {
			root: {
				width: 0,
			},
		},
		MuiInput: {
			underline: {
				'&::after': {
					content: 'none',
				},
			},
		},
		MuiFormHelperText: {
			root: {
				'&.Mui-error': {
					fontSize: '11px',
					color: '#E44F73',
				},
			},
		},
		MuiDialog: {
			root: {
				zIndex: `1995 !important`,
			},
			// DatePicker
			container: {
				'& .MuiPaper-rounded': {
					margin: '9px 20px 0',
					borderRadius: '8px',
					background: 'transparent',
					width: '320px',
				},
				// DatePicker toolbar with full date output
				'& .MuiPickersDatePickerRoot-toolbar': {
					background: toolbarBackground,
					height: '83px',
					// '&::after': {
					// 	position: 'relative',
					// 	content: '""',
					// 	width: '300px',
					// 	height: '1px',
					// 	left: '-15px',
					// 	top: '10px',
					// 	background: toolbarBorder,
					// },
					'& .MuiPickersToolbarButton-toolbarBtn': {
						height: 'auto',
						margin: '4px 0px',
					},
					'& .MuiPickersToolbarText-toolbarTxt': {
						color: txtColor,
					},
					'& .MuiButton-label': {
						justifyContent: 'start',
						fontFamily: primaryFontFamily,
						fontStyle: 'normal',
						textAlign: 'left',
						// year
						'& h6': {
							height: '16px',
							fontSize: '10px',
							fontWeight: '500',
							lineHeight: '16px',
							letterSpacing: '1.5px',
						},
						// full date
						'& h4': {
							height: '24px',
							fontSize: '20px',
							fontWeight: '400',
							lineHeight: '24px',
							letterSpacing: '0.1px',
						},
					},
				},
				// DatePicker main block
				'& .MuiPickersBasePicker-pickerView': {
					backgroundColor: toolbarBackground,

					'@media (orientation: landscape)': {
						height: '305px',
						minHeight: 'auto',
					},

					'& .MuiPickersYear-root': {
						color: mainBlockColor,
						opacity: 0.87,
						fontSize: '14px',
						'& + .MuiPickersYear-yearSelected': {
							fontSize: '18px',
							fontWeight: 'bold',
							margin: 0,
						},
					},
					'& .MuiPickersMonthSelection-container': {
						'& .MuiPickersMonth-root': {
							color: txtColor,
							fontSize: '14px',
							'&.MuiPickersMonth-monthSelected': {
								fontSize: '18px',
								fontWeight: 'bold',
								color: mainBlockSelectedColor,
							},
						},
					},
					'& .MuiPickersCalendarHeader-switchHeader button': {
						color: mainBlockColor,
						background: 'none',
						'& svg': {
							color: mainBlockColor,
						},
					},
					'& .MuiPickersCalendarHeader-dayLabel': {
						color: '#6A7187',
					},
					'& .MuiPickersCalendarHeader-transitionContainer': {
						color: mainBlockColor,
					},
					'& .MuiTypography-colorInherit': {
						color: mainBlockColor,
					},
					'& .MuiPickersDay-daySelected': {
						backgroundColor: mainBlockSelectedColor,
						'& p': {
							color: '#FFFFFF',
							fontWeight: 'bold',
						},
					},
					'& .MuiPickersYearSelection-container': {
						'&::-webkit-scrollbar': {
							width: '6px',
							height: '20px',
							borderRadius: '1vw',
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#DEDEE0',
							borderRadius: '1vw',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#F5F3F3',
							borderRadius: '100vw',
						},
						scrollbarWidth: 'thin',
						scrollbarColor: '#F5F3F3 #DEDEE0',
						'& .MuiPickersYear-yearSelected': {
							color: mainBlockSelectedColor,
						},
					},
				},
				// DatePicker bottom buttons
				'& .MuiDialogActions-spacing': {
					backgroundColor: toolbarBackground,
					padding: '0px 39px 32px',
					justifyContent: 'space-between',
					'& .MuiButton-textPrimary': {
						width: '104px',
						height: '40px',
						color: txtColor,
						border: secondaryBorder,
						boxSizing: 'border-box',
						borderRadius: '4px',
						'&:last-child': {
							background: secondaryBackground,
							color: '#FFFFFF',
						},
					},
				},
			},
			// DatePicker end
		},
		MuiPopover: {
			// DatePicker
			root: {
				zIndex: `1995 !important`,
				'& .MuiPaper-rounded': {
					margin: '9px auto 0',
					borderRadius: '8px',
					background: 'transparent',
					width: '320px',
				},
				// DatePicker toolbar with full date output
				'& .MuiPickersDatePickerRoot-toolbar': {
					background: toolbarBackground,
					height: '83px',
					'&::after': {
						position: 'relative',
						content: '""',
						width: '300px',
						height: '1px',
						left: '-15px',
						top: '10px',
						background: toolbarBorder,
					},
					'& .MuiPickersToolbarButton-toolbarBtn': {
						height: 'auto',
						margin: '4px 0px',
					},
					'& .MuiPickersToolbarText-toolbarTxt': {
						color: txtColor,
					},
					'& .MuiButton-label': {
						justifyContent: 'start',
						fontFamily: primaryFontFamily,
						fontStyle: 'normal',
						textAlign: 'left',
						// year
						'& h6': {
							height: '16px',
							fontSize: '10px',
							fontWeight: '500',
							lineHeight: '16px',
							letterSpacing: '1.5px',
						},
						// full date
						'& h4': {
							height: '24px',
							fontSize: '20px',
							fontWeight: '400',
							lineHeight: '24px',
							letterSpacing: '0.1px',
						},
					},
				},
				// DatePicker main block
				'& .MuiPickersBasePicker-pickerView': {
					backgroundColor: toolbarBackground,

					'@media (orientation: landscape)': {
						height: '305px',
						minHeight: 'auto',
					},

					'& .MuiPickersYear-root': {
						color: mainBlockColor,
						opacity: 0.87,
						fontSize: '14px',
						'& + .MuiPickersYear-yearSelected': {
							fontSize: '18px',
							fontWeight: 'bold',
							margin: 0,
						},
					},
					'& .MuiPickersMonthSelection-container': {
						'& .MuiPickersMonth-root': {
							color: txtColor,
							fontSize: '14px',
							'&.MuiPickersMonth-monthSelected': {
								fontSize: '18px',
								fontWeight: 'bold',
								color: mainBlockSelectedColor,
							},
						},
					},
					'& .MuiPickersCalendarHeader-switchHeader button': {
						color: mainBlockColor,
						background: 'none',
						'& svg': {
							color: mainBlockColor,
						},
					},
					'& .MuiPickersCalendarHeader-dayLabel': {
						color: '#6A7187',
					},
					'& .MuiPickersCalendarHeader-transitionContainer': {
						color: mainBlockColor,
					},
					'& .MuiTypography-colorInherit': {
						color: mainBlockColor,
					},
					'& .MuiPickersDay-daySelected': {
						backgroundColor: mainBlockSelectedColor,
						'& p': {
							color: '#FFFFFF',
							fontWeight: 'bold',
						},
					},
					'& .MuiPickersYearSelection-container': {
						'&::-webkit-scrollbar': {
							width: '6px',
							height: '20px',
							borderRadius: '1vw',
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#DEDEE0',
							borderRadius: '1vw',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#F5F3F3',
							borderRadius: '100vw',
						},
						scrollbarWidth: 'thin',
						scrollbarColor: '#F5F3F3 #DEDEE0',
						'& .MuiPickersYear-yearSelected': {
							color: mainBlockSelectedColor,
						},
					},
				},
				// DatePicker bottom buttons
				'& .MuiDialogActions-spacing': {
					backgroundColor: '#101018',
					padding: '0px 39px 32px',
					justifyContent: 'space-between',
					'& .MuiButton-textPrimary': {
						width: '104px',
						height: '40px',
						color: txtColor,
						border: secondaryBorder,
						boxSizing: 'border-box',
						borderRadius: '4px',
						'&:last-child': {
							background: secondaryBackground,
						},
					},
				},
			},
			// DatePicker end
		},
		MuiIconButton: {
			root: {
				color: svgColorInactive,
				'&:hover': {
					color: svgHoverColorInactive,
				},
			},
		},
		MuiSvgIcon: {
			colorPrimary: {
				color: txtColor,
			},
			colorSecondary: {
				color: '#69657D',
				'&:hover': {
					color: txtColor,
				},
			},
			colorAction: {
				color: primaryGoldColor,
			},
			colorDisabled: {
				color: '#53515E',
			},
		},
		MuiButton: {
			root: {
				color: primaryColor,
				width: '100%',
				height: '40px',
				fontWeight: 600,
				fontFamily: primaryFontFamily,
				fontSize: '14px',
				lineHeight: '17px',
				borderRadius: primaryBorderRadius,
				textTransform: 'none',
				'& + .Mui-disabled': {
					opacity: 1,
					color: disableColor,
					background: disableBackground,
				},
				'&:hover': {
					'& .MuiSvgIcon-colorDisabled': {
						color: txtColor,
					},
				},
				['@media (max-width: 1024px)']: {
					fontSize: '16px',
				},
			},
			contained: {
				'&.Mui-disabled': {
					opacity: 1,
					color: disableColor,
					backgroundColor: `${disableBackground} !important`,
				},
			},
			outlinedPrimary: {
				color: primaryColor,
				border: primaryBorder,
				'&:hover': {
					border: primaryBorderHover,
					backgroundColor: primaryBackgroundHover,
				},
				['@media (hover: none)']: {
					'&:hover': {
						border: primaryBorder,
						backgroundColor: 'transparent',
					},
				},
			},
			containedPrimary: {
				backgroundColor: primaryBackground,
				color: primaryColor,
				'&:hover': {
					backgroundColor: primaryBackgroundHover,
				},
				['@media (hover: none)']: {
					backgroundColor: `${primaryBackground} !important`,
					'&:hover': {
						backgroundColor: primaryBackground,
					},
				},
			},
			sizeSmall: {
				width: 'auto',
			},
			sizeLarge: {
				width: '106px',
				height: '48px',
				fontWeight: 700,
			},
		},
		MuiTextField: {
			root: {
				width: '100%',
				height: '48px',
				'& .MuiInputBase-formControl': {
					height: '100%',
				},
				'& .MuiInputLabel-outlined': {
					top: '-4px',
					color: inputPlaceholderColor,
				},
				'& .MuiInputBase-input': {
					'font-family': 'Montserrat, sans-serif',
					'font-size': '14px',
					'line-height': '17px',
					color: '#000',
					'&:placeholder': {
						'caret-color': '#404040',
						'font-weight': 500,
					},
				},
			},
		},
		MuiInputLabel: {
			root: {
				'font-size': '14px',
				'line-height': '17px',
			},
			outlined: {
				'&.MuiInputLabel-shrink': {
					top: '0 !important',
				},
			},
		},
		MuiFormControlLabel: {
			root: {
				color: 'white',
				'& .MuiIconButton-colorPrimary:hover': {
					backgroundColor: '#33353D',
				},
				'& .MuiCheckbox-colorPrimary.Mui-checked': {
					'& .MuiIconButton-label': {
						background: '#FBFBFB',
					},
				},
				'& .MuiIconButton-label': {
					borderRadius: '4px',
					border: '1.5px solid white',
					width: '20px',
					height: '20px',
					'& svg': {
						color: '#2C2E35',
					},
				},
			},
		},
	},
});
export default theme;
