import clsx from 'clsx';
import React from 'react';

import Check from '../icons/Check';
import styles from './ProgressIndicator.module.scss';

export const IndicatorStatus = {
	Wait: 'Wait',
	Active: 'Active',
	Completed: 'Completed',
};

const ProgressIndicator = ({ statusSteps, onBackHandle }) => {
	return (
		<div className={styles.wrapper}>
			{Object.values(statusSteps).map((step, ind, arr) => {
				return (
					<>
						<div
							className={clsx(styles.indicator, {
								[styles.active]: step === IndicatorStatus.Active,
								[styles.completed]: step === IndicatorStatus.Completed,
							})}
						>
							{step === IndicatorStatus.Completed ? (
								<div
									className={styles.check_wrapper}
									onClick={onBackHandle}
								>
									<Check color={'secondary'} width={'12px'} />
								</div>
							) : (
								<div
									className={clsx(styles.dot, {
										[styles.active]: step === IndicatorStatus.Active,
									})}
								/>
							)}
						</div>
						{ind + 1 !== arr.length && (
							<div
								className={clsx(styles.line, {
									[styles.active]: step === IndicatorStatus.Completed,
								})}
							/>
						)}
					</>
				);
			})}
		</div>
	);
};

export default ProgressIndicator;
