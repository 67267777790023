import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Check = ({ color, width }) => {
	return (
		<SvgIcon
			color={color}
			style={{ fontSize: '16px', width: width ?? '100%' }}
			viewBox="0 0 24 24"
		>
			<path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z" />
		</SvgIcon>
	);
};

export default Check;
