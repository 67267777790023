import React from 'react';
import { getCurrentColor } from '../../../utils/consts';

const Promotions = ({ color }) => {
	const currentColor = getCurrentColor(color);

	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.06301 6.95508H6.08374V12.5307H2.06301C1.9061 12.5307 1.72856 12.4547 1.57925 12.2709C1.42764 12.0844 1.33008 11.8128 1.33008 11.5132V7.97253C1.33008 7.67296 1.42764 7.40142 1.57925 7.21483C1.72856 7.03106 1.9061 6.95508 2.06301 6.95508Z"
				fill={currentColor}
				stroke={currentColor}
			/>
			<path
				d="M18.6476 16.3262V16.3264C18.6477 16.4606 18.613 16.5942 18.5448 16.716C18.4805 16.8307 18.3876 16.9326 18.2712 17.0122C17.9763 17.1511 17.6348 17.183 17.3146 17.0998L8.13525 12.653V6.82768L17.3439 2.39515C17.4881 2.33889 17.6459 2.31623 17.8033 2.33018C17.9651 2.34453 18.1185 2.39688 18.2496 2.48034C18.3761 2.56183 18.4766 2.66917 18.5448 2.79093L18.981 2.54652L18.5448 2.79093C18.613 2.91271 18.6477 3.04633 18.6476 3.1805V3.18073V16.3262Z"
				fill={currentColor}
				stroke={currentColor}
			/>
			<path
				d="M5.82704 14.8828H3.2959L4.17303 17.5733C4.22897 17.7455 4.34767 17.8971 4.51108 18.0049C4.67448 18.1127 4.87366 18.1709 5.07835 18.1706H5.65474C5.79784 18.1652 5.93779 18.1321 6.06457 18.0738C6.19134 18.0155 6.3018 17.9334 6.38802 17.8334C6.47425 17.7334 6.53411 17.6179 6.56331 17.4952C6.59251 17.3726 6.59033 17.2458 6.55693 17.124L5.82704 14.8828Z"
				fill={currentColor}
				stroke={currentColor}
			/>
		</svg>
	);
};

export default Promotions;
