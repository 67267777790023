const translationsNavigation = {
	en: {
		NavigationSport: 'Sports',
		NavigationSportLive: 'Sports Live',
		NavigationVirtualSports: 'Virtuals',
		NavigationWinclub: 'VIP',
		NavigationWinpotClub: 'Winpot club',
		NavigationCasino: 'Casino',
		NavigationSlots: 'Slots',
		NavigationLiveCasino: 'Live casino',
		NavigationBingo: 'Bingo',
		NavigationMyAccount: 'My account',
		NavigationBalanceAndInformation: 'Balance & Information',
		NavigationKYC: 'KYC',
		NavigationTransactions: 'Transactions',
		NavigationDeposit: 'Deposit/Withdraw',
		NavigationPromotions: 'Promotions',
		NavigationBank: 'Bank',
		NavigationSupport: 'Support',
		NavigationApp: 'Download the app',
		NavigationInfo: 'Information',
		NavigationInfoAccount: 'To Open An Account',
		NavigationInfoDeposit: 'Deposits In Your Account',
		NavigationInfoWithdraw: 'Withdraw Your Earnings',
		NavigationInfoBonds: 'Withdraw Your Earnings',
		NavigationInfoBetRules: 'Betting Rules',
		NavigationInfoVerification: 'Verification',
		NavigationInfoPayment: 'Payment options',
		NavigationTitleContacts: 'Contact us',
		NavigationTitleLiveChat: 'Live Chat',
		NavigationTitleWhatsapp: '+52 5532757944',
		NavigationSubTitleWhatsapp: '(9am - 10pm)',
		NavigationTitleSupport: 'soporte@winpot.mx',
		NavigationGamificationTitle: 'WinReto',
		NavigationGamificationOverview: 'Overview',
		NavigationGamificationCasino: 'Casinos Missions',
		NavigationGamificationSport: 'Sports Missions',
		NavigationGamificationStore: 'Store',
		NavigationGamificationMail: 'Mail',
	},
	es: {
		NavigationSport: 'Deportes',
		NavigationSportLive: 'Deportes en vivo',
		NavigationVirtualSports: 'Virtuales',
		NavigationWinclub: 'VIP',
		NavigationWinpotClub: 'Winpot club',
		NavigationCasino: 'Casino',
		NavigationSlots: 'Slots',
		NavigationLiveCasino: 'Casino En Vivo',
		NavigationBingo: 'Bingo',
		NavigationMyAccount: 'Mi cuenta',
		NavigationBalanceAndInformation: 'Balance & Información',
		NavigationKYC: 'KYC',
		NavigationTransactions: 'Transacciones',
		NavigationDeposit: 'Depósito/Retirada',
		NavigationPromotions: 'Promociones',
		NavigationBank: 'Banco',
		NavigationSupport: 'Soporte',
		NavigationApp: 'Descargar la aplicación',
		NavigationInfo: 'Información',
		NavigationInfoAccount: 'Abrir Una Cuenta',
		NavigationInfoDeposit: 'Depositos En Tu Cuenta',
		NavigationInfoWithdraw: 'Retirar Tus Ganancias',
		NavigationInfoBonds: 'Informacion Sobre Bonos',
		NavigationInfoBetRules: 'Reglas De Apuestas',
		NavigationInfoVerification: 'Verificación',
		NavigationInfoPayment: 'Opciones De Pago',
		NavigationTitleContacts: 'Contacto',
		NavigationTitleLiveChat: 'Chat En Vivo',
		NavigationTitleWhatsapp: '+52 5532757944',
		NavigationSubTitleWhatsapp: '(9am - 10pm)',
		NavigationTitleSupport: 'soporte@winpot.mx',
		NavigationGamificationTitle: 'WinReto',
		NavigationGamificationOverview: 'Resumen',
		NavigationGamificationCasino: 'Casino Misiones',
		NavigationGamificationSport: 'Deportes Misiones',
		NavigationGamificationStore: 'Tienda',
		NavigationGamificationMail: 'Bandeja',
	},
};

export default translationsNavigation;
