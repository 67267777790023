import { StoreAction } from '../../utils/storeActions';

const initialState = {
	favouriteGames: [],
	games: [],
};

export const gamesReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case StoreAction.ADD_GAMES: {
			return {
				...state,
				games: payload,
			};
		}

		case StoreAction.ADD_FAVOURITE_GAMES: {
			return {
				...state,
				favouriteGames: payload,
			};
		}

		default:
			return state;
	}
};
