import React from 'react';
import clsx from 'clsx';
import styles from './sidemenu.module.scss';
import { useDispatch } from 'react-redux';
import getMessage from '../../utils/locale';
import ChatIcon from '../../UI/icons/ChatIcon';
import WhatsappIcon from '../../UI/icons/WhatsappIcon';
import SupportIcon from '../../UI/icons/SupportIcon';
import { actions } from '../../redux/actions/commonActions';

const SideMenuLocalItemContacts = () => {
	const dispatch = useDispatch();

	const openSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	const contactsNav = [
		{
			icon: ChatIcon,
			urlTitle: getMessage('es', 'NavigationTitleLiveChat'),
			lowercased: false,
			onClick: openSupport,
		},
		{
			icon: WhatsappIcon,
			urlTitle: getMessage('es', 'NavigationTitleWhatsapp'),
			urlSubtitle: (
				<span
					dangerouslySetInnerHTML={{
						__html: getMessage('es', 'NavigationSubTitleWhatsapp'),
					}}
				/>
			),
			lowercased: false,
			onClick: () => {
				window.location.href =
					'https://api.whatsapp.com/send/?phone=525532757944&text&type=phone_number&app_absent=0';
			},
		},
		{
			icon: SupportIcon,
			urlTitle: getMessage('es', 'NavigationTitleSupport'),
			lowercased: true,
			onClick: () => {
				window.location.href = 'mailto:soporte@winpot.mx';
			},
		},
	];

	return (
		<div className={styles.navLinks}>
			{contactsNav.map((item, idx) => {
				return (
					<div key={idx}>
						<span
							className={clsx(
								styles.navLinks__title,
								styles.navLinks__title__contacts
							)}
							onClick={item.onClick}
						>
							<item.icon />
							<span
								className={
									item.lowercased ? styles['lowercased'] : undefined
								}
							>
								{item.urlTitle}
							</span>
							<span className={styles.navLinks__subtitle__contacts}>
								{!!item.urlSubtitle && item.urlSubtitle}
							</span>
						</span>
					</div>
				);
			})}
		</div>
	);
};

export default SideMenuLocalItemContacts;
