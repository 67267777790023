import React from 'react';
import styles from './sidemenu.module.scss';
import getMessage from '../../utils/locale';

const SideMenuLocalItemUser = (props) => {
	const { link, label, setShowSpinner } = props;

	const handleClickOnLink = () => {
		setShowSpinner(true);
		window.location.href = link;
	};

	return (
		<div className={styles.navLinks__title} onClick={() => handleClickOnLink()}>
			<div>{getMessage('es', `Navigation${label}`)}</div>
		</div>
	);
};

export default SideMenuLocalItemUser;
