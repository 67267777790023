import React from 'react';

const Info = ({ color }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.6364 22.4993C17.2345 22.4993 21.7727 17.9611 21.7727 12.3629C21.7727 6.76477 17.2345 2.22656 11.6364 2.22656C6.03821 2.22656 1.5 6.76477 1.5 12.3629C1.5 17.9611 6.03821 22.4993 11.6364 22.4993ZM11.6364 23.9993C18.063 23.9993 23.2727 18.7895 23.2727 12.3629C23.2727 5.93634 18.063 0.726562 11.6364 0.726562C5.20978 0.726562 0 5.93634 0 12.3629C0 18.7895 5.20978 23.9993 11.6364 23.9993Z"
			fill={color}
		/>
		<path
			d="M13.3309 16.9094C13.0856 16.9323 12.8393 16.8769 12.6273 16.7512C12.4724 16.5922 12.3982 16.3715 12.4255 16.1512C12.4312 15.9679 12.4531 15.7853 12.4909 15.6058C12.5275 15.3998 12.5748 15.1959 12.6327 14.9949L13.2764 12.7803C13.3427 12.5617 13.3866 12.3369 13.4073 12.1094C13.4073 11.8639 13.44 11.6948 13.44 11.5967C13.4537 11.1592 13.2669 10.7394 12.9327 10.4567C12.5217 10.1411 12.0098 9.986 11.4927 10.0203C11.1222 10.0259 10.7545 10.0865 10.4018 10.2003C10.0163 10.3203 9.61088 10.4639 9.18544 10.6312L9 11.3512C9.12544 11.3076 9.2782 11.2585 9.45272 11.2039C9.61922 11.1546 9.79181 11.1289 9.96544 11.1276C10.209 11.1012 10.4542 11.161 10.6582 11.2967C10.7967 11.462 10.8617 11.6768 10.8382 11.8912C10.8375 12.0747 10.8174 12.2575 10.7782 12.4367C10.74 12.6276 10.6909 12.8294 10.6309 13.0421L9.9818 15.2675C9.92948 15.4744 9.88763 15.6837 9.85636 15.8948C9.83086 16.0755 9.81811 16.2578 9.81816 16.4403C9.81548 16.8807 10.0169 17.2977 10.3636 17.5693C10.781 17.8898 11.3002 18.0487 11.8254 18.0166C12.1952 18.0242 12.5638 17.9708 12.9163 17.8584C13.2254 17.7529 13.6381 17.6021 14.1545 17.4057L14.3291 16.7184C14.1892 16.7764 14.0452 16.8238 13.8982 16.8602C13.7123 16.9027 13.5214 16.9192 13.3309 16.9094Z"
			fill={color}
		/>
		<path
			d="M14.0111 6.40958C13.7143 6.13695 13.3231 5.99028 12.9202 6.0005C12.5175 5.99141 12.1268 6.13794 11.8293 6.40958C11.284 6.87973 11.2231 7.70291 11.6934 8.2482C11.7353 8.29681 11.7807 8.34223 11.8293 8.38414C12.4505 8.93975 13.3899 8.93975 14.0111 8.38414C14.5563 7.90934 14.6135 7.08247 14.1387 6.53722C14.0992 6.4918 14.0566 6.44914 14.0111 6.40958Z"
			fill={color}
		/>
	</svg>
);

export default Info;
