import axios from './config';

export const checkUser = (data, additionalHeaders) =>
	axios.post('player/crm/login', data, additionalHeaders);

export const register = (data, additionalHeaders) =>
	axios.post('player/crm/register', data, additionalHeaders);

export const updateInfo = (data) => axios.post('player/crm/update-info', data);

export const loginSocialNetworkUser = (socialNetworkToken) =>
	axios.post(`player/crm/login/social-network`, { socialNetworkToken });

export const validate = (data) =>
	axios.post('player/crm/validate', {
		type: data.body,
		payload: data.data,
	});

export const getInfo = () => axios.get('player/crm/info');

export const getBalance = () => axios.get('player/financial/balance');

export const updatePhone = (data) =>
	axios.post(`${getSiteApi()}player/crm/update-phone`, data);
