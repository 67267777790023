import React from 'react';
import { getCurrentColor } from '../../../utils/consts';

const SportsLive = ({ color }) => {
	const currentColor = getCurrentColor(color);

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.5652 8.27588H13.7574L19.1413 0H12.3587L6 12.4136H11.0022L6 24L19.5652 8.27588Z"
				fill={currentColor}
			/>
		</svg>
	);
};

export default SportsLive;
