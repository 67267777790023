import React, { useEffect, useMemo, useRef, useState } from 'react';
import getMessage from '../../../utils/locale';
import clsx from 'clsx';
import styles from './general.module.scss';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import SportHero from '../../../images/sport_home.jpg';
import SportHeroTablet from '../../../images/sport_home_tablet.jpg';
import SportHeroMobile from '../../../images/sport_home_mobile.jpg';
import CasinoHero from '../../../images/casino_home.png';
import CasinoHeroTablet from '../../../images/casino_home_tablet.png';
import CasinoHeroMobile from '../../../images/casino_home_mobile.png';
import VIPBannerDesktop from '../../../images/VIP_Desktop.png';
import VIPBannerMobile from '../../../images/VIP_Mobile.png';
import Header from '../Header/Header';
import SEOContent from '../SEOContent/SEOContent';
import Promotions from '../Promotions/Promotions';
import Footer from '../Footer/Footer';
import screenSizes, {
	getValueFromBrowser,
	setCookieToDomains,
	WINPOT_CASINO_URL,
} from '../../../utils/consts';
import { isFirefox } from 'react-device-detect';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import { PopupType } from '../../Modals/SmallModal/SmallModal';
import { isLoggedIn } from '../../../utils/session';
import tabsData from './tabsData';
import { useModal } from '../../../redux/selectors';
import { actions } from '../../../redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import { OFFER_TYPE } from '../../../utils/trackingQueryHook';

const General = ({ setShowSpinner }) => {
	const size = useWindowSize();
	const dispatch = useDispatch();
	const { category, historyPopupType = [] } = useModal();
	const casinoRef = useRef(null);
	const bannerRef = useRef(null);

	const [screenActive, setScreenActive] = useState(0);
	const [activeTab, setActiveTab] = useState(0);

	const userOffer = useMemo(() => {
		const offerCode = getValueFromBrowser('offer') || '';
		return offerCode.toLowerCase();
	}, [getValueFromBrowser('offer')]);

	const btnText = {
		casinoBtn: !isLoggedIn() ? 'descBannerButton' : 'bannerToCasino',
		sportBtn: !isLoggedIn() ? 'descBannerButton' : 'bannerToSport',
	};

	const iOSversion = () => {
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
			return parseInt(v[1], 10);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			activeTab === 0 ? tabsHandler(1) : tabsHandler(0);
		}, 8000);
		return () => clearInterval(interval);
	}, [activeTab]);

	const handleClick = (ref) => {
		ref.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const tabsHandler = (idx) => {
		setScreenActive(idx);
		setActiveTab(idx);
	};

	useEffect(() => {
		const preloadBGList = [CasinoHero, CasinoHeroTablet, CasinoHeroMobile];

		// preloading BG images
		preloadBGList.forEach((image) => {
			const img = new Image();
			img.src = image;
		});
	}, []);

	const setHeroImg = (type) => {
		if (size.width <= screenSizes.mobileMiddle) {
			return type === 'Casino' ? CasinoHeroMobile : SportHeroMobile;
		} else if (size.width <= screenSizes.desktop) {
			return type === 'Casino' ? CasinoHeroTablet : SportHeroTablet;
		} else {
			return type === 'Casino' ? CasinoHero : SportHero;
		}
	};
	const getCasinoText = () => {
		switch (userOffer) {
			// By default, all casino users will see default text except the below cases
			case OFFER_TYPE.CA500:
			case OFFER_TYPE.CAOFF2:
			case OFFER_TYPE.CAOFF3:
			case OFFER_TYPE.CAOFFN500:
				return `${userOffer}CasinoScreenBonusTitle`;
			default:
				return 'defaultCasinoScreenBonusTitle';
		}
	};

	const getSportText = () => {
		switch (userOffer) {
			// By default, all sport users will see default text except the below cases
			case OFFER_TYPE.SPOFF1:
			case OFFER_TYPE.SPOFF5:
				return `${userOffer}SportScreenBonusTitle`;
			default:
				return 'defaultSportScreenBonusTitle';
		}
	};

	return (
		<>
			<Header setShowSpinner={setShowSpinner} />
			<div className={clsx(styles.wrapper)}>
				<div
					className={clsx(styles.screens, {
						[styles.screens__oldIOS]: iOSversion() <= 14,
					})}
				>
					{size.width <= screenSizes.desktop && (
						<div className={styles.screens__tabs}>
							{tabsData.map((item, idx) => {
								return (
									<button
										className={clsx(styles.screens__tab, {
											[styles.screens__tab__active]:
												activeTab === idx,
										})}
										key={idx}
										onClick={() => tabsHandler(idx)}
										data-testid={`${
											item.title.toLowerCase() === 'casino'
												? 'casino'
												: 'sports'
										}-tab`}
									>
										<img
											className={styles.screens__tab__icon}
											src={item.src}
											alt={item.title}
										/>
										<span className={styles.screens__tab__title}>
											{item.title}
										</span>
									</button>
								);
							})}
						</div>
					)}

					<div
						className={clsx(styles.screen, {
							[styles.screen__active]: screenActive === 0,
							[styles.screen__isFirefox]: isFirefox,
						})}
					>
						<div
							className={styles.screen__img}
							style={{ backgroundImage: `url(${setHeroImg('Casino')})` }}
						/>
						<div className={styles.screen__content}>
							{size.width <= screenSizes.desktop && (
								<div
									className={clsx(styles.screen__progress, {
										[styles.screen__progress__active]:
											screenActive === 0,
									})}
								/>
							)}
							<div className={styles.screen__sub_title}>
								{getMessage('es', 'casinoScreenSubTitle')}
							</div>
							<h2 className={styles.screen__title}>
								{getMessage('es', getCasinoText())}
							</h2>
							<button
								className={styles.screen__btn}
								onClick={() => {
									if (!isLoggedIn()) {
										setCookieToDomains('product_2', '1');
										dispatch({
											type: actions.OpenPopUp,
											payload: {
												category: PopupType.SignUp,
												historyPopupType: [
													...historyPopupType,
													PopupType.SignUp,
												],
											},
										});
									} else {
										window.location.href = `${WINPOT_CASINO_URL}casino`;
									}
								}}
								data-testid="signUp-casino-button"
							>
								{getMessage('es', btnText.casinoBtn)}
							</button>
						</div>
						<div
							className={styles.screen__scroll}
							onClick={() => handleClick(casinoRef)}
						/>
					</div>

					<div
						className={clsx(styles.screen, {
							[styles.screen__active]: screenActive === 1,
							[styles.screen__isFirefox]: isFirefox,
						})}
						id="sportScreen"
					>
						<div className={styles.target} ref={casinoRef} />
						<div
							className={styles.screen__img}
							style={{ backgroundImage: `url(${setHeroImg('Sport')})` }}
						/>
						<div className={styles.screen__content}>
							{size.width <= screenSizes.desktop && (
								<div
									className={clsx(styles.screen__progress, {
										[styles.screen__progress__active]:
											screenActive === 1,
									})}
								/>
							)}
							<div className={styles.screen__sub_title}>
								{getMessage('es', 'sportScreenSubTitle')}
							</div>
							<h2 className={styles.screen__title}>
								{getMessage('es', getSportText())}
							</h2>
							<button
								className={styles.screen__btn}
								onClick={() => {
									if (!isLoggedIn()) {
										setCookieToDomains('product_2', '2');
										dispatch({
											type: actions.OpenPopUp,
											payload: {
												category: PopupType.SignUp,
												historyPopupType: [
													...historyPopupType,
													PopupType.SignUp,
												],
											},
										});
									} else {
										window.location.href = `${WINPOT_CASINO_URL}sport`;
									}
								}}
								data-testid="signUp-sport-button"
							>
								{getMessage('es', btnText.sportBtn)}
							</button>
						</div>
						<div
							className={styles.screen__scroll}
							onClick={() => handleClick(bannerRef)}
						/>
					</div>
				</div>
			</div>

			<div className={styles.banner__wrapper} id="banner">
				<div className={styles.target} ref={bannerRef} />
				<div className={styles.banner}>
					<div className={styles.banner__content}>
						<h2 className={styles.banner__title}>
							{getMessage('es', 'winclubBanner')}
						</h2>
						<a
							href={`${WINPOT_CASINO_URL}winclub`}
							className={styles.banner__btn}
						>
							{getMessage('es', 'bannerButtonLoadMore')}
						</a>
					</div>
					<img
						className={styles.banner__img}
						src={
							size.width <= screenSizes.desktop
								? VIPBannerMobile
								: VIPBannerDesktop
						}
						alt={getMessage('es', 'bannerWinclubImgText')}
					/>
				</div>
				<h2 className={styles.general__promo__title}>
					{getMessage('es', 'mainPromoBanner')}
				</h2>
			</div>

			{size.width > screenSizes.tabletMiddle &&
				!isLoggedIn() &&
				category === PopupType.None && <RegistrationForm isFlying />}

			{<Promotions />}
			<SEOContent />
			<Footer />
		</>
	);
};

export default General;
