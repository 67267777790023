import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './Spinner.module.scss';
import spinnerImg from '../../images/spinner/New-UI-Loader.svg';

const Spinner = ({ isShow, delay = 1000 }) => {
	const [isShowPreload, setIsShowPreload] = useState(false);

	useEffect(() => {
		if (isShow) {
			setIsShowPreload(isShow);
		} else {
			setTimeout(() => setIsShowPreload(isShow), delay);
		}
	}, [isShow]);

	const loader = useMemo(() => {
		return <img src={spinnerImg} alt="loading ..." className={styles.spinnerGif} />;
	}, []);

	return (
		<>
			{isShowPreload && (
				<div
					className={clsx(styles.container, {
						[styles.isShowPreload]: isShowPreload,
					})}
				>
					{loader}
				</div>
			)}
		</>
	);
};

export default Spinner;
