import { PopupType } from '../../components/Modals/SmallModal/SmallModal';
import { actions } from '../actions/commonActions';

const initialState = {
	category: PopupType.None,
	props: {},
	historyPopupType: [],
};

export const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.OpenPopUp: {
			return { ...state, ...action.payload };
		}

		case actions.ClosePopUp: {
			return { ...initialState };
		}
	}
	return state;
};
