export const StoreAction = {
	// Popups
	OPEN_POPUP: 'OPEN_POPUP',
	CLOSE_POPUP: 'CLOSE_POPUP',
	SET_OPEN_MOVE_POPUP: 'SET_OPEN_MOVE_POPUP',
	OPEN_SIDE_MENU_POPUP: 'OPEN_SIDE_MENU_POPUP',
	CLOSE_SIDE_MENU_POPUP: 'CLOSE_SIDE_MENU_POPUP',

	// Games
	ADD_GAMES: 'ADD_GAMES',
	ADD_FAVOURITE_GAMES: 'ADD_FAVOURITE_GAMES',
};
