import { WINPOT_CASINO_URL } from '../../../utils/consts';

import SPIE from '../../../images/paymentCards/STPMX.svg';
import appStore from '../../../images/AppStore.png';
import twitter from '../../../images/social/twitter.svg';
import facebook from '../../../images/social/facebook.svg';
import googleStore from '../../../images/GoogleStore.png';
import instagram from '../../../images/social/instagram.svg';
import mastercard from '../../../images/paymentCards/Mastercard.svg';
import OXXO from '../../../images/paymentCards/OXXO.svg';
import visa from '../../../images/paymentCards/Visa.svg';
import cash from '../../../images/paymentCards/Cash.svg';
import BBVA from '../../../images/paymentCards/BBVA.svg';
import Banorte from '../../../images/paymentCards/Banorte.svg';
import FarmaciasDelAhoro from '../../../images/paymentCards/Ahoro.svg';
import SevenEleven from '../../../images/paymentCards/7-eleven.svg';
import SamsClub from '../../../images/paymentCards/SamsClub.svg';
import KLogo from '../../../images/paymentCards/K-logo.svg';
import Walmart from '../../../images/paymentCards/Walmart_logo.svg';
import BodegaAurrera from '../../../images/paymentCards/BodegaAurrera.svg';
import Roma from '../../../images/paymentCards/Roma.svg';
import ComercialMexicana from '../../../images/paymentCards/ComercialMexicana.svg';
import FarmaciasBenavides from '../../../images/paymentCards/FarmaciasBenavides.svg';
import SantaMaria from '../../../images/paymentCards/SantaMaria.svg';
import ExtraLogo from '../../../images/paymentCards/Extra_logo.svg';
import Soriana from '../../../images/paymentCards/Soriana.svg';
import Calimax from '../../../images/paymentCards/Calimax.svg';

export const appLinks = [
	{
		url: '/',
		img: googleStore,
		alt: 'GoogleStore',
	},
	{
		url: '/',
		img: appStore,
		alt: 'AppStore',
	},
];

export const socialData = (size) => [
	{
		icon: facebook,
		alt: 'Facebook',
		url: 'https://www.facebook.com/winpot.mx',
	},
	{
		icon: twitter,
		alt: 'Twitter',
		url: 'https://www.twitter.com/WinpotMx',
	},
	{
		icon: instagram,
		alt: 'Instagram',
		url: 'https://www.instagram.com/winpot.mx',
	},
];

export const paymentCards = [
	{
		icon: visa,
		alt: 'visa',
		url: `${WINPOT_CASINO_URL}info/payment-options/visa`,
	},
	{
		icon: mastercard,
		alt: 'master-card',
		url: `${WINPOT_CASINO_URL}info/payment-options/master-card`,
	},
	{
		icon: SPIE,
		alt: 'stp',
		url: `${WINPOT_CASINO_URL}info/payment-options/stp`,
	},
	{
		icon: cash,
		alt: 'cash',
		url: `${WINPOT_CASINO_URL}info/payment-options/cash`,
	},
	{
		icon: OXXO,
		alt: 'oxxo',
		url: `${WINPOT_CASINO_URL}info/payment-options/oxxo`,
	},
	{
		icon: BBVA,
		alt: 'BBVA',
		url: `${WINPOT_CASINO_URL}info/payment-options/bbva`,
	},
	{
		icon: Banorte,
		alt: 'Banorte',
		url: `${WINPOT_CASINO_URL}info/payment-options/banorte`,
	},
	{
		icon: FarmaciasDelAhoro,
		alt: 'FarmaciasDelAhoro',
		url: `${WINPOT_CASINO_URL}info/payment-options/farmacias-del-ahoro`,
	},
	{
		icon: SevenEleven,
		alt: '7Eleven',
		url: `${WINPOT_CASINO_URL}info/payment-options/7-eleven`,
	},
	{
		icon: SamsClub,
		alt: 'SamsClub',
		url: `${WINPOT_CASINO_URL}info/payment-options/sam's-club`,
	},
	{
		icon: KLogo,
		alt: 'KLogo',
		url: `${WINPOT_CASINO_URL}info/payment-options/k`,
	},
	{
		icon: Walmart,
		alt: 'Walmart',
		url: `${WINPOT_CASINO_URL}info/payment-options/walmart`,
	},
	{
		icon: BodegaAurrera,
		alt: 'BodegaAurrera',
		url: `${WINPOT_CASINO_URL}info/payment-options/bodega-aurrera`,
	},
	{
		icon: Roma,
		alt: 'Roma',
		url: `${WINPOT_CASINO_URL}info/payment-options/roma`,
	},
	{
		icon: ComercialMexicana,
		alt: 'ComercialMexicana',
		url: `${WINPOT_CASINO_URL}info/payment-options/comercial-mexicana`,
	},
	{
		icon: FarmaciasBenavides,
		alt: 'FarmaciasBenavides',
		url: `${WINPOT_CASINO_URL}info/payment-options/farmacias-benavides`,
	},
	{
		icon: SantaMaria,
		alt: 'SantaMaria',
		url: `${WINPOT_CASINO_URL}info/payment-options/santa-maria`,
	},
	{
		icon: ExtraLogo,
		alt: 'ExtraLogo',
		url: `${WINPOT_CASINO_URL}info/payment-options/extra`,
	},
	{
		icon: Soriana,
		alt: 'Soriana',
		url: `${WINPOT_CASINO_URL}info/payment-options/soriana`,
	},
	{
		icon: Calimax,
		alt: 'Calimax',
		url: `${WINPOT_CASINO_URL}info/payment-options/calimax`,
	},
];

export const bottomNav = [
	{
		label: 'Terms And Conditions',
		url: `${WINPOT_CASINO_URL}info/terms-and-conditions`,
	},
	{
		label: 'FAQ',
		url: `https://faq.winpot.mx`,
	},
	{
		label: 'Blog',
		url: `${WINPOT_CASINO_URL}blog`,
	},
	{
		label: 'Affiliates',
		url: `https://www.affiliatesworld.com/es`,
	},
	{
		label: 'Privacy Policy',
		url: `${WINPOT_CASINO_URL}info/privacy-policy`,
	},
	{
		label: 'Responsible Gaming',
		url: `${WINPOT_CASINO_URL}info/responsible-gaming`,
	},
	{
		label: 'Betting Rules',
		url: `${WINPOT_CASINO_URL}info/betting-rules`,
	},
	// {
	// 	label: 'About Us',
	// 	url: `${WINPOT_CASINO_URL}info/about-us`,
	// },
	// {
	// 	label: 'Fair Game',
	// 	url: '/',
	// },
	{
		label: 'General Bonus Policy',
		url: `${WINPOT_CASINO_URL}info/general-bonus-policy`,
	},
	{
		label: 'New Account',
		url: `${WINPOT_CASINO_URL}info/open-an-account`,
	},
	{
		label: 'Deposit To Your Account',
		url: `${WINPOT_CASINO_URL}info/deposits-to-your-account`,
	},
	{
		label: 'Withdraw Your Profits',
		url: `${WINPOT_CASINO_URL}info/withdraw-your-profits`,
	},
	{
		label: 'Bonus Information',
		url: `${WINPOT_CASINO_URL}info/bonus-information`,
	},
	{
		label: 'Payment Options',
		url: `${WINPOT_CASINO_URL}info/payment-options`,
	},
	{
		label: 'Verifications',
		url: `${WINPOT_CASINO_URL}info/verifications`,
	},
];
