import React from 'react';

import { useUserData } from '../../../redux/selectors';
import styles from './profile.module.scss';
import { showInitials } from '../../../utils/common';
import getMessage from '../../../utils/locale';
import screenSizes, {
	getRedirectAfterRegistration,
	WINPOT_CASINO_URL,
} from '../../../utils/consts';
import Button from '../../../UI/Button/Button';
import useWindowSize from '../../../utils/hooks/useWindowSize';

const ProfileDropdown = ({}) => {
	const userData = useUserData();
	const {
		firstName,
		lastName,
		balance: { summary: { total } = {}, currency } = {},
	} = userData;
	const { width } = useWindowSize();
	const isTabletAndMobileView = width <= screenSizes.tablet;

	const formatDecimalNumber = (number) =>
		Number(number).toLocaleString(undefined, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});

	const redirectForDeposit = () => {
		window.location.href = `${
			WINPOT_CASINO_URL + getRedirectAfterRegistration()
		}?modal=Finances `;
	};

	const redirectToMyAccount = () => {
		window.location.href = `${WINPOT_CASINO_URL}account/my-account`;
	};

	return (
		<>
			<div className={styles.root}>
				<div className={styles.profileDepositSection}>
					<div className={styles.profileBalance} onClick={redirectToMyAccount}>
						<div className={styles.profileBalance__title}>
							{!isTabletAndMobileView &&
								getMessage('es', 'profileDropdown.navbar.balance.title')}
							{total !== undefined && (
								<p className={styles.profileBalance__description}>
									<span>{currency === 'MXN' ? '$' : currency} </span>
									{formatDecimalNumber(total)}
								</p>
							)}
						</div>
						{!isTabletAndMobileView && (
							<div>{showInitials(firstName, lastName)}</div>
						)}
					</div>
					{width <= screenSizes.tabletMiddle ? (
						<Button
							text={getMessage(
								'es',
								'profileDropdown.navbar.deposit.button'
							)}
							type={'button'}
							classes={'depositButton'}
							onClick={redirectForDeposit}
						/>
					) : (
						<Button
							onClick={redirectForDeposit}
							classes={'btnPrimaryGreen'}
							text={getMessage(
								'es',
								'profileDropdown.navbar.deposit.button'
							)}
						></Button>
					)}
				</div>
			</div>
		</>
	);
};

export default ProfileDropdown;
