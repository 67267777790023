import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '../../../UI/Button/Button';
import InputLineWithLabel from '../../../UI/InputLineWithLabel/InputLineWithLabel';
import { PopupContainer } from '../../Container/Container';

import {
	DEFAULT_COUNTRY_INDEX,
	phoneCodes,
	phoneCodesSort,
} from '../../../UI/MultiSelect/phoneCodes';

import { DAYS, MONTHS, NAMES_OF_MONTH, YEARS } from './const';

import getMessage from '../../../utils/locale';
import screenSizes, {
	checkIfPreRelease,
	PASSWORD_REG_EXP_WITHOUT_CYRILLIC,
	REACT_APP_WINPOT_SHOW_FB_BUTTON,
} from '../../../utils/consts';

import {
	checkEnterCharactersOnlyNumbers,
	checkUserAbove18,
	getEmptyScheme,
	getPhoneDataByCode,
	getSignUpEmailAndPhoneScheme,
	getSignUpFullNameScheme,
	isValidDayOfMonth,
	selectValidMessagesPassword,
} from '../../../utils/validation';
import prepareRequestsData from './prepareRequestsData';

import styles from './signup.module.scss';
import { actions, closeSignUp } from '../../../redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import SuccessRegistrationPopup from '../SuccessRegistrationPopup/SuccessRegistrationPopup';
import { appData, useModal, useUserData } from '../../../redux/selectors';
import { resetErrorServerMessage } from '../../../redux/actions/userSession';
import CheckBox from '../../../UI/CheckBox/CheckBox';
import {
	getSignUpBanner,
	handleFBRegistration,
	isValidEmail,
	validateUniqueFields,
} from '../../../utils/common';
import clsx from 'clsx';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { PopupType } from '../SmallModal/SmallModal';
import IconFacebook from '../../../UI/icons/social/Facebook';
import Select from '../../../UI/Select/Select';
import ProgressIndicator, {
	IndicatorStatus,
} from '../../../UI/ProgressIndicator/ProgressIndicator';
import MultiSelect from '../../../UI/MultiSelect/MultiSelect';
import usePasswordHints from '../../../utils/hooks/useHintsPassword';
import useSeonSessionId from '../../../utils/hooks/useSeonSessionId';
import { isLoggedIn } from '../../../utils/session';

const initialSteps = {
	1: IndicatorStatus.Active,
	2: IndicatorStatus.Wait,
};

// eslint-disable-next-line react/display-name
const SignUpModal = () => {
	const dispatch = useDispatch();
	const userDataRedux = useUserData();

	const size = useWindowSize();
	const isMobileView = size.width < screenSizes.mobileMax;

	const { loading, loadingValidateFields } = appData();
	const { category, historyPopupType = [] } = useModal();

	const refForm = useRef(null);
	const widthForm = refForm?.current?.offsetWidth;

	const [openCongratulationPopUp, setOpenCongratulationPopUp] = useState(false);

	const [isShowMultiSelectItems, setIsShowMultiSelectItems] = useState(false);
	const [errorServerMessage, setErrorServerMessage] = useState(['', '']);
	const [errorValidAge, setErrorValidAge] = useState('');
	const [errorDayMessage, setErrorDayMessage] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [filledFirstStep, setFilledFirstStep] = useState(false);
	const [filledSecondStep, setFilledSecondStep] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState({
		day: null,
		month: null,
		year: null,
	});
	const [activeInputName, setActiveInputName] = useState(null);
	const [currentStep, setCurrentStep] = useState(1);
	const [filledStep, setFilledStep] = useState(initialSteps);
	const [showCouponField, setShowCouponField] = useState(false);
	const [isNeedCheckOnFlyEmail, setIsNeedCheckOnFlyEmail] = useState(false);

	const [secondStep, setSecondStep] = useState(false);

	const { hintsPassword, setHintsPassword } = usePasswordHints();

	const [phoneCodeIndex, setPhoneCodeIndex] = useState(
		phoneCodesSort[DEFAULT_COUNTRY_INDEX].ind
	);
	const [allowMarketing, setAllowMarketing] = useState({ allowMarketing: true });
	const sessionId = useSeonSessionId();
	const timerIdsRef = useRef([]);

	const phoneData = useMemo(() => {
		return getPhoneDataByCode(phoneCodes[phoneCodeIndex].value);
	}, [phoneCodeIndex]);

	const schema = useMemo(() => {
		let scheme;
		switch (currentStep) {
			case 1:
				scheme = getSignUpEmailAndPhoneScheme(phoneData);
				break;
			case 2:
				scheme = getSignUpFullNameScheme();
				break;
			default:
				scheme = getEmptyScheme();
				break;
		}

		return scheme;
	}, [phoneCodeIndex, currentStep]);

	const { register, handleSubmit, formState, reset, setError, watch } = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: '',
			lastName: '',
			phoneNumber: '',
			email: '',
			password: '',
			couponCode: '',
		},
		shouldFocusError: true,
	});

	const handleSelectField = useCallback(
		(fieldName) => (value) => {
			setDateOfBirth((prevDateOfBirth) => ({
				...prevDateOfBirth,
				[fieldName]: value,
			}));
		},
		[]
	);

	const { errors, dirtyFields } = formState;
	const { firstName, lastName, phoneNumber, email, password, couponCode } = dirtyFields;
	const formFields = watch();

	useEffect(() => {
		const { day, month, year } = dateOfBirth;
		const isCurrentPassword =
			currentStep === 1
				? PASSWORD_REG_EXP_WITHOUT_CYRILLIC.test(formFields.password)
				: true;
		const isDirtyFields =
			currentStep === 1
				? email && password && phoneNumber
				: lastName && firstName && day && month && year;
		const existErrors =
			currentStep === 1
				? !errors.email && !errors.phoneNumber
				: !errors.firstName &&
				  !errors.lastName &&
				  !errorDayMessage &&
				  !errorValidAge;
		const isFilled = !!(
			isDirtyFields &&
			existErrors &&
			!errorServerMessage[0].length &&
			isCurrentPassword &&
			/^[0-9]*$/g.test(formFields.phoneNumber)
		);
		currentStep === 1 ? setFilledFirstStep(isFilled) : setFilledSecondStep(isFilled);
	}, [
		formFields,
		email,
		password,
		phoneNumber,
		lastName,
		firstName,
		errors,
		errorServerMessage,
		errorDayMessage,
		errorValidAge,
		dateOfBirth,
		secondStep,
	]);

	useEffect(() => {
		const detectBackSpaceClick = (e) => {
			if (
				e.key === 'Backspace' &&
				currentStep === 2 &&
				activeInputName !== 'firstName' &&
				activeInputName !== 'lastName' &&
				activeInputName !== 'couponCode'
			) {
				onClose(true);
			}
		};
		document.addEventListener('keydown', detectBackSpaceClick, false);
		return () => document.removeEventListener('keydown', detectBackSpaceClick);
	}, [currentStep, activeInputName]);

	useEffect(() => {
		const { day, month, year } = dateOfBirth;
		const birthDate = `${year}-${month}-${day}`;
		const isValidCurrentDay = isValidDayOfMonth(
			parseInt(day),
			parseInt(month),
			parseInt(year)
		);

		if (isValidCurrentDay) {
			setErrorDayMessage('');
		} else {
			setErrorDayMessage(() =>
				isValidCurrentDay ? '' : getMessage('es', 'incorrect.day')
			);
		}
		setErrorValidAge(checkUserAbove18(birthDate));

		return () => setErrorValidAge('');
	}, [dateOfBirth.day, dateOfBirth.month, dateOfBirth.year]);

	useEffect(() => {
		selectValidMessagesPassword(formFields.password, hintsPassword, setHintsPassword);
	}, [formFields.password]);

	useEffect(() => {
		if (userDataRedux?.registrationStep || isLoggedIn()) {
			dispatch({ type: actions.ClosePopUp });
		}
	}, [userDataRedux.registrationStep, isLoggedIn()]);

	useEffect(() => {
		if (!firstName && !lastName && !phoneNumber) {
			const timerIds = [250, 500].map((delay) => {
				return setTimeout(() => reset(), delay);
			});
			timerIdsRef.current = timerIds;
			return () => {
				timerIdsRef.current.forEach((timerId) => clearTimeout(timerId));
			};
		}
	}, []);

	const showSeonRestrict = () => {
		dispatch({
			type: actions.OpenPopUp,
			payload: {
				category: PopupType.showSeonRestrict,
			},
		});
	};

	const onSubmit = async (data) => {
		if (currentStep === 1) {
			validateEmailAndPhone({
				email: formFields.email,
				phone: formFields.phoneNumber,
			});
			setSecondStep(true);
			return;
		}

		dispatch(resetErrorServerMessage());
		setErrorServerMessage(['', '']);

		const phoneData = phoneCodes[phoneCodeIndex];
		const { firstName, lastName, phoneNumber, email, password, couponCode } = data;
		const currentUserData = {
			dateOfBirth: `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`,
			firstName: firstName.trim(),
			lastName: lastName.trim(),
			phoneNumber,
			password,
			email,
			phoneCode: phoneData.value,
			isPushNewsletter: allowMarketing.allowMarketing,
			isPhoneNewsletter: allowMarketing.allowMarketing,
			isEmailNewsletter: allowMarketing.allowMarketing,
			allowPromotions: true,
			couponCode,
			sessionId,
		};

		const { profileData } = prepareRequestsData(currentUserData);
		localStorage.setItem('singUpRequest', true);
		dispatch({
			type: actions.SignUp,
			payload: {
				profileData,
				email,
				password,
				showSeonRestrict,
				t: getMessage,
				validateUniqueFields,
				setError,
			},
		});
	};

	useEffect(() => {
		const [field = '', errorMessage = ''] = userDataRedux?.errorServerMessage
			? Object.entries(userDataRedux?.errorServerMessage)[0]
			: ['', ''];
		setErrorServerMessage([field, errorMessage]);
	}, [userDataRedux?.errorServerMessage]);

	const onClose = (isClickOnBack = false) => {
		dispatch(closeSignUp());
		if (currentStep === 2 && (isMobileView || isClickOnBack)) {
			setCurrentStep(1);
			setFilledStep(initialSteps);
			return;
		}
		dispatch(resetErrorServerMessage());
		setErrorServerMessage(['', '']);
		dispatch({
			type: actions.OpenPopUp,
			payload: { category: PopupType.None },
		});
	};

	const preValidateEmail = (email) => {
		const isPreValidateEmailSuccess = isValidEmail(email, true);
		if (isPreValidateEmailSuccess) {
			setErrorServerMessage(['', '']);
		} else {
			setErrorServerMessage(['email', getMessage('es', 'incorrect.email')]);
		}
		return isPreValidateEmailSuccess;
	};

	const onEmailChange = (e) => {
		if (isNeedCheckOnFlyEmail) preValidateEmail(e.target.value);
	};

	const validateField = (type, data) => {
		dispatch({
			type: type === 'email' ? actions.ValidateEmail : actions.ValidatePhone,
			payload: {
				body: `${type}`,
				data: data,
				t: getMessage,
				validateUniqueFields,
				setError,
			},
		});
	};

	const validateEmailAndPhone = (data) => {
		if (data.email) {
			if (!isNeedCheckOnFlyEmail) setIsNeedCheckOnFlyEmail(true);
			if (preValidateEmail(data.email)) {
				validateField('email', data.email.trim());
			}
		}
		if (data.phone) {
			validateField('phone', {
				phone: `+52${data.phone.trim()}`,
				regionCode: 'MX',
			});
		}
	};

	const openSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	useEffect(() => {
		const onFocus = (event) => setActiveInputName(event?.target?.name);
		const onBlur = (event) => setActiveInputName(null);

		window.addEventListener('focus', onFocus, true);
		window.addEventListener('blur', onBlur, true);

		return () => {
			window.removeEventListener('focus', onFocus);
			window.removeEventListener('blur', onBlur);
		};
	}, []);

	useEffect(() => {
		localStorage.removeItem('singUpRequest');
	}, []);

	useEffect(() => {
		if (secondStep && !loadingValidateFields) {
			setSecondStep(false);
			if (
				filledFirstStep &&
				!(errors?.email || errors?.phoneNumber) &&
				!['email', 'phone'].includes(errorServerMessage[0]) &&
				userDataRedux.isEmailValidated &&
				userDataRedux.isPhoneValidated
			) {
				setCurrentStep(2);
				setFilledStep({
					1: IndicatorStatus.Completed,
					2: IndicatorStatus.Active,
				});
			}
		}
	}, [secondStep, userDataRedux.isEmailValidated, userDataRedux.isPhoneValidated]);

	return (
		<>
			<PopupContainer
				classes={styles.container}
				displayCloseButton={true}
				onClose={onClose}
				typeModal={category}
			>
				<button
					className={styles.support}
					onClick={openSupport}
					data-testid="support-button"
				>
					{getMessage('es', 'signUpSupport')}
				</button>
				<ProgressIndicator
					statusSteps={filledStep}
					onBackHandle={() => onClose(true)}
				/>
				{currentStep === 1 && (
					<div className={styles.formBanner} data-testid="signup-banner">
						<img
							className={styles.formBannerImg}
							src={getSignUpBanner()}
							alt="Sign up banner"
						/>
					</div>
				)}
				<div>
					<p
						className={clsx(styles.title, {
							[styles.isMobileView]: isMobileView,
						})}
					>
						{getMessage('es', 'signUp.form.button.open.account')}
					</p>
				</div>
				{REACT_APP_WINPOT_SHOW_FB_BUTTON && (
					<>
						<div className={styles.wrapperBtnFC}>
							<Button
								text={getMessage('es', 'loginFormButtonFacebook')}
								classes={'btnPrimaryBlue'}
								iconStart={<IconFacebook />}
								onClick={handleFBRegistration}
								disabled={checkIfPreRelease()}
							/>
						</div>
					</>
				)}
				<form
					className={styles.content}
					onSubmit={handleSubmit(onSubmit)}
					ref={refForm}
					autoComplete="off"
				>
					<div
						className={clsx(styles.form__inner, {
							[styles.stepOne]: currentStep === 1,
						})}
					>
						<InputLineWithLabel
							reg={register('email', {
								onBlur: () =>
									validateEmailAndPhone({
										email: formFields.email,
									}),
								onChange: (e) => onEmailChange(e),
								setValueAs: (v) => v.trim(),
							})}
							label={getMessage('es', 'fieldEmail')}
							labelSubTitle={getMessage('es', 'fieldEmailSubtitle')}
							type="text"
							name="email"
							placeholder={getMessage('es', 'fieldEmailPlaceholder')}
							errorText={
								errors.email?.message ||
								(errorServerMessage[0] === 'email' &&
									errorServerMessage[1])
							}
							hasValue={email}
							value={formFields.email}
							dataTestId={'email-input'}
						/>
						<InputLineWithLabel
							reg={register('password')}
							type="password"
							name="password"
							label={getMessage('es', 'fieldPassword')}
							autocomplete="new-password"
							iconPlace="loginFB"
							placeholder={getMessage('es', 'fieldPasswordPlaceholder')}
							errorText={errors.password?.message}
							hints={Object.values(hintsPassword)}
							hasValue={password}
							value={formFields.password}
							dataTestId={'password-input'}
						/>
						<div className={styles.wrapperPhone}>
							<div className={styles.customLabel}>
								{getMessage('es', 'fieldPhoneNumber')}
								<span>
									{getMessage('es', 'fieldPhoneNumberSubtitle')}
								</span>
							</div>
							<div className={styles.wrapperCodePhone}>
								<MultiSelect
									setPhoneCodeIndex={setPhoneCodeIndex}
									items={phoneCodesSort}
									iconFlag
									search
									selectedItem={phoneCodeIndex}
									widthContent={widthForm}
									isShowSelectItems={isShowMultiSelectItems}
									setIsShowSelectItems={setIsShowMultiSelectItems}
									disabled={true}
								/>

								<InputLineWithLabel
									reg={register('phoneNumber', {
										onBlur: () =>
											validateEmailAndPhone({
												phone: formFields.phoneNumber,
											}),
										onChange: (e) =>
											checkEnterCharactersOnlyNumbers(e),
									})}
									name="phoneNumber"
									placeholder={getMessage(
										'es',
										'fieldPhoneNumberPlaceholder'
									)}
									errorText={
										errors.phoneNumber?.message ||
										(errorServerMessage[0] === 'phoneNumber' &&
											errorServerMessage[1])
									}
									hasValue={!!formFields.phoneNumber}
									dataTestId={'phone-number-input'}
								/>
							</div>
						</div>
						<div className={styles.checkBox}>
							<CheckBox
								name="allowMarketing"
								label={getMessage('es', 'fieldAllowMarketing')}
								setSelectedValue={setAllowMarketing}
								checked={allowMarketing.allowMarketing}
								className={styles.checkbox}
								checkedColor="secondary"
								checkedClassName={styles.checkedCheckBox}
								labelClassName={styles.checkBoxLabel}
								dataTestId={'allow-marketing-checkbox'}
							/>
						</div>
					</div>
					<div
						className={clsx(styles.form__inner, {
							[styles.stepTwo]: currentStep === 2,
						})}
					>
						<div className={styles.inputWrapper}>
							<InputLineWithLabel
								name="firstName"
								type="text"
								label={getMessage('es', 'fieldFirstName')}
								labelSubTitle={getMessage('es', 'fieldFirstNameSubtitle')}
								placeholder={getMessage(
									'es',
									'fieldFirstNamePlaceholder'
								)}
								errorText={errors.firstName?.message}
								reg={register('firstName')}
								hasValue={firstName}
								value={formFields.firstName}
								dataTestId={'firstName-input'}
							/>
						</div>
						<div className={styles.inputWrapper}>
							<InputLineWithLabel
								name="lastName"
								label={getMessage('es', 'fieldLastName')}
								labelSubTitle={getMessage('es', 'fieldLastNameSubtitle')}
								placeholder={getMessage('es', 'fieldLastNamePlaceholder')}
								errorText={errors.lastName?.message}
								type="text"
								reg={register('lastName')}
								hasValue={lastName}
								value={formFields.lastName}
								dataTestId={'lastName-input'}
							/>
						</div>
						<div className={styles.datePickerWrapper}>
							{' '}
							<div className={styles.customLabel}>
								{getMessage('es', 'fieldBirthday')}
							</div>
							<div className={styles.datePicker}>
								<Select
									topLabel={getMessage('es', 'fieldDay')}
									selectItems={DAYS}
									handleClick={handleSelectField('day')}
									isRegistrationSelect
									value={dateOfBirth.day}
									dataTestId={'day-select'}
								/>

								<Select
									topLabel={getMessage('es', 'fieldMonth')}
									selectItems={MONTHS}
									value={NAMES_OF_MONTH[Number(dateOfBirth.month) - 1]}
									handleClick={handleSelectField('month')}
									isRegistrationSelect
									dataTestId={'month-select'}
								/>

								<Select
									topLabel={getMessage('es', 'fieldYear')}
									selectItems={YEARS}
									value={dateOfBirth.year}
									handleClick={handleSelectField('year')}
									isRegistrationSelect
									dataTestId={'year-select'}
								/>
							</div>
							<div className={styles.errorText}>
								{errorDayMessage || errorValidAge || ''}
							</div>
						</div>

						{showCouponField ? (
							<InputLineWithLabel
								name="couponCode"
								type="text"
								label={getMessage('es', 'fieldCouponCodeInputLabel')}
								placeholder={getMessage('es', 'fieldCouponCode')}
								errorText={errors.couponCode?.message}
								reg={register('couponCode')}
								hasValue={couponCode}
								value={formFields.couponCode}
							/>
						) : (
							<p className={styles.couponText}>
								{getMessage('es', 'fieldCouponCodeQuestion')}
								<span onClick={() => setShowCouponField(true)}>
									{getMessage('es', 'fieldCouponCodeButton')}
								</span>{' '}
								{getMessage('es', 'fieldCouponCodeSubstring')}
							</p>
						)}
					</div>
					<div className={styles.buttonSubmit}>
						<Button
							text={
								currentStep === 1
									? getMessage('es', 'signUpFormButtonSubmitFirstStep')
									: getMessage('es', 'signUpFormButtonSubmitSecondStep')
							}
							classes={'btnPrimaryGreen'}
							type={'submit'}
							disabled={
								currentStep === 1
									? !filledFirstStep || checkIfPreRelease() || loading
									: !filledSecondStep || checkIfPreRelease() || loading
							}
							dataTestId={'submit-button'}
						/>
					</div>
				</form>

				<p className={styles.logInText}>
					{getMessage('es', 'signUp.info.message.alreadyHaveAccount')}{' '}
					<span
						onClick={() => {
							dispatch({
								type: actions.OpenPopUp,
								payload: {
									category: PopupType.Login,
									historyPopupType: [
										...historyPopupType,
										PopupType.Login,
									],
								},
							});
						}}
						data-testid="login-with-account"
					>
						{getMessage('es', 'login.form.logIn')}
					</span>
				</p>

				<div className={styles.divider} />

				<div className={styles.rulesAgreement}>
					<div
						dangerouslySetInnerHTML={{
							__html: getMessage('es', 'formMessageConditions'),
						}}
					/>
				</div>
			</PopupContainer>
			{openCongratulationPopUp && (
				<SuccessRegistrationPopup setOpen={setOpenCongratulationPopUp} />
			)}
		</>
	);
};

export default SignUpModal;
