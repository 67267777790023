import React from 'react';
import { PopupContainer } from '../../Container/Container';
import styles from './successRegistrationPopup.module.scss';
import Button from '../../../UI/Button/Button';
import getMessage from '../../../utils/locale';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/actions/commonActions';
import { getRedirectAfterRegistration, WINPOT_CASINO_URL } from '../../../utils/consts';
import userIcon from '../../../images/successAvatar.png';
import { PopupType } from '../SmallModal/SmallModal';

const SuccessRegistrationPopup = ({ setOpen }) => {
	const dispatch = useDispatch();

	const onClose = () => {
		setOpen(false);
		dispatch({ type: actions.PutDataUser, payload: { registrationStep: '' } });
		window.location.href = `${
			WINPOT_CASINO_URL + getRedirectAfterRegistration()
		}?modal=Finances`;
	};

	return (
		<PopupContainer
			classes={styles.popup}
			displayCloseButton
			closeSuccessPopup
			onClose={onClose}
			typeModal={PopupType.Congratulation}
		>
			<div className={styles.popupBody}>
				<div className={styles.successLabel}>
					<img src={userIcon} alt="SuccessLabel" />
				</div>
				<h2 className={styles.popup__title}>
					{getMessage('es', 'signUp.form.success.done')}
				</h2>
				<p className={styles.popup__description}>
					{getMessage('es', 'signUp.form.successfully.registered')}
				</p>
				<div className={styles.popup__confirmation}>
					<Button
						text={getMessage('es', 'successRegistrationModalButtonSubmit')}
						type="button"
						classes={'btnPrimaryMintGreen'}
						onClick={() => {
							onClose();
						}}
					/>
				</div>
			</div>
		</PopupContainer>
	);
};

export default SuccessRegistrationPopup;
