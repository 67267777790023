const translationGeneralScreen = {
	en: {
		descBannerDesc: '$ 400 gift without deposit',
		descBannerTitle: 'We duplicate\n' + 'your 1st deposit!',
		descBannerButton: 'REGISTER',
		bannerToCasino: 'Go to Casino',
		bannerToSport: 'Go to Sport',
		descBonusButton: 'Juega Ahora',
		winclubBanner: 'Live the best VIP experience at Winpot VIP',
		mainPromoBanner: 'Discover more advantages',
		bannerButtonLoadMore: 'Be one of our VIP member',
		bannerWinclubImgText: 'playing cards',
		casinoScreenSubTitle: 'Casino',
		sportScreenSubTitle: 'Sport',
		defaultCasinoScreenBonusTitle:
			'Free $500 bonus + up to $10,000 on your first deposit',
		ca500CasinoScreenBonusTitle:
			'WELCOME BONUS: 120% UP TO $10,000 ON YOUR FIRST DEPOSIT',
		caoff2CasinoScreenBonusTitle:
			'WELCOME BONUS: $200 FREE NO DEPOSIT AND DOUBLE YOUR FIRST DEPOSIT UP TO $10,000',
		caoff3CasinoScreenBonusTitle:
			'WELCOME BONUS: $200 UP TO $1000 AND 20 FREE SPINS ON YOUR FIRST DEPOSIT',
		caoffn500CasinoScreenBonusTitle:
			'WELCOME BONUS: 100 FREE SPINS AND WE DOUBLE YOUR FIRST DEPOSIT',
		defaultSportScreenBonusTitle:
			'Welcome bonus in sports We double your deposit up to $3,000',
		spoff1SportScreenBonusTitle:
			'WELCOME BONUS: $500 FREE NO DEPOSIT AND DOUBLE YOUR FIRST DEPOSIT UP TO $3,000',
		spoff5SportScreenBonusTitle:
			'WELCOME BONUS IN SPORTS FREE $500 BONUS + WE DOUBLE YOUR DEPOSIT UP TO $3,000',
		sportScreenTitle: 'Welcome bonus in sports We double your deposit up to $3,000',
		promoBtnLoadMore: 'Load More',
		promoTabDetails: 'Details',
		promoTabTerms: 'Terms',
		promoTabGame: 'Game',
		promoCasinoTitleFirst: 'DROPS & WINS',
		promoSportTitleFirst: 'BORE DRAW',
		promoSportTitleSecond: 'PREPAYMENT',
		promoPopUpTimerDays: 'Days',
		promoPopUpTimerHours: 'Hours',
		promoPopUpTimerMinutes: 'Minues',
		promoPopUpTimerSeconds: 'Seconds',
	},
	es: {
		// descBannerDesc: 'De regalo $400 sin deposito',
		descBannerDesc:
			'Obtén tu bono de bienvenida en Winpot hasta <b style="color: #E73843; font-style: italic; white-space: nowrap;" > $ 10,000 </b> en tu primer depósito',
		descBannerTitle: '+ ¡Duplicamos\n' + 'tu 1er depòsito!',
		descBannerButton: 'REGÍSTRATE',
		bannerToCasino: 'Ir a Casino',
		bannerToSport: 'Ir al Deporte',
		descBonusButton: 'Juega Ahora',
		winclubBanner: 'Vive la mejor experiencia VIP en Winpot VIP',
		mainPromoBanner: 'Descubre más ventajas',
		bannerButtonLoadMore: 'Sé uno de nuestros miembros VIP',
		bannerWinclubImgText: 'cartas de juego',
		casinoScreenSubTitle: 'Casino',
		sportScreenSubTitle: 'Deportes',
		defaultCasinoScreenBonusTitle:
			'Bono gratis de $500 + hasta $10,000 en su primer depósito',
		ca500CasinoScreenBonusTitle:
			'BONO DE BIENVENIDA: 120% HASTA $10,000 EN TU PRIMER DEPOSITO',
		caoff2CasinoScreenBonusTitle:
			'BONO DE BIENVENIDA: $200 GRATIS SIN DEPOSITO Y DUPLICA TU PRIMER DEPOSITO HASTO $10,000',
		caoff3CasinoScreenBonusTitle:
			'BONO DE BIENVENIDA: $200 HASTA $1000 Y 20 GIROS GRATIS EN EL TU PRIMER DEPOSITO',
		caoffn500CasinoScreenBonusTitle:
			'BONO DE BIENVENIDA: 100 GIROS GRATIS Y DUPLICAMOS TU PRIMER DEPOSITO',
		defaultSportScreenBonusTitle:
			'Bono de bienvenida en deportes Duplicamos tu deposito hasta $3,000',
		spoff1SportScreenBonusTitle:
			'BONO DE BIENVENIDA: $500 GARTIS SIN DEPOSITO Y DUPLICA TU PRIMER DEPÓSITO HASTA $3,000',
		spoff5SportScreenBonusTitle:
			'BONO DE BIENVENIDA EN DEPORTES BONO GRATIS DE $500 + DUPLICAMOS TU DEPÓSITO HASTA $3,000',
		sportScreenTitle:
			'Bono de bienvenida en deportes Duplicamos tu deposito hasta $3,000',
		promoBtnLoadMore: 'Leer Mas',
		promoTabDetails: 'Detalles',
		promoTabTerms: 'Términos y condiciones',
		promoTabGame: 'Juegos',
		promoCasinoTitleFirst: 'DROPS & WINS',
		promoSportTitleFirst: 'BORE DRAW',
		promoSportTitleSecond: 'PAGO ANTICIPADO',
		promoPopUpTimerDays: 'Días',
		promoPopUpTimerHours: 'Horas',
		promoPopUpTimerMinutes: 'Minutos',
		promoPopUpTimerSeconds: 'Segundos',
	},
	ru: {
		loginFormFieldUsernameEmail: 'Никнейм/Емаил',
	},
};

export default translationGeneralScreen;
