import React, { useState } from 'react';
import PromoPopup from '../../Modals/PromoPopup/PromoPopup';
import getMessage from '../../../utils/locale';

import styles from './Promotions.module.scss';

const PromoSlide = (props) => {
	const { slide, popupData } = props;

	const [promoPopup, setPromoPopup] = useState(false);

	const showPromoPopup = () => {
		setPromoPopup(!promoPopup);
		document.documentElement.style.overflow = 'hidden';
	};

	return (
		<div className={styles.slide}>
			<div
				className={styles.content}
				style={{ backgroundImage: `url(${slide.src})` }}
				onClick={showPromoPopup}
			>
				<h2 className={styles.title}>{slide.title && slide.title}</h2>
				<button className={styles.btn}>
					{getMessage('es', 'promoBtnLoadMore')}
				</button>
			</div>

			{promoPopup && (
				<PromoPopup showPromoPopup={showPromoPopup} popupData={popupData} />
			)}
		</div>
	);
};

export default PromoSlide;
