import 'moment/locale/es'; // without this line it didn't work
import moment from 'moment';
import { getTrackingValues } from '../../../utils/trackingQueryHook';
import Cookies from 'js-cookie';
import { removeCookieFromDomains, setCookieToDomains } from '../../../utils/consts';

const prepareRequestsData = (userData) => {
	const {
		firstName,
		lastName,
		nickName,
		email,
		password,
		phoneCode,
		phoneNumber,
		dateOfBirth,
		isPushNewsletter,
		isPhoneNewsletter,
		isEmailNewsletter,
		allowPromotions,
		couponCode,
		sessionId,
	} = userData;

	const address = {
		street: '',
		city: '',
		zipCode: '',
		addressCountryAlfa2: '--',
		state: '',
	};

	const phone = `+${phoneCode}${phoneNumber}`;
	const birthDate = moment
		.utc(moment(dateOfBirth.replace(/\s+/g, ''), 'YYYY-MM-DD').format('YYYY-MM-DD'))
		.toISOString();
	setCookieToDomains(
		'product',
		Cookies.get('product') || Cookies.get('product_1') || Cookies.get('product_2')
	);
	removeCookieFromDomains('product_1');
	removeCookieFromDomains('product_2');

	const profileData = {
		firstName: firstName.trim(),
		lastName: lastName.trim(),
		middleName: '',
		password,
		address,
		currency: 'MXN',
		country: undefined,
		city: undefined,
		zipCode: undefined,
		birthDate,
		phone,
		email,
		language: 'es',
		citizenship: 'MX', // mexican hardcoded citizenship
		gender: 'other', // Tmp fake data. Need to change
		affiliateInformation: getTrackingValues(couponCode),
		emailVerified: false,
		phoneVerified: false,
		isPushNewsletter,
		isPhoneNewsletter,
		isEmailNewsletter,
		allowPromotions,
		...(nickName && { nickName: nickName.trim() }),
		sessionId,
	};

	return { profileData, email, nickName, password };
};

export default prepareRequestsData;
