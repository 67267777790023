export const ICON_COLORS = {
	Primary: '#ffffff',
	Secondary: '#2D2A26',
	Action: '#E73843',
};

export const ICON_VARIANT = {
	Action: 'action',
	Disabled: 'disabled',
	Primary: 'primary',
	Secondary: 'secondary',
};
