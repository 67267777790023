import React from 'react';
import styles from './styles.module.scss';
import { PopupType } from '../../../components/Modals/SmallModal/SmallModal';
import { useModal } from '../../../redux/selectors';
import clsx from 'clsx';

const IconClose = ({ onclick }) => {
	const { category } = useModal();
	return (
		<div
			className={clsx(styles.wrapper, {
				[styles.isForgotPassword]: category === PopupType.ForgotPassword,
			})}
			onClick={onclick}
			data-testid="close-button"
		>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M13 3L3 13"
					stroke="#999DAD"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3 3L13 13"
					stroke="#999DAD"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};

export default IconClose;
