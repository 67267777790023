import { put, takeLeading } from 'redux-saga/effects';

// import * as apiGames from '../utils/api/games';
// import { errorNotification } from '@/utils/notifications/notifications';

import { finishRequest, startRequest } from '../redux/actions/commonActions';
import { actionsGames, addFavouriteGames } from '../redux/actions/games';

// worker saga: makes the api call when watcher saga sees the action
// eslint-disable-next-line require-jsdoc
function* getFavouriteGames() {
	// eslint-disable-next-line no-unused-vars
	yield put(startRequest());
	try {
		// const responseRegister = yield call(apiGames.getFavouriteGames);
		const responseRegister = {
			data: {
				total: 542,
				result: [
					{
						createdAt: '2022-02-09T12:20:21.007Z',
						lastChangedAt: '2022-02-09T12:20:21.007Z',
						oid: '998',
						id: '00000000-0000-0000-0000-000000000999',
						bonusFactor: 100,
						externalDesktopId: 'EGT_807',
						externalMobileId: 'EGT_807',
						friendlyName: 'Zodiac Wheel',
						licenses: ['MIT'],
						lines: 0,
						gameType: 'slot',
						releaseDate: '2022-02-09T12:20:21.007Z',
						rtp: 0,
						supportFreeSpins: true,
						link: '',
						vendor: '00000000-0000-0000-0000-000000000006',
						provider: '00000000-0000-0000-0000-000000000001',
						categories: [
							'9fb0c46c-af0c-4890-bbdd-10b55ca7723b',
							'd475f09d-012b-462e-a980-3f00f1bd273d',
						],
						favorites: [],
					},
					{
						createdAt: '2022-02-09T12:20:21.007Z',
						lastChangedAt: '2022-02-09T12:20:21.007Z',
						oid: '13',
						id: '00000000-0000-0000-0000-000000000014',
						bonusFactor: 100,
						externalDesktopId: 'PP_HTML5_GodsOfKemet94_Desktop',
						externalMobileId: 'PP_HTML5_GodsOfKemet94_Mobile',
						friendlyName: 'Gods of Kemet',
						licenses: ['MIT'],
						lines: 10,
						gameType: 'slot',
						releaseDate: '2022-02-09T12:20:21.007Z',
						rtp: 94.15,
						supportFreeSpins: true,
						link: '',
						vendor: '00000000-0000-0000-0000-000000000001',
						provider: '00000000-0000-0000-0000-000000000001',
						categories: ['a5fbc24b-08fc-48f1-b5df-22b73d22f30b'],
						favorites: [],
					},
					{
						createdAt: '2022-02-09T12:20:21.007Z',
						lastChangedAt: '2022-02-09T12:20:21.007Z',
						oid: '16',
						id: '00000000-0000-0000-0000-000000000017',
						bonusFactor: 100,
						externalDesktopId: 'PP_HTML5_JingleWins94_Desktop',
						externalMobileId: 'PP_HTML5_JingleWins94_Mobile',
						friendlyName: 'Jingle Wins',
						licenses: ['MIT'],
						lines: 10,
						gameType: 'slot',
						releaseDate: '2022-02-09T12:20:21.007Z',
						rtp: 93.99,
						supportFreeSpins: true,
						link: '',
						vendor: '00000000-0000-0000-0000-000000000001',
						provider: '00000000-0000-0000-0000-000000000001',
						categories: ['a5fbc24b-08fc-48f1-b5df-22b73d22f30b'],
						favorites: ['a2cf824d-9880-40d1-af40-53f13867fd07'],
					},
					{
						createdAt: '2022-02-09T12:20:21.007Z',
						lastChangedAt: '2022-02-09T12:20:21.007Z',
						oid: '19',
						id: '00000000-0000-0000-0000-000000000020',
						bonusFactor: 100,
						externalDesktopId: 'PP_HTML5_EmeraldsOfOz94_Desktop',
						externalMobileId: 'PP_HTML5_EmeraldsOfOz94_Mobile',
						friendlyName: 'Emeralds of Oz',
						licenses: ['MIT'],
						lines: 10,
						gameType: 'slot',
						releaseDate: '2022-02-09T12:20:21.007Z',
						rtp: 93.99,
						supportFreeSpins: true,
						link: '',
						vendor: '00000000-0000-0000-0000-000000000001',
						provider: '00000000-0000-0000-0000-000000000001',
						categories: [
							'07c6374a-21bf-400e-a755-73b308341450',
							'a5fbc24b-08fc-48f1-b5df-22b73d22f30b',
							'd475f09d-012b-462e-a980-3f00f1bd273d',
						],
						favorites: [],
					},
					{
						createdAt: '2022-02-09T12:20:21.007Z',
						lastChangedAt: '2022-02-09T12:20:21.007Z',
						oid: '22',
						id: '00000000-0000-0000-0000-000000000023',
						bonusFactor: 100,
						externalDesktopId: 'PP_HTML5_BookOfEnchantments94_Desktop',
						externalMobileId: 'PP_HTML5_BookOfEnchantments94_Mobile',
						friendlyName: 'Book of Enchantments',
						licenses: ['MIT'],
						lines: 10,
						gameType: 'slot',
						releaseDate: '2022-02-09T12:20:21.007Z',
						rtp: 94.5,
						supportFreeSpins: true,
						link: '',
						vendor: '00000000-0000-0000-0000-000000000001',
						provider: '00000000-0000-0000-0000-000000000001',
						categories: [
							'04151cc6-97fe-4224-bf0c-9a88b16895b3',
							'07c6374a-21bf-400e-a755-73b308341450',
							'a5fbc24b-08fc-48f1-b5df-22b73d22f30b',
							'd475f09d-012b-462e-a980-3f00f1bd273d',
						],
						favorites: [],
					},
				],
			},
		};
		const { data } = responseRegister;
		yield put(addFavouriteGames(data));
	} catch (e) {
		// yield put(
		// 	putDataUser({
		// 		statusError: e.response?.status,
		// 		dataError: e.response?.data,
		// 	})
		// );
		const dataMessage = e.response?.data?.data;
		if (dataMessage) {
			// TODO implement show alerts
			// const [field, errorMessage] = Object.entries(dataMessage)[0];
			// errorNotification(t, errorMessage, {
			// 	field: t(`players.field.${field}`, { ns: 'players' }),
			// });
		}
	} finally {
		yield put(finishRequest());
	}
}

// watcher root saga for userApi:
// watches for actions dispatched to the store, starts worker saga
// eslint-disable-next-line require-jsdoc
export default function* gamesSaga() {
	yield takeLeading(actionsGames.GetFavouriteGames, getFavouriteGames);
}
