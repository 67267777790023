import React from 'react';
import { getCurrentColor } from '../../../utils/consts';

const Casino = ({ color }) => {
	const currentColor = getCurrentColor(color);

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_28633_273605)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19.8854 0H3.88537C1.74962 0 0 1.76314 0 3.91539V20.0816C0 22.2369 1.74962 23.997 3.88537 23.997H4.11161H11.8854H20.1116C22.2504 23.997 23.997 22.2338 23.997 20.0816V3.91539C23.997 1.7601 22.2474 0 20.1116 0H19.8854ZM5.28506 3.87912C5.28506 4.62694 4.68476 5.23188 3.94268 5.23188C3.2006 5.23188 2.6003 4.62694 2.6003 3.87912C2.6003 3.13131 3.2006 2.52637 3.94268 2.52637C4.68476 2.52637 5.28506 3.13131 5.28506 3.87912ZM20.0573 21.4741C20.7994 21.4741 21.3997 20.8691 21.3997 20.1213C21.3997 19.3735 20.7994 18.7686 20.0573 18.7686C19.3152 18.7686 18.7149 19.3735 18.7149 20.1213C18.7149 20.8691 19.3152 21.4741 20.0573 21.4741Z"
					fill={currentColor}
				/>
				<path
					d="M13.2187 6.41174L17.5837 10.8105C17.9065 11.1357 18.0845 11.5704 18.0845 12.0325C18.0845 12.4946 17.9065 12.9293 17.5837 13.2546L15.9638 14.887C15.641 15.2122 15.2096 15.3916 14.7511 15.3916C14.2926 15.3916 13.8612 15.2122 13.5385 14.887L12.009 13.3457L10.4646 14.9022C10.1418 15.2274 9.7104 15.4068 9.25188 15.4068C8.79336 15.4068 8.36199 15.2274 8.03921 14.9022L6.4193 13.2698C6.09653 12.9445 5.91855 12.5098 5.91855 12.0477C5.91855 11.5856 6.09653 11.1509 6.4193 10.8257L10.7964 6.41478C11.1192 6.08951 11.5505 5.91016 12.009 5.91016C12.4676 5.91016 12.8989 6.08951 13.2217 6.41478L13.2187 6.41174Z"
					fill="#1C1B31"
				/>
				<path
					d="M12.5309 12.3152C12.3137 10.4092 12.5188 9.10813 12.5188 9.10813L11.997 10.695L11.4751 9.10813C11.4751 9.10813 11.6802 10.4122 11.463 12.3152C11.276 13.9476 10.7753 16.0209 9.56561 18.0941H14.4284C13.2157 16.0209 12.7179 13.9476 12.5309 12.3152Z"
					fill="#1C1B31"
				/>
			</g>
			<defs>
				<clipPath id="clip0_28633_273605">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Casino;
