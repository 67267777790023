import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './CodeVerification.module.scss';
import axios from '../../../utils/api/api';

import getMessage from '../../../utils/locale';
import DigitCodeInput from '../../../UI/DigitCodeInput/DigitCodeInput';
import CellPhoneIcon from '../../Icons/CellPhoneIcon';
import { actions } from '../../../redux/actions/commonActions';
import { useUserData } from '../../../redux/selectors';
import { getSiteApi } from '../../../utils/session';
import {
	ConfirmationCodeState,
	SECONDS_IN_MINUTE,
} from '../ForgotPassword/ForgotPassword';
import { removeCookieFromDomains } from '../../../utils/consts';

export const VERIFICATION_CODE_LENGTH = 6;

const CodeVerification = (props) => {
	const {
		activeTab,
		setActiveScreen,
		secondsLeft,
		verifyMethod,
		setMobileCode = undefined,
		currentPhoneNumber,
	} = props;

	const userData = useUserData();
	const dispatch = useDispatch();

	const [code, setCode] = useState('');
	const [hasError, setHasError] = useState(false);
	const withVerificationBonus = false;

	useEffect(() => {
		if (hasError) {
			setHasError(false);
		}

		if (code.length === VERIFICATION_CODE_LENGTH) {
			if (setMobileCode) {
				confirmSmsCode();
			} else {
				confirmCode();
			}
		}
	}, [code, setMobileCode]);

	const timeLeftArea = () => {
		const minutes = Math.floor(secondsLeft / SECONDS_IN_MINUTE);
		const seconds = Math.floor(secondsLeft % SECONDS_IN_MINUTE);

		return (
			<div className={styles.timer}>
				{secondsLeft > 0 ? (
					<>
						{minutes < 10 ? `0${minutes}` : minutes} :{' '}
						{seconds < 10 ? `0${seconds}` : seconds}
					</>
				) : (
					<>
						<p className={styles.resend}>
							{getMessage('es', 'verification.timeUp')}
						</p>
						<button
							className={styles.button}
							onClick={() => verifyMethod(setCode, setHasError)}
						>
							{getMessage('es', 'verification.button.resendOTP')}
						</button>
					</>
				)}
			</div>
		);
	};

	const confirmCode = () => {
		axios
			.post(`${getSiteApi()}player/crm/confirm/${activeTab.toLowerCase()}`, {
				confirmationCode: code,
			})
			.then(() => {
				if (Cookies.get('isRegistration')) {
					removeCookieFromDomains('isRegistration');
				}
				setActiveScreen(ConfirmationCodeState.Success);
			})
			.catch(() => setHasError(true))
			.finally(() => {
				dispatch({ type: actions.GetInfo });
			});
	};

	const confirmSmsCode = () => {
		axios
			.post(`${getSiteApi()}player/crm/password/reset/confirm-sms-code`, {
				phone: currentPhoneNumber,
				confirmationCode: code,
			})
			.then((response) => {
				setMobileCode(response?.data?.code);
				setActiveScreen(ConfirmationCodeState.ResetPasswordModal);
			})
			.catch(() => setHasError(true));
	};

	const handleClickOnSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	return (
		<>
			<div className={styles.presentbox__icon}>
				{withVerificationBonus ? (
					<img
						src={'/images/verification/present-box.png'}
						alt={getMessage('es', 'verification.title')}
						loading="lazy"
					/>
				) : (
					<CellPhoneIcon />
				)}
			</div>
			<h2 className={clsx(styles.title, styles.titleCode)}>
				{setMobileCode
					? getMessage('es', 'navbar.user.titleCongratulation.thankYou')
					: getMessage('es', 'verification.title')}
			</h2>
			{setMobileCode ? (
				<h2 className={clsx(styles.description)}>
					{getMessage('es', 'verification.description.resetPassword', {
						phoneNumber: currentPhoneNumber,
					})}
				</h2>
			) : (
				<p className={styles.description}>
					{getMessage('es', 'verification.description', {
						verificationMethod: getMessage(
							'es',
							`verification.method.${activeTab.toLowerCase()}`
						),
					})}
					<span className={styles.method}>
						{userData[activeTab.toLowerCase()]}
					</span>
				</p>
			)}
			{timeLeftArea()}
			<div className={styles.digitCodeInputWrapper}>
				<DigitCodeInput hasError={hasError} setCode={setCode} code={code} />
			</div>
			<div
				className={clsx(styles.error, {
					[styles.extended]: hasError,
				})}
			>
				<div className={styles.error__text}>
					{getMessage('es', 'verification.error.invalidCode')}
				</div>
			</div>
			<div className={styles.needHelp}>
				<div className={clsx(styles.info, styles.extended)}>
					<p className={styles.info__text}>
						{getMessage('es', 'verification.needHelp')}
					</p>
					<span className={styles.info__link} onClick={handleClickOnSupport}>
						{getMessage('es', 'verification.button.support')}
					</span>
				</div>
			</div>
		</>
	);
};

export default CodeVerification;
