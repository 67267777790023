import React from 'react';
import { PopupContainer } from '../../Container/Container';
import styles from './login.module.scss';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { resetErrorServerMessage } from '../../../redux/actions/userSession';
import { PopupType } from '../SmallModal/SmallModal';
import { actions } from '../../../redux/actions/commonActions';
import getMessage from '../../../utils/locale';
import { useModal } from '../../../redux/selectors';

const LoginModal = ({ inModal, isLoginPromoPopUp }) => {
	const dispatch = useDispatch();
	const { category, historyPopupType = [] } = useModal();

	const onClose = () => {
		localStorage.removeItem('winclubPath');
		dispatch(resetErrorServerMessage());
		dispatch({
			type: actions.OpenPopUp,
			payload: {
				category: PopupType.None,
				historyPopupType: [...historyPopupType, PopupType.None],
			},
		});
	};

	const showSupport = () => {
		dispatch({
			type: actions.ClickedOnSupport,
			payload: true,
		});
	};

	return (
		<PopupContainer
			classes={clsx(styles.container)}
			displayCloseButton
			onClose={onClose}
			typeModal={category}
		>
			<button
				className={styles.support}
				onClick={showSupport}
				data-testid="support-button"
			>
				{getMessage('es', 'signUpSupport')}
			</button>
			<RegistrationForm inModal={inModal} isLoginPromoPopUp={isLoginPromoPopUp} />
		</PopupContainer>
	);
};

export default LoginModal;
