import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useSeonSessionId = () => {
	const [seonSessionId, setSeonSessionId] = useState(null);

	useEffect(() => {
		const seonToken = uuidv4();

		seon.config({
			host: 'seondf.com',
			// eslint-disable-next-line camelcase
			session_id: seonToken,
			// eslint-disable-next-line camelcase
			audio_fingerprint: true,
			// eslint-disable-next-line camelcase
			canvas_fingerprint: true,
			// eslint-disable-next-line camelcase
			webgl_fingerprint: true,
		});
		seon.getBase64Session((res) => setSeonSessionId(res));
	}, []);

	return seonSessionId;
};

export default useSeonSessionId;
